import React from 'react'
import Divider from '@mui/material/Divider';
import Navbar from '../../src/components/snippets/nav-sidebars/Navbar'
import "../style/general/PrivacyPolicyOfSlot.css";
export default function PrivacyPolicyOfSlot() {
    return (
        <div>
            <Navbar />
            <div className='Privacy-Policy-div'>
                <h2 className='Privacy-Policy-title'>
                    Privacy policy of Slot
                </h2>
            </div>
            <div className='min-div'>
                <p className='min-headers'>
                    1.	Definitions
                </p>


                <p> <strong>- “Affiliate”</strong></p>
                <p>
                    means in relation to SLOT any entity that from time to time directly or indirectly controls, is
                    controlled by, or is under common control with Slot FZCO;
                </p>

                <p><strong> - “Apps”</strong> </p>
                <p>
                    means the Slot applications available on the Google Play Store or the Apple App Store;
                </p>

                <p><strong>- “End-user”</strong></p>
                <p>
                    means an individual using any of our Apps;
                </p>

                <p> <strong>- “Slot”</strong> </p>

                <p>
                    means www.slotyourspace.com a free zone company registered in United Arab Emirates under
                    license number 25024, with registered office address in DSO-DDP-A5-D-OFF-1006, Dubai Digital Park -
                    Office A5-Dtec, Dubai Silicon Oasis, Dubai, UAE;
                </p>

                <p><strong>-  “Partner”</strong></p>
                <p>
                    means a business owner who provide services to Slot like and not limited to payment gateway,
                    analytics services, hosting services, marketing services, analytical services, etc.
                </p>

                <p><strong>- “Vendor”</strong></p>
                <p>
                    means a business owner/space owner who uses the Slot website and/or App to
                    offer/confirm/cancel spaces/slots bookings to End-user;
                </p>

                <p><strong>- “Privacy Policy”</strong></p>
                <p>
                    means Privacy Policy which may be amended from time-to-time and available in our
                    Website www.slotyourspace.com ;
                </p>
                <p><strong>- “Terms & Conditions”</strong></p>
                <p>
                    means this Terms & Conditions for using Slot App or Websites which may be
                    amended from time-to-time and available in our Website www.slotyourspace.com ;
                </p>

                <p><strong>- “Services”</strong></p>
                <p>
                    means any of the services we provide to Partners or End-user through our Apps and Websites;
                    and;
                </p>

                <p><strong> - “Members”</strong></p>
                <p>
                    Are staff or shareholder of Slot FZCO or it’s local dealer.
                </p>

                <p><strong> - “Packages”</strong></p>
                <p>
                    Is the Vendor offers for each of his/her listed slots.
                </p>

                <p><strong>- “Convenience Fee”</strong>
                </p>
                <p>
                    is the amount added to Packaged amount as service fee to make slot
                    reservation/booking.
                </p>

                <p><strong> - “Websites”</strong> </p>
                <p>
                    means www.slotyourspace.com, including all subdirectories, subdomains, and Mobile sites
                    thereof.
                </p>

                <p className='min-headers '> 2 Who Should Read This Privacy Policy </p>

                <p> This Privacy Policy will apply to you if you are any of the following:</p>


                <ul>•	A Vendor on the Slot Websites;</ul>

                <ul>•	An End-user; or </ul>
                <ul>• A visitor to any of our Websites.</ul>



                <p>
                    By downloading/ installing our Apps or accessing our Websites or using the Services, you agree to the terms of
                    this Privacy Policy. If we make any significant changes to this Privacy Policy, there will be no notification either
                    declaration in regards to that; therefore, it is recommended by time to time Vendors, End-users and visitors
                    have a look through our Privacy Policy.<br></br> <br></br>
                    If you have any questions about our Privacy Policy, don’t hesitate to get in touch with us. Please feel free to
                    reach out to us at <a href="mailto:CC@slotyourspace.com" style={{ color: 'blue' }}>CC@slotyourspace.com</a>
                </p>




                <p className='min-headers'>
                    3.	The Personal Information We Collect
                </p>

                <p>
                    We collect personal information (meaning anything that could identify you either by itself, or when combined
                    with other information we are able to gain access to), as described below. When you register/use/access to our
                    Apps or Websites, we collect information that Apps or your browser sends to us that is called log data, which
                    may include information such and not limited to your name, Last name, your mobile number, your email, your
                    GPS location, your bank card details computer's Internet Protocol (“IP”) address, MAC address browser version,
                    and pages of our Service that you visit, the time and date of your visit, the time spent on those pages, and other
                    statistics. You can always choose not to provide us with your personal information, but it may mean that we are
                    unable to provide you with the Service you have requested.
                </p>

                <p className='min-headers'>
                    3.1 Information you provide to us when creating an account
                </p>

                <p>
                    When you create an account using either of our Apps or Websites, you provide us with personal information,
                    including your name, e-mail address, a password, the name of your business (if you are a Vendor) and your
                    mobile number. We also collect demographic information such as your birth date. If you give us permission to
                    do so, we may also collect information about your social media presence, including your usernames. And your
                    geographical location.
                </p>

                <p className="sub-title">
                    3.2 Information we collect from End-users
                </p>

                <p className="sub-title">
                    3.2.1 Apps and Browsers;
                </p>

                <p> We collect information about your device when you download and install one of our Apps, and when use your
                    browser when you visit either of our websites. </p>

                <p>
                    The information we collect includes:
                </p>

                <ul>
                    • <strong>How you found our Websites:</strong> including but not limited to whether you discovered us through a
                    search engine, a partner \website, an ad campaign, or email campaign;
                </ul>

                <ul>
                    • <strong>Technical information:</strong> your IP address, MAC address, operating system version, device type,
                    language and region settings, and other details about how you interact with our Apps and Website;
                    and
                </ul>

                <ul>
                    •<strong>Location information:</strong>  if you have activated one of our Apps and permitted your mobile or other
                    location-aware device to transmit location data, we will collect this automatically. This may include
                    country, province, city, or neighborhood information.
                </ul>

                <p className='min-headers'>
                    3.3 Information we collect from Vendors
                </p>

                <p>
                    If you are a Vendor, we will collect the following details about you:
                </p>

                <ul>
                    • <strong>Bank details:</strong> in order to make payments to you, we need to collect your bank account details like
                    and not limited to account number, account name, bank name, bank branch, swift code, IBAN
                    number and country.
                </ul>


                <ul>
                    • <strong>Business related information:</strong> your business name, a description of your business, contact details,
                    titles, owner name(s), CR copy, and bios of your staff members;
                </ul>

                <ul>
                    • <strong>Availability and scheduling information:</strong> information about your business calendars and staff
                    availability from Slot account on the Website;
                </ul>


                <ul>
                    • <strong>Social media information:</strong> information from your Facebook page, Instagram page, Twitter feed,
                    LinkedIn profile, and additional publicly available data; and
                </ul>

                <ul>
                    • <strong>Customer information:</strong> where you upload your customer database to our servers, we will retain
                    the personal information it contains subject to our Data Retention Policy in Section 7.
                </ul>

                <p className='min-headers'>
                    3.4 Third Parties
                </p>

                <p>
                    We may receive personal information about you from other sources with which you have registered, business
                    Partners, affiliate entities (collectively, "Partners"), or other third parties. For example, if an End User creates an
                    account on Slot through one of our registration Partners, such as Google or Facebook, we may collect personal
                    information from those websites. We may associate this information with the other personal information we
                    have collected about you.
                </p>

                <p className='min-headers'>
                    3.5 Cookies:
                </p>

                <p>
                    A cookie is a small file of letters and numbers that we store on your browser or device if you agree. Slot uses
                    cookies in order to save End-user/Vendor name and ID’s and some other information when required. we use
                    the following types of cookies:                </p>


                <p className="sub-title">
                    3.5.1 Strictly necessary cookies
                </p>

                <p>
                    These are cookies that are required for the operation of our Websites. They include, for example, cookies that
                    enable you to log into secure areas of our Websites.
                </p>

                <p className="sub-title">
                    3.5.2 Analytical/performance cookies
                </p>


                <p>
                    These cookies allow us to recognize and count the number of visitors and to see how visitors move around our
                    Websites. This helps us to improve the way they work, for example, by ensuring that users are finding what they
                    are looking for easily. We use these to remember who you are and keep track of your preferences, e.g. the cities
                    for which you want to be shown Vendor offerings. We may also collect information about the website you were
                    visiting before you landed on one of our own, and the website you visit after you leave our own, if this
                    information is supplied to us by your browser.
                </p>

                <p className="sub-title">
                    3.5.3 Targeting and Remarketing Cookies from Third-party Advertising Networks
                </p>

                <p >
                    Slot has relationships with third party advertising Partners -like and limited to Google- to place advertisements
                    on our behalf onto other websites, and to perform tracking and reporting functions for our platform and our
                    advertisements. These third parties may place cookies on your device which automatically collect information
                    about your visit to one of our Websites, your IP address, your ISP and the browser you use. Information collected
                    may be used, among other things, to deliver advertising targeted to your interests and to better understand the
                    usage and visitation patterns of our platform and Websites.
                </p>

                <p className='min-headers'>
                    4 How We Use Your Personal Information
                </p>

                <p>
                    We will only use your personal information once you install/download our Apps or access our Websites. Most
                    commonly, we will use your personal information in the following circumstances:
                </p>
                <ul>
                    <li> • When we deliver our Services;</li>


                    <li>
                        • Where we need to perform a contract with you (e.g. to create an appointment or to process a
                        payment);
                    </li>
                    <li>
                        • Where it is necessary for our legitimate interests (or those of a third party) and your interests and
                        fundamental rights do not override those interests (see the "Legitimate Interests" section below);
                    </li>
                    <li>
                        • Where we need to comply with a legal or regulatory obligation;
                    </li>
                    <li>
                        • Provide you with offers for products and services from participating Vendors, including offers based
                        on variables such as stated and anticipated customer interests, personal characteristics, or
                        location;
                    </li>
                    <li>
                        • Recommend certain Vendors offerings to you (e.g., to suggest Vendors to add to your favorites
                        page or remind you when it's time to rebook a service); and
                    </li>
                    <li>
                        • Facilitate and fulfil requests for appointments for Vendor offerings, including their purchase and
                        redemption.
                    </li>
                </ul>


                <p className='min-headers'>
                    5 Legitimate Interests
                </p>

                <p>
                    We may rely on legitimate interests to process your personal information, provided that your interests do not
                    override our own. Where we rely on legitimate interests, these interests are:
                </p>
                <ul>
                    <li>• Providing, maintaining, and improving the Services to meet the needs of our End Users and
                        Vendors;</li>
                    <li>• Understanding how the Services are used to improve their performance in the future;</li>
                    <li>• customizing the Services to provide you with a better user experience;</li>
                    <li>• Marketing to inform End Users and Vendors about other Services we offer that might benefit or
                        interest them;</li>
                    <li>• Providing advertising;</li>
                    <li>
                        • Detecting, preventing, or otherwise addressing fraud, abuse, security, or technical issues with our
                        services; and
                    </li>
                    <li>
                        • Enforcing legal claims.
                    </li>
                </ul>


                <p className='min-headers'>
                    6 Keeping Your Personal Information Safe
                </p>

                <p>
                    We employ a variety of physical and technical measures to keep your personal information safe and to prevent
                    unauthorized access to, use or disclosure of it. Databases are stored on cloud secure servers and out technical
                    Partners control who has access to them (using both physical and electronic means).
                    Where we have given you (or where you have chosen) a password which enables you to access our Services,
                    you are responsible for keeping this password confidential. We ask you not to share your password with anyone.
                    We cannot absolutely guarantee the security of the internet, external networks, or your own device, accordingly
                    any online communications (e.g. information provided by e-mail, through our Website or our Apps) are at your
                    own risk.
                </p>

                <p className='min-headers'>
                    7 Storage and Retention
                </p>

                <p className="sub-title">
                    7.1 Where we store your information
                </p>

                <p>
                    Slot is currently utilizing Google hosting solutions to assure high availability of our Services. Slot reserves the
                    right to migrate or backup data to other cloud or in-premise hosting solutions other than Google without any
                    notification to Vendors or End-user.
                </p>

                <p className="sub-title">
                    7.2 Data Retention
                </p>

                <p>
                    Slot will have the total right to store your personal data and there is an option to delete the data from the End-
                    user device. However, Slot will remain the data in the database for analytics purpose.
                </p>

                <p className='min-headers'>
                    8 Disclosing Your Information
                </p>

                <p className="sub-title">
                    8.1 End-user information
                </p>

                <p>
                    Except as otherwise stated in this Privacy Policy, we do not sell, trade, rent, or otherwise share the personal
                    information that we collect with third parties, unless you ask or authorize us to do so. However, there are
                    occasions where we may need to disclose your personal information.
                </p>
                <p>Where we do so, any such disclosure will be on the basis that these third parties are required to keep the
                    information we give them confidential and secure, and will not use it for any other purpose than to carry out
                    the services they are performing for us. We may disclose personal information as follows:</p>

                <ul>
                    <li>• To affiliated companies (e.g., subsidiaries and other companies owned by or controlled by Slot);</li>
                    <li>• To service providers that are hired by us to provide administrative and communication services on our
                        behalf, such as website hosting, the processing and delivery of mailings, providing customer support, or
                        providing credit card processing services;</li>
                    <li>• To Vendors where you have purchased Services from a Vendor through Slot, or participated in an offer,
                        contest or other activity sponsored or offered through Slot on behalf of the Vendor. We may also provide
                        Vendors with other personal information about you on your Slot account to send customized offers, to
                        validate the purchases, to aid and troubleshoot redemption, to combat fraud, as part of legal
                        proceedings affecting Slot and the Vendor, to facilitate a direct relationship between Vendors and
                        customers, and to enable Vendors to communicate electronically with End-users who make purchases
                        from the Vendor;</li>
                    <li>• When you rate or review a Service which you have purchased, your rating, review, photo, your first name
                        and last initial will be published on the Slot App/Websites and may appear on third party websites. For
                        Vendors whose businesses have received reviews on the Slot marketplace, these reviews may be
                        syndicated on third party websites;</li>
                    <li>• To business Partners and other advertisers as part of an offer, contest, distribution partnership, or other
                        activity that is provided by or in conjunction with Slot and its business Partners and in which you have
                        elected to participate. For example, if you are referred to Slot from a business Partner's website or
                        through an invitation facilitated by or in conjunction with a Slot business Partner, Slot may provide that
                        business Partner with contact information and transactional information;</li>
                    <li>• To the public when any user of the Slot App access your favorites page. You may, however, hide this
                        information from the public by marking your favorites page as private;</li>
                    <li>• To third parties to whom we may choose to sell, transfer, or merge parts of our business or our assets.
                        Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our
                        business, then the new owners may use your personal information in the same way as set out in this
                        policy or they can change this policy; and</li>
                    <li>• To our professional advisors.</li>
                </ul>


                <p className="sub-title">
                    8.2 Vendor information
                </p>
                <p>We may share some or all of your information, including but not limited to booking information, staff
                    member names, Vendor offerings, ratings and reviews, prices and discounts, appointment availability,
                    and related data (collectively <strong>"Vendor Information"</strong>) with End-users who have signed up or registered
                    for Slot and with Slot business Partners and affiliates with which Slot is working:</p>

                <ul>
                    <li>• We may feed Vendor information to third party websites (excluding customer lists), which may
                        result in Vendor information appearing on a third-party website, mobile application or in search
                        engine results; and such results may include a corresponding link to schedule an appointment
                        through Slot;</li>
                    <li>• Slot may contact Vendor employees through email to invite them to create a Slot account with a
                        restricted login;</li>
                    <li>• We may use certain contact information to promote your goods and services through a variety
                        of online and offline advertising and promotional media, including, but not limited to Google,
                        Google AdWords, Facebook Ads, Twitter, e-mail distribution lists, and third-party business
                        Partners and affiliates; or</li>
                    <li>
                        • If you have participated in any joint Vendor offerings or promotions with other Vendors, Slot may
                        disclose your information to its Partners and/or advertisers, as part of such offer, contest, or
                        other activity that is provided by or in conjunction with Slot and its business Partners and in which
                        you have elected to participate.
                    </li>
                </ul>

                <p className="sub-title">
                    8.3 Aggregated Data
                </p>

                <p>
                    We may use aggregated non-personally identifiable data to:
                </p>
                <ul>
                    <li>• Assist us to better understand how our customers are using the Services;</li>
                    <li>• Provide our customers with further information regarding the uses and benefits of the Services;</li>
                    <li>• Enhance small business productivity, including by creating useful business insights from that
                        aggregated data and allowing you to benchmark your business’s performance against that
                        aggregated data, and</li>
                    <li>• Otherwise, to improve the Services.</li>
                </ul>



                <p className="sub-title">
                    8.4 Disclosures Required by Law
                </p>

                <p>
                    We are subjected to the law like everyone else. We may be required to give information to legal and/or
                    regulatory authorities globally if they so request, or if they have the proper authorization such as a search
                    warrant or court order.
                </p>
                <br></br>
                <p>We also may need to retain and disclose certain information about you to legal and/or regulatory authorities
                    and to appropriate agencies to conduct anti-money laundering checks and to assist with fraud prevention. We
                    will disclose this information as is required by law.</p>


                <p className="sub-title">
                    8.5 Links to Third Party Websites
                </p>


                <p>
                    This Privacy Policy applies solely to your information collected by Slot/Websites and does not apply to third
                    party/Partners websites. Slot is not responsible for the privacy policies of third-party websites. You should read
                    the privacy policies of other websites before providing them with any information about you.
                </p>

                <p className='min-headers'>
                    9 Updating this Privacy Policy
                </p>

                <p>
                    We may update this Privacy Policy at any time. We encourage you to frequently check this Policy from time to
                    time for any changes to stay informed about how we are helping to protect the personal information we hold.
                </p>


            </div>
        </div>
    )
}