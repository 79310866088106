import swal from "sweetalert";
import * as React from "react";
import Global from "../../../data/global";
import Radio from "@mui/material/Radio";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import RadioGroup from "@mui/material/RadioGroup";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { timestamp } from "../../../firebase/config";
import { useCollection } from "../../../hooks/useCollection";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useGlobalContext } from "../../../hooks/useGlobalContext";
import { useVendorAddPackage } from "../../../hooks/useVendorAddPackage";
import "../../../style/vendor/AddPackage.css";

const ITEM_PADDING_TOP = 8;
const ITEM_HEIGHT = 48;

export default function Package() {
  const navigate = useNavigate();
  const branchDocs = useCollection("branches").documents;
  const { documents } = useCollection("packages");
  const { user } = useAuthContext();
  const { addPackage, isPending, error } = useVendorAddPackage();
  const { pageType } = useParams();
  const { dispatch } = useGlobalContext();
  const [fee, setFee] = React.useState("");
  const [minOrderUser, setMinOrderUser] = useState("");
  const [minOrderBusiness, setMinOrderBusiness] = useState("");
  const [slotType, setSlotType] = React.useState("room");
  const [timeFrom, setTimeFrom] = React.useState("1");
  const [timeTo, setTimeTo] = React.useState("1");
  const [createdBy, setcreatedBy] = React.useState(user.uid);
  const [branch, setbranch] = React.useState([]);
  const [isFreeReservation, setIsFreeReservation] = useState(false);
  const TimeFor = timeFrom + ":00";
  const TimeTo = timeTo + ":00";
  var tempBranchIds = [];

  const handleChecke = (event) => {
    if (event.target.checked) {
      var userMinOrder = document.getElementById("userMinOrder");
      var businessMinOrder = document.getElementById("businessMinOrder");
      userMinOrder.style.display = event.target.checked ? "none" : "block";
      setMinOrderUser("0");
      businessMinOrder.style.display = event.target.checked ? "none" : "block";
      setMinOrderBusiness("0");
      setIsFreeReservation(true);
    } else {
      var userMinOrder = document.getElementById("userMinOrder");
      var businessMinOrder = document.getElementById("businessMinOrder");
      userMinOrder.style.display = !event.target.checked ? "block" : "none";
      businessMinOrder.style.display = !event.target.checked ? "block" : "none";
      setIsFreeReservation(false);
    }
  };

  const branchHandleChange = (event) => {
    const {
      target: { value },
    } = event;

    setbranch(typeof value === "string" ? value.split(",") : value);
    tempBranchIds.push(branch);
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const isConflictTime = (addedTimeFrom, addedTimeTo) => {
    var conflict = false;

    var addedTimeFromHour = parseInt(
      addedTimeFrom.substring(0, addedTimeFrom.indexOf(":"))
    );
    var addedTimeToHour = parseInt(
      addedTimeTo.substring(
        addedTimeFrom.indexOf(":"),
        addedTimeFrom.indexOf(":")
      )
    );
    var addedTimeFromMinute = parseInt(
      addedTimeFrom.substring(
        addedTimeFrom.indexOf(":") + 1,
        addedTimeFrom.length
      )
    );
    var addedTimeToMinute = parseInt(
      addedTimeFrom.substring(
        addedTimeFrom.indexOf(":") + 1,
        addedTimeFrom.length
      )
    );

    const packege = documents
      ? documents.filter((packageDocument) => {
        let assignedToMe = false;
        if (user.id === packageDocument.createdBy) {
          if (packageDocument.slotType === slotType) {
            assignedToMe = true;

            var timeFrom = packageDocument.timeFrom;
            var timeTo = packageDocument.timeTo;

            var timeFromHour = parseInt(
              timeFrom.substring(0, timeFrom.indexOf(":"))
            );
            var timeToHour = parseInt(
              timeTo.substring(timeFrom.indexOf(":"), timeFrom.indexOf(":"))
            );
            var timeFromMinute = parseInt(
              timeFrom.substring(timeFrom.indexOf(":") + 1, timeFrom.length)
            );
            var timeToMinute = parseInt(
              timeFrom.substring(timeFrom.indexOf(":") + 1, timeFrom.length)
            );

            if (
              addedTimeFromHour > timeFromHour &&
              addedTimeFromHour < timeToHour
            ) {
              conflict = true;
            } else if (
              addedTimeToHour > timeFromHour &&
              addedTimeToHour < timeToHour
            ) {
              conflict = true;
            } else if (timeFromHour === addedTimeFromHour) {
              if (timeFromMinute <= addedTimeFromMinute) {
                conflict = true;
              }
            } else if (timeToHour === addedTimeToHour) {
              if (timeToMinute <= addedTimeToMinute) {
                conflict = true;
              }
            } else {
            }

            return assignedToMe;
          }
        }
      })
      : null;

    return conflict;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch({ type: "SET_BRANCH_IDS", payload: [...tempBranchIds] });
    const conflictTime = isConflictTime(TimeFor, TimeTo);
    if (!conflictTime) {
      if (minOrderUser > minOrderBusiness || isFreeReservation) {
        addPackage(
          createdBy,
          TimeFor,
          TimeTo,
          fee,
          minOrderUser,
          minOrderBusiness,
          slotType,
          timestamp.now()
        );
        navigate("/dashboard/package-list");
      } else {
        swal({
          icon: "error",
          text: "min order for customer should be greter than min order for business",
        });
      }
    } else {
      swal({
        icon: "error",
        text: "There is an conflict",
      });
    }
    navigate("/dashboard/package-list");
  };

  const handleChangeSotType = (eventslot) => {
    setSlotType(eventslot.target.value);
  };

  const handleChangeTimeFrom = (timefrom) => {
    setTimeFrom(timefrom.target.value);
  };

  const handleChangeTimeTo = (timeto) => {
    setTimeTo(timeto.target.value);
  };

  const handleChange = (event) => {
    setFee(event.target.value);
  };

  const getBranchSection = () => {
    if (pageType === "indpendent") {
      return (
        <FormControl sx={{ m: 1, width: 300, mt: 3 }}>
          <Select
            multiple
            displayEmpty
            value={branch}
            onChange={branchHandleChange}
            input={<OutlinedInput />}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <em>Branches</em>;
              }

              return selected.join(", ");
            }}
            MenuProps={MenuProps}
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem disabled value=""></MenuItem>

            {branchDocs && branchDocs.length === 0 && <p>No Branch yet!</p>}
            {branchDocs &&
              branchDocs.map((b) =>
                b.createdBy === user.uid ? (
                  <MenuItem id={b.id} key={b.id} value={b.title}>
                    {b.title}
                  </MenuItem>
                ) : null
              )}
          </Select>
        </FormControl>
      );
    } else {
      <div></div>;
    }
  };

  return (
    <form onSubmit={handleSubmit} className="auth-form">
      <h2 className="auth-form-h2-tag">Add Package</h2>

      {getBranchSection()}

      <label>
        <span>
          How Many Hours?
          <InputLabel id="demo-simple-select-label">
            From
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={timeFrom}
              onChange={handleChangeTimeFrom}
              style={{ width: 100, marginLeft: 15 }}
            >
              <MenuItem value="1">1</MenuItem>
              <MenuItem value="2">2</MenuItem>
              <MenuItem value="3">3</MenuItem>
              <MenuItem value="4">4</MenuItem>
              <MenuItem value="5">5</MenuItem>
              <MenuItem value="6">6</MenuItem>
              <MenuItem value="7">7</MenuItem>
              <MenuItem value="8">8</MenuItem>
              <MenuItem value="9">9</MenuItem>
            </Select>
          </InputLabel>
          <InputLabel id="demo-simple-select-label">
            To
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={timeTo}
              onChange={handleChangeTimeTo}
              style={{ width: 100, marginLeft: 32 }}
            >
              <MenuItem value="1">1</MenuItem>
              <MenuItem value="2">2</MenuItem>
              <MenuItem value="3">3</MenuItem>
              <MenuItem value="4">4</MenuItem>
              <MenuItem value="5">5</MenuItem>
              <MenuItem value="6">6</MenuItem>
              <MenuItem value="7">7</MenuItem>
              <MenuItem value="8">8</MenuItem>
              <MenuItem value="9">9</MenuItem>
            </Select>
          </InputLabel>
        </span>
      </label>
      <label>
        <span id="demo-controlled-radio-buttons-group">With Fee ?</span>
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={fee}
          onChange={handleChange}
        >
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="no" control={<Radio />} label="No" />
        </RadioGroup>
      </label>
      <lable>
        <span id="demo-controlled-radio-buttons-group">Free Reservation:</span>
        <Checkbox
          value={isFreeReservation}
          onChange={handleChecke}
          style={{ color: "#014D2F" }}
        />
      </lable>
      <label id="userMinOrder">
        <span>Minimum order per hour for regular accounts:</span>
        <input
          required
          type="number"
          onChange={(e) => setMinOrderUser(e.target.value)}
          value={minOrderUser}
        />
      </label>

      <label id="businessMinOrder">
        <span>Minimum order for business accounts:</span>
        <input
          required
          type="number"
          onChange={(e) => setMinOrderBusiness(e.target.value)}
          value={minOrderBusiness}
        />
      </label>
      {pageType === "/AddPackage" ? (
        <label>
          <span>Available in branch:</span>
          <p>{Global.placeLocationLable}</p>
        </label>
      ) : null}

      <label>
        <span id="demo-simple-select-filled-label">Slot Type</span>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={slotType}
          onChange={handleChangeSotType}
          style={{ width: 300 }}
        >
          <MenuItem value="room">Room</MenuItem>
          <MenuItem value="table">Table</MenuItem>
        </Select>
      </label>
      {!isPending && (
        <button
          className="slotButton"
          style={{ width: 250, marginLeft: 225, marginRight: 125 }}
        >
          Add Package
        </button>
      )
      }
      {
        isPending && (
          <button
            className="slotButton"
            style={{ width: 250, marginLeft: 125, marginRight: 125 }
            }
            disabled
          >
            loading
          </button >
        )}
      {error && <div className="error">{error}</div>}
    </form >
  );
}
