import * as React from "react";
import Box from "@mui/material/Box";
import Temple from "../../../assets/images/slotLogo.svg";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { useAuthContext } from "../../../hooks/useAuthContext";

export default function Navbar() {
  const { user } = useAuthContext();

  return (
    <div>
      {" "}
      {!user && (
        <AppBar
          className="appBar"
          style={{
            backgroundColor: "#ffff",
          }}
        >
          <Toolbar disableGutters>
            {" "}
            <img
              alt="Slot logo"
              className="logo"
              src={Temple}
              style={{ width: "85px", marginLeft: "15px" }}
            />
            <Box
              sx={{
                flexGrow: 1,
                display: {
                  xs: "flex",
                  md: "none",
                },
              }}
            ></Box>
          </Toolbar>
        </AppBar>
      )}
    </div>
  );
}
