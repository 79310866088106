import { useState, useEffect } from "react";
import { projectFirestore } from "../firebase/config";
import { nowTimeStamp } from "../firebase/config";
import { useGlobalContext } from "../hooks/useGlobalContext";
import { useDocument } from "../hooks/useDocument";

export const useAddSlot = () => {
  const [isCancelled, setIsCancelled] = useState(false);
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const { branchIds } = useGlobalContext();
  const { document } = useDocument("branches", branchIds[0]);
  const addSlot = async (
    autoAccept,
    feeMinPrice,
    feesOnVendor,
    isPrivate,
    privateUserList,
    createdBy,
    currency,
    slotLable,
    numbeOfChair,
    base_pricing,
    inflation,
    opening_time,
    slotType,
    image,
    slotDate
  ) => {
    setError(null);
    setIsPending(true);

    try {
      const location = document.placelocation;
      const placeOpenTime = document.openTime;
      const placeCloseTime = document.closeTime;
      const timestamp = nowTimeStamp;
      const branchId = branchIds[0] === undefined ? "" : branchIds[0];

      await projectFirestore
        .collection("slots")
        .add({
          autoAccept,
          feeMinPrice,
          feesOnVendor,
          isPrivate,
          privateUserList,
          branchId,
          createdBy,
          currency,
          slotLable,
          numbeOfChair,
          base_pricing,
          inflation,
          opening_time,
          slotType,
          image,
          slotDate,
          placeOpenTime,
          placeCloseTime,
          location,
          timestamp,
        })
        .then((mess) => {
          //console.log(mess);
        })
        .catch((mess) => {
          console.log(mess);
        });

      if (!isCancelled) {
        setIsPending(false);
        setError(null);
      }
    } catch (err) {
      if (!isCancelled) {
        setError(err.message);
        setIsPending(false);
      }
    }
  };

  useEffect(() => {
    return () => setIsCancelled(true);
  }, []);

  return { addSlot, error, isPending };
};
