import moment from "moment";
import swal from "sweetalert";
import * as React from "react";
import Global from "../../../data/global";
import TimePicker from "../general/TimePicker";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { timestamp } from "../../../firebase/config";
import { useAddBranch } from "../../../hooks/useAddBranch";
import { projectStorage } from "../../../firebase/config";
import { useAuthContext } from "../../../hooks/useAuthContext";
import "../../../style/vendor/AddBranch.css";

export default function AddIndependentBranch() {
  const { user } = useAuthContext();
  const { addBranch, isPending, error } = useAddBranch();
  const [title, setTitle] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState("");
  const [description, setDescription] = useState("");
  const [isWifiAvailbe, setIsWifiAvailbe] = React.useState(true);
  const [isFreeParkingAvailbe, setIsFreeParkingAvailbe] = React.useState(true);
  const [isFoodDrinkAvailbe, setIsFoodDrinkAvailbe] = React.useState(true);
  const [isToiletsAvailbe, setIsToiletsAvailbe] = React.useState(true);
  const [createdBy, setCreatedBy] = React.useState(user.uid);
  const [choosesdImage, setChoosesdImage] = useState();
  const formatedStartTime = moment(startTime).format("hh:mm A");
  const formatedEndTime = moment(endTime).format("hh:mm A");
  const navigate = useNavigate();
  Global.placeOpenTime = formatedStartTime;
  Global.placeCloseTime = formatedEndTime;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (startTime === "" || endTime === "") {
      swal({
        icon: "info",
        text: "Pick Open/Close time",
      });
    }
    else if (choosesdImage === null) {
      swal({
        icon: "info",
        text: "Select Image!",
      });
    }
    else {
      upload();
      swal({
        icon: "success",
        text: "Branch Added",
      });
      navigate("/dashboard/branch-list");
    }
  };

  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setChoosesdImage(e.target.files[0]);
    }
  };

  const removeChoosesdImage = () => {
    setChoosesdImage();
  };

  const upload = async () => {

    if (choosesdImage === null) return;


    const uniqeValue = new Date().getTime();
    const uniqeImageName = uniqeValue.toString() + choosesdImage.name;



    var repeate = true;

    try {
      const uploadTask = await projectStorage
        .ref(`/images/${uniqeImageName}`)
        .put(choosesdImage)
        .on("state_changed", () => {
          if (repeate === true) {
            projectStorage
              .ref("images")
              .child(uniqeImageName)
              .getDownloadURL()
              .then((url) => {
                repeate = false;
                addBranch(
                  createdBy,
                  title,
                  description,
                  isWifiAvailbe,
                  isFreeParkingAvailbe,
                  isFoodDrinkAvailbe,
                  isToiletsAvailbe,
                  Global.lng,
                  Global.lat,
                  Global.placeLocationLable,
                  formatedStartTime,
                  formatedEndTime,
                  url,
                  "active",
                  timestamp.now()
                ).then(
                  () => {
                    console.log("added")
                  }
                )
                  .catch(
                    (e) => {
                      console.log("error:", e)
                    }
                  )
              });
          }
        });
    }
    catch (error) {
      console.log("Error during upload and branch addition:", error);
    }
  };

  const handleChangeWifi = (eventWifi) => {
    setIsWifiAvailbe(eventWifi.target.value);
  };
  const handleChangeParking = (eventParking) => {
    setIsFreeParkingAvailbe(eventParking.target.value);
  };
  const handleChangeFoodDrink = (eventFoodDrink) => {
    setIsFoodDrinkAvailbe(eventFoodDrink.target.value);
  };
  const handleChangeToilets = (eventToilets) => {
    setIsToiletsAvailbe(eventToilets.target.value);
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="branch-auth-form">
        <h2 className="branch-auth-form-h2-tag">
          Add Branch
        </h2>

        <div>
          <label className="branch-auth-form-lable">
            <span className="branch-auth-form-span">
              Area
            </span>
          </label>
          <input
            className="branch-auth-form-input"
            required
            type="text"
            onChange={(e) => setTitle(e.target.value)}
            value={title}
          />
        </div>

        <div>
          <lable className='branch-auth-form-lable'>
            <span className="branch-auth-form-span" style={{ marginTop: '10px' }}>
              Opining and Closing Time
            </span>
          </lable>
          <div className='branch-auth-form-lable' style={{ margin: '0px' }}>
            <div style={{ marginTop: '15px', display: 'flex' }}>
              <p className="branch-auth-form-p-tag" style={{ marginLeft: '-10px', marginRight: '10px' }}>
                From &nbsp;
              </p>
              <TimePicker
                value={startTime}
                onChange={setStartTime}
                style={{ width: "200px", marginLeft: '-10px' }}
              />

              <p className="branch-auth-form-p-tag" style={{ marginLeft: '10px', marginRight: '10px' }}>
                To &nbsp;{" "}</p>
              <TimePicker
                value={endTime}
                onChange={setEndTime}
                style={{ width: "200px", marginLeft: '-10px' }}
              />
            </div>
          </div>
        </div>

        <div>
          <lable className='branch-auth-form-lable'>
            <span className="branch-auth-form-span" for="exampleFormControlTextarea1" style={{ marginTop: '15px' }}>
              Description
            </span>
          </lable>
          <textarea
            required
            className="form-control-textBox"
            id="exampleFormControlTextarea1"
            rows="3"
            // style={{ width: 350, marginLeft: '-10px' }}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
        </div>


        <label style={{ margin: 0 }} >
          <span className="branch-auth-form-span" id="demo-controlled-radio-buttons-group" style={{ marginTop: '15px' }}>
            Wi-Fi Availability
          </span>
        </label>
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={isWifiAvailbe}
          onChange={handleChangeWifi}
        >
          <FormControlLabel value="true" control={<Radio color="success" />} label="true" />
          <FormControlLabel value="false" control={<Radio color="success" />} label="false" />
        </RadioGroup>


        <label style={{ margin: 0 }}>
          <span className="branch-auth-form-span" id="demo-controlled-radio-buttons-group">
            Parking Accessibility
          </span>
        </label>
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={isFreeParkingAvailbe}
          onChange={handleChangeParking}
        >
          <FormControlLabel value="true" control={<Radio color="success" />} label="true" />
          <FormControlLabel value="false" control={<Radio color="success" />} label="false" />
        </RadioGroup>


        <label style={{ margin: 0 }}>
          <span className="branch-auth-form-span" id="demo-controlled-radio-buttons-group">
            Food and Beverages Availability
          </span>
        </label>
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={isFoodDrinkAvailbe}
          onChange={handleChangeFoodDrink}
        >
          <FormControlLabel value="true" control={<Radio color="success" />} label="true" />
          <FormControlLabel value="false" control={<Radio color="success" />} label="false" />
        </RadioGroup>


        <label style={{ margin: 0 }}>
          <span className="branch-auth-form-span" id="demo-controlled-radio-buttons-group">
            Restrooms Availability
          </span>
        </label>
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={isToiletsAvailbe}
          onChange={handleChangeToilets}
        >
          <FormControlLabel value="true" control={<Radio color="success" />} label="true" />
          <FormControlLabel value="false" control={<Radio color="success" />} label="false" />
        </RadioGroup>


        <label className="branch-auth-form-lable">
          <span className="branch-auth-form-span" style={{ marginBottom: '15px' }}>
            Branch Image
          </span>
        </label>
        <input accept=".png , .jpg" type="file" onChange={imageChange} style={{ width: "250px", marginLeft: '-10px', marginBottom: '35px' }} />
        {
          choosesdImage && (
            <div>
              <img
                src={URL.createObjectURL(choosesdImage)}
                style={{ width: "350px" }}
              />
              <button
                onClick={removeChoosesdImage}
                className="slotButton"
                style={{ width: 200, marginLeft: '-10px', marginTop: '35px' }}
              >
                Remove This Image
              </button>
            </div>
          )
        }


        {
          !isPending && (
            <button
              className="slotButton"
              style={{ width: 250, marginLeft: 200, marginRight: 150, marginTop: '30px' }}
              type="submit"
            >
              Add Branch
            </button>
          )
        }

        {
          isPending && (
            <button
              className="slotButton"
              style={{ width: 250, marginLeft: 125, marginRight: 125 }}
              disabled
            >
              loading
            </button>
          )
        }
        {error && <div className="error">{error}</div>}
      </form >
    </div >
  );
}