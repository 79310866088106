import React from "react";
import moment from "moment";
import swal from "sweetalert";
import Global from "../../../data/global";
import mapStyles from "../../mapStyles";
import "@reach/combobox/styles.css";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const libraries = ["places"];
const mapContainerStyle = {
  height: "70vh",
  width: "60vw",
  marginTop: "-50px",
};
const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
};
const center = {
  lat: 23.614328,
  lng: 58.545284,
};

export default function AddIndependentBranchMap() {
  const [startTime, setStartTime] = useState(null);
  const [endTime] = useState("");
  const [lable, setLable, errorLable] = React.useState("");
  const formatedStartTime = moment(startTime).format("hh:mm A");
  const formatedEndTime = moment(endTime).format("hh:mm A");
  const navigate = useNavigate();
  Global.placeOpenTime = formatedStartTime;
  Global.placeCloseTime = formatedEndTime;

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyDt9y9WQH5xvGcqTYAdf9xkP8LTgK4MmRI",
    libraries,
  });
  const [markers, setMarkers] = React.useState([]);
  const [selected, setSelected] = React.useState(null);

  const onChangeStartTime = (time) => {
    setStartTime(time);
  };

  const onMapClick = React.useCallback((e) => {
    setMarkers([]);
    setMarkers((current) => [
      ...current,
      {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
        time: new Date(),
      },
    ]);

  }, []);
  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  const panTo = React.useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(14);
  }, []);

  if (loadError) return;
  // console.log("Error from map");
  if (!isLoaded) return "Loading...";

  const handleSubmit = (e) => {
    e.preventDefault();

    if (selected === null) {
      swal({
        text: "Choose Your Branch Location From Map By Double Click",
      });
    }
    else navigate("/dashboard/add-independent-branch");
  };


  return (
    <div style={{ marginTop: "175px" }}>
      <div>
        <form
          className="auth-form"
          onSubmit={handleSubmit}
          style={{ marginTop: "-70px", marginLeft: "10px" }}
        >
          <label >Place location: </label>
          <input
            required
            type="text"
            id="lable"
            name="lable"
            value={lable}
            onChange={(event) =>
              setLable((Global.placeLocationLable = event.target.value))
            }
          />
          <br />
          <label htmlFor="lng">
            *Choose Your Branch Location From Map By Double Click
          </label>
          <br />
          <button
            className="slotButton"
            style={{ width: 100, marginRight: 150 }}
            type="submit"
          >
            Next
          </button>
        </form>
      </div>
      <div>
        <br />
        &nbsp; &nbsp;
        <GoogleMap
          id="map"
          mapTypeId="hybrid"
          mapContainerStyle={mapContainerStyle}
          zoom={8}
          center={center}
          options={options}
          onClick={onMapClick}
          onLoad={onMapLoad}
        >
          {markers.map((marker) => (
            <Marker
              key={`${marker.lat}-${marker.lng}`}
              position={{ lat: marker.lat, lng: marker.lng }}
              onClick={() => {
                Global.lat = marker.lat;
                Global.lng = marker.lng;
                setSelected(marker);
              }}
              icon={{
                url: `/bear.svg`,
                path: "./WhiteLogo.svg",
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(15, 15),
                scaledSize: new window.google.maps.Size(30, 30),
              }}
            />
          ))}
          {selected ? (
            <InfoWindow
              position={{ lat: selected.lat, lng: selected.lng }}
              onCloseClick={() => {
                setSelected(null);
              }}
            >
              <div>
                <h4>Place coordinates</h4>
                <br />
                <p>lat : {selected.lat}</p>
                <p>lng : {selected.lng}</p>
              </div>
            </InfoWindow>
          ) : null}
        </GoogleMap>
      </div>
    </div>
  );
}
