import React from 'react'
import { useDocument } from "../../../hooks/useDocument";
import { useAuthContext } from "../../../hooks/useAuthContext";
import IconButton from "@mui/material/IconButton";
import EditIcon from '@mui/icons-material/Edit';
import { useFirestore } from "../../../hooks/useFirestore";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { useState } from "react";
import "../../../style/general/UpdateProfile.css";

export default function UpdateUserProfile() {
    const { user } = useAuthContext();
    const { updateProfile } = useFirestore("users");
    const navigate = useNavigate();
    const { document } = useDocument("users", user.uid);

    const [isUserNameVisible, setIsUserNameVisible] = useState(false);
    const [isButtonUserNameVisible, setIsButtonUserNameVisible] = useState(true);

    const [isEmailVisible, setIsEmailVisible] = useState(false);
    const [isButtonEmailVisible, setIsButtonEmailVisible] = useState(true);

    const [isPhoneNumberVisible, setIsPhoneNumberVisible] = useState(false);
    const [isButtonPhoneNumberVisible, setIsButtonPhoneNumberVisible] = useState(true);

    const [isCrNumberVisible, setIsCrNumberVisible] = useState(false);
    const [isButtonCrNumberVisible, setIsButtonCrNumberVisible] = useState(true);
    var updateProfileObject = {};

    const handleEditUserNameClick = () => {

        setIsUserNameVisible(true);
        setIsButtonUserNameVisible(false);
    };

    const handleEditEmailClick = () => {

        setIsEmailVisible(true);
        setIsButtonEmailVisible(false);
    };

    const handleEditPhoneNumberClick = () => {

        setIsPhoneNumberVisible(true);
        setIsButtonPhoneNumberVisible(false);
    };

    const handleEditCrNumberClick = () => {

        setIsCrNumberVisible(true);
        setIsButtonCrNumberVisible(false);
    };

    const onClickUpdateProfile = (e, userId, updateProfileObject) => {
        e.preventDefault();
        updateProfile(userId, updateProfileObject)
            .then(() => {
                swal({
                    icon: "success",
                    text: "Profile successfully updated!",
                });
                navigate("/dashboard/user-profile");
            })
            .catch((error) => {
                console.error('Error updating document: ', error);
            });
    }




    return (
        <form
            className="update-profile-form">
            <h2 className='update-profile-h2-tag'>Edit Profile</h2>
            <div className='update-profile-div'>
                <span className='update-profile-span'>User Name: </span>
                <label className='update-profile-lable'>
                    {document && document.firstName} {document && document.lastName}

                    <IconButton
                        title="Edit"
                        style={{
                            width: "60px",
                            color: " #014D2F",
                            marginLeft: "5px",
                            marginTop: "-10px",
                            display: isButtonUserNameVisible ? "inline-block" : "none"
                        }}
                        onClick={handleEditUserNameClick}
                    >
                        <EditIcon />
                    </IconButton>
                </label>

                {isUserNameVisible &&
                    <div>
                        <input
                            className='update-profile-input'
                            required
                            placeholder="First Name"
                            type="text"
                            onChange={(e) => {
                                updateProfileObject["firstName"] = e.target.value
                            }}
                        />
                        <input
                            className='update-profile-input'
                            required
                            style={{ marginLeft: '15px' }}
                            placeholder="Last Name"
                            type="text"
                            onChange={(e) => {
                                updateProfileObject["lastName"] = e.target.value
                            }}
                        />
                    </div>
                }

                <span className='update-profile-span' >Email: </span>
                <label className='update-profile-lable'>
                    {document && document.email}

                    <IconButton
                        title="Edit"
                        style={{
                            width: "60px",
                            color: " #014D2F",
                            marginLeft: "5px",
                            marginTop: "-10px",
                            display: isButtonEmailVisible ? "inline-block" : "none"
                        }}
                        onClick={handleEditEmailClick}
                    >
                        <EditIcon />
                    </IconButton>
                </label>

                {isEmailVisible &&
                    <div>
                        <input
                            className='update-profile-input'
                            required
                            placeholder="Email"
                            type="text"
                            onChange={(e) => {
                                updateProfileObject["email"] = e.target.value
                            }}
                        />
                    </div>
                }

                <span className='update-profile-span'>  Phone Number: </span>
                <label className='update-profile-lable'>
                    {document && document.phoneNumber}

                    <IconButton
                        title="Edit"
                        style={{
                            width: "60px",
                            color: " #014D2F",
                            marginLeft: "5px",
                            marginTop: "-10px",
                            display: isButtonPhoneNumberVisible ? "inline-block" : "none"
                        }}
                        onClick={handleEditPhoneNumberClick}
                    >
                        <EditIcon />
                    </IconButton>
                </label>
                {isPhoneNumberVisible &&
                    <div>
                        <input
                            className='update-profile-input'
                            required
                            placeholder="Phone Number"
                            type="text"
                            onChange={(e) => {
                                updateProfileObject["phoneNumber"] = e.target.value
                            }}
                        />
                    </div>
                }


                <span className='update-profile-span'>  CR Number: </span>
                <label className='update-profile-lable'>
                    {document && document.ownerId}

                    <IconButton
                        title="Edit"
                        style={{
                            width: "60px",
                            color: " #014D2F",
                            marginLeft: "5px",
                            marginTop: "-10px",
                            display: isButtonCrNumberVisible ? "inline-block" : "none"
                        }}
                        onClick={handleEditCrNumberClick}
                    >
                        <EditIcon />
                    </IconButton>
                </label>

                {isCrNumberVisible &&
                    <div>
                        <input
                            className='update-profile-input'
                            required
                            placeholder="CR Number"
                            type="number"
                            onChange={(e) => {
                                updateProfileObject["ownerId"] = e.target.value
                            }}
                        />
                    </div>
                }

            </div>


            <button
                className="slotButton"
                style={{ width: 250, marginLeft: 300, marginRight: 125, marginTop: 15 }}

                onClick={(e) => {
                    onClickUpdateProfile(e, user.uid, updateProfileObject);
                }}
            >
                Update Profile
            </button>
        </form>
    )
}
