import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import StartIcon from "@mui/icons-material/Start";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import ListAltIcon from "@mui/icons-material/ListAlt";
import PlaylistAddTwoToneIcon from "@mui/icons-material/PlaylistAddTwoTone";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
const drawerItems = [
  {
    title: "Dashboard",
    icon: <PersonOutlineOutlinedIcon style={{ color: "#6c757d", marginRight: '10px' }} />,
    route: "/dashboard",
    users: ["admin", "developer"],
  },
  {
    title: "My Account",
    icon: <PersonOutlineOutlinedIcon style={{ color: "#6c757d", marginRight: '10px' }} />,
    route: "/dashboard/user-profile",
    users: ["admin", "vendor", "business", "developer", "staff"],
  },
  {
    title: "Get Started",
    icon: <StartIcon style={{ color: "#6c757d", marginRight: '10px' }} />,
    route: "/dashboard/map",
    users: ["vendor", "developer"],
  },
  {
    title: "Acount Access",
    icon: <ListAltIcon style={{ color: "#6c757d", marginRight: '10px' }} />,
    route: "/dashboard/acount-access",
    users: ["staff", "developer", "staff"],
  },

  {
    title: "Add Employees",
    icon: <PersonAddOutlinedIcon style={{ color: "#6c757d", marginRight: '10px' }} />,
    route: "/add-employees",
    users: ["business", "developer"],
  },
  {
    title: "Vendors Account Status",
    icon: <ListAltIcon style={{ color: "#6c757d", marginRight: '10px' }} />,
    route: "/dashboard/vendor-list",
    users: ["developer", "admin"],
  },
  {
    title: "Add Package",
    icon: <AddCircleOutlineOutlinedIcon style={{ color: "#6c757d", marginRight: '10px' }} />,
    route: "/dashboard/add-package",
    users: ["developer"],
  },
  // {
  //   title: "Packages List",
  //   icon: <ListAltIcon style={{ color: "#6c757d" }} />,
  //   route: "/dashboard/package-list",
  //   users: ["vendor", "developer"],
  // },
  {
    title: "Branch List",
    icon: <ListAltIcon style={{ color: "#6c757d", marginRight: '10px' }} />,
    route: "/dashboard/branch-list",
    users: ["vendor", "developer"],
  },
  {
    title: "Slots List",
    icon: <ListAltIcon style={{ color: "#6c757d", marginRight: '10px' }} />,
    route: "/dashboard/slot-list",
    users: ["vendor", "developer"],
  },
  {
    title: "Employees List",
    icon: <ListAltIcon style={{ color: "#6c757d", marginRight: '10px' }} />,
    route: "/dashboard/add-employee-list",
    users: ["business", "developer"],
  },
  {
    title: "Booking",
    icon: <ListAltIcon style={{ color: "#6c757d", marginRight: '10px' }} />,
    route: "/dashboard/orders-list",
    users: ["vendor"],
  },
  // {
  //   title: "User Profile",
  //   icon: <PersonIcon />,
  //   route: "/dashboard/user-profile",
  //   users: ["admin"],
  // },
  {
    title: "Vendors Reservations",
    icon: <PlaylistAddTwoToneIcon style={{ color: "#6c757d", marginRight: '10px' }} />,
    route: "/dashboard/vendors-reservations",
    users: ["admin"],
  },
  {
    title: "Give Access",
    icon: <HowToRegIcon style={{ color: "#6c757d", marginRight: '10px' }} />,
    route: "/dashboard/give-access",
    users: ["vendor", "developer"],
  },
  {
    title: "Customers Managment",
    icon: <ListAltIcon style={{ color: "#6c757d", marginRight: '10px' }} />,
    route: "/dashboard/customers-managment",
    users: ["admin", "developer"],
  },
  {
    title: "Staff Managment",
    icon: <ListAltIcon style={{ color: "#6c757d", marginRight: '10px' }} />,
    route: "/dashboard/staff-managment",
    users: ["admin", "developer"],
  },
  {
    title: "Reservations",
    icon: <ListAltIcon style={{ color: "#6c757d", marginRight: '10px' }} />,
    route: "/dashboard/reservations",
    users: ["admin", "developer"],
  },
];

export default drawerItems;
