import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { projectFirestore } from "../../../firebase/config";
import { useCollection } from "../../../hooks/useCollection";
import { useAuthContext } from "../../../hooks/useAuthContext";

export default function AcountAccess() {
  const navigate = useNavigate();
  const { documents } = useCollection("users");
  const { user } = useAuthContext();
  const [accessAccountsState, setAccessAccountsState] = useState([]);
  const [fetchData, setFetchData] = useState([]);
  var count = 0;
  let headers = new Headers();

  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");

  headers.append("Access-Control-Allow-Origin", "http://localhost:3001");
  headers.append("Access-Control-Allow-Credentials", "true");

  useEffect(() => {
    async function fetchResult() {
      await fetch(
        `https://us-central1-slot-145a8.cloudfunctions.net/getUserAccessAcounts?userID=${user.uid}`
      )
        .then((res) => res.json())
        .then((result) => {
          setFetchData(result.data);
        },
          (error) => {
            console.log(error);
          }
        );
    }
    fetchResult();
  }, []);

  const users = documents
    ? documents.filter((userData) => {
      if (user.uid === userData.id) {
        if (userData.access != null) {
          count = userData.access.length;
          if (count > accessAccountsState.length) {
            userData.access.forEach((element, index) => {
              const accountId = element.accountId;
              const ref = projectFirestore.collection("users").doc(accountId);
              ref.onSnapshot(
                (snapshot) => {
                  if (snapshot.data()) {
                    setAccessAccountsState([
                      ...accessAccountsState,
                      snapshot.data(),
                    ]);
                  } else {
                  }
                },
                (err) => {
                  console.log(err.message);
                }
              );
            });
          }
          return user.uid === userData.id;
        }
      }
    })
    : null;

  useEffect(() => { }, [accessAccountsState]);

  return (
    <div style={{ marginTop: "150px", padding: "0 100px" }}>
      <h2
        variant="h4"
        style={{
          fontFamily: "NexaDemo-Bold",
          fontSize: "30px",
          color: " #014D2F",
        }}
        className="page-title"
      >
        Access Acount
      </h2>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  fontWeight: "bold",
                  fontFamily: "NexaDemo-Bold",
                  fontSize: "18px",
                }}
              >
                Brand Name
              </TableCell>
              <TableCell
                align="right"
                style={{
                  fontWeight: "bold",
                  fontFamily: "NexaDemo-Bold",
                  fontSize: "18px",
                }}
              >
                Vendor Name
              </TableCell>
              <TableCell
                align="right"
                style={{
                  fontWeight: "bold",
                  fontFamily: "NexaDemo-Bold",
                  fontSize: "18px",
                }}
              >
                Email
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fetchData.length === 0 && <p>No Access yet!</p>}
            {fetchData &&
              fetchData.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  onClick={() => {
                    navigate(
                      "/dashboard/staff-order-list/" +
                      users[0].access[index].accountId
                    );
                  }}
                  hover={true}
                >
                  <TableCell component="th" scope="row">
                    {row.brandName}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.firstName + " " + row.lastName}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.email}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
