class Global{
    static type = "";
    static test = "test";
    static loginId = "";
    static lng = "";
    static lat = "";
    static placeLocationLable = "";
    static branchId = "";
    static selctedBranch = "";
    static placeOpenTime = "";
    static placeCloseTime = "";
    static element = "";
    static bookedBy = "";
    static branchIds = [];
    static brandName = "";
    static isInLoop = true ;
    static packageId = "" ;
}
export {Global} ;

export default Global;