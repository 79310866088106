import moment from "moment";
import swal from "sweetalert";
import * as React from "react";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import EditIcon from "@mui/icons-material/Edit";
import HelpIcon from "@mui/icons-material/Help";
import TimePicker from ".././general/TimePicker";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import PhoneInput from "react-phone-number-input";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import TableContainer from "@mui/material/TableContainer";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAddSlot } from "../../../hooks/useAddSlot";
import { projectStorage } from "../../../firebase/config";
import { useCollection } from "../../../hooks/useCollection";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useGlobalContext } from "../../../hooks/useGlobalContext";
import "react-phone-number-input/style.css";
import "../../../style/vendor/AddSlot.css";

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export default function AddSlot() {
  const navigate = useNavigate();
  const { pageType } = useParams();
  const { user } = useAuthContext();
  const { dispatch } = useGlobalContext();
  const { addSlot, isPending, error } = useAddSlot();
  const { documents } = useCollection("branches");
  const [slotDate] = useState("");
  const [autoAccept] = React.useState(false);
  const [createdBy] = React.useState(user.uid);
  const [slotLable, setSlotLable] = useState("");
  const [feeMinPrice, setFeeMinPrice] = useState("");
  const [branch, setbranch] = React.useState("");
  const [feesOnVendor, setFeesOnVendor] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const [numbeOfChair, setNumbeOfChair] = useState("");
  const [selectedImage, setSelectedImage] = useState();
  const [currency, setCurrency] = React.useState("");
  const [slotType, setSlotType] = React.useState("");
  const [lableSelect, setLableSelect] = React.useState(true);
  const [privateUserList, setPrivateUserList] = useState([]);
  const [slotHour, setSlotHour] = React.useState("");
  const [slotPrice, setSlotPrice] = useState("");
  const [base_pricing, setbase_pricing] = useState([]);
  const phoneNumberList = [];
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const formatedStartTime = moment(startTime).format("hh:mm A");
  const formatedEndTime = moment(endTime).format("hh:mm A");
  const [inflation, setInflation] = useState();
  const [inflationType, setInflationType] = React.useState("");
  const [inflation_List, setIflationList] = useState([]);

  {
    /* Opening-Close Time Table*/
  }
  const [openTime, setOpenTime] = useState("");
  const [closeTime, setCloseTime] = useState("");
  const formatedOpenTime = moment(openTime).format("hh:mm A");
  const formatedCloseTime = moment(closeTime).format("hh:mm A");
  const [editIndex, setEditIndex] = useState(-1);
  const [showTable, setShowTable] = useState(false);
  const [cellValues, setCellValues] = useState(Array(7).fill(""));
  const [opening_time, setOpeningTime] = useState([]);
  const daysOfWeek = [
    "SUNDAY",
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
  ];


  useEffect(() => {
    const initialCellValues = Array(7).fill({ open_time: "", close_time: "" });
    const initialOpeningTime = daysOfWeek.map((day) => ({
      day,
      open_time: "",
      close_time: "",
    }));
    setCellValues(initialCellValues);
    setOpeningTime(initialOpeningTime);
  }, []);


  const handleFocus = (e) => {
    e.target.blur(); // Remove focus from the input
  };

  const handleEditIndex = (index) => {
    setEditIndex(index);
  };

  const handleInputChange = (index, value, field) => {
    const updatedCellValues = [...cellValues];
    const updatedValue = value.format("hh:mm A");
    updatedCellValues[index][field] = updatedValue;
    setCellValues(updatedCellValues);
  };

  const handleUpdateTime = (index) => {
    setEditIndex(-1);
    const updatedOpeningTime = [...opening_time];
    updatedOpeningTime[index].open_time = cellValues[index].open_time;
    updatedOpeningTime[index].close_time = cellValues[index].close_time;
    setOpeningTime(updatedOpeningTime);
  };

  const handleDelete = (index) => {
    const updatedOpeniningTime = [...opening_time];
    updatedOpeniningTime.splice(index, 1);
    setOpeningTime(updatedOpeniningTime);
    setEditIndex(-1);
  };

  const openingClosingTable = (
    <TableContainer component={Paper}>
      <Table id="opening-hours-table">
        <TableHead>
          <TableRow>
            <TableCell style={{ fontSize: '18px' }}>Day</TableCell>
            <TableCell style={{ fontSize: '18px' }}>Opening - Closing Time</TableCell>
            <TableCell style={{ fontSize: '18px' }}>Edit</TableCell>
            <TableCell style={{ fontSize: '18px' }}>Off Day</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {opening_time.map((row, index) => {
            const { day } = row;
            const isEditMode = index === editIndex;

            return (
              <TableRow key={day}>
                <TableCell>{day}</TableCell>
                <TableCell>
                  {isEditMode ? (
                    <div>
                      <p className="slot-form-p-tag ">Open Time: </p>
                      <TimePicker
                        value={cellValues[index].open_time}
                        onChange={(time) =>
                          handleInputChange(index, time, "open_time")
                        }
                      />
                      <p className="slot-form-p-tag ">close Time: </p>
                      <TimePicker
                        value={cellValues[index].close_time}
                        onChange={(time) =>
                          handleInputChange(index, time, "close_time")
                        }
                      />
                      <IconButton
                        title="Check"
                        style={{
                          width: "60px",
                          color: " #014D2F",
                          marginLeft: "5px",
                          marginTop: "-10px",
                        }}
                        onClick={() => handleUpdateTime(index)}
                      >
                        <CheckIcon />
                      </IconButton>
                    </div>
                  ) : (
                    `${row.open_time} - ${row.close_time}`
                  )}
                </TableCell>
                <TableCell>
                  <IconButton
                    title="Edit"
                    style={{
                      width: "60px",
                      color: " #014D2F",
                      marginLeft: "5px",
                      marginTop: "-10px",
                    }}
                    onClick={() => handleEditIndex(index)}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => handleDelete(index)}
                    title="Delete"
                    style={{ width: "60px", color: "red" }}
                  >
                    <ClearIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const handleFormSubmitOpenTime = (e) => {
    e.preventDefault();
    if (formatedOpenTime == 'Invalid date' || formatedCloseTime == 'Invalid date') {
      swal({
        icon: "info",
        text: "Choose Open and Close Time ! ",
      });
    }

    const updatedOpeningTime = opening_time.map((cellValue, index) => ({
      day: cellValue.day,
      open_time: formatedOpenTime,
      close_time: formatedCloseTime,
    }));
    setOpeningTime(updatedOpeningTime);
    setShowTable(true);
  };

  {
    /* Opening-Close Time Table */
  }

  const validateDuration = () => {
    if (base_pricing.filter((e) => e.hours == slotHour).length == 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmitHourPrice = (e) => {
    e.preventDefault();
    if (!validateDuration())
      return swal({
        icon: "info",
        text: "This Duration is alreday Enter ! ",
      });

    if (slotHour == "" || slotPrice == "")
      return swal({
        icon: "info",
        text: "fileds can not be empty ! ",
      });

    setbase_pricing([
      ...base_pricing,
      { hours: parseInt(slotHour), price: parseFloat(slotPrice) },
    ]);
  };

  const validateInflation = () => {
    var valid = true;

    if (inflation_List.length != 0) {
      inflation_List.forEach((e, index) => {
        // check if input time between the range
        if (
          formatedStartTime >= e.start_time &&
          formatedStartTime <= e.end_time &&
          formatedEndTime >= e.start_time &&
          formatedEndTime <= e.end_time
        ) {
          valid = false;
          return false;
        }
        // check if input time start between the range and end after the range
        else if (
          formatedStartTime > e.start_time &&
          formatedStartTime < e.end_time &&
          formatedEndTime > e.end_time
        ) {
          valid = false;
          return false;
        }
        // check if input time start after the range and end between the range
        else if (
          formatedStartTime < e.start_time &&
          formatedEndTime > e.start_time &&
          formatedEndTime < e.end_time
        ) {
          valid = false;
          return false;
        }
        // check if input time start before the range and end after the range
        else if (
          formatedStartTime < e.start_time &&
          formatedEndTime > e.end_time
        ) {
          valid = false;
          return false;
        } else {
          valid = true;
          return true;
        }
      });
    } else {
      valid = true;
      return true;
    }
    return valid;
  };

  const handleSubmitinflation = (e) => {
    e.preventDefault();
    var validInf = validateInflation();
    if (!validInf)
      return swal({
        icon: "info",
        text: "Conflict in time ! ",
      });

    // check if fileds empty
    if (
      startTime == "" ||
      endTime == "" ||
      inflation == "undefined" ||
      inflationType == ""
    )
      return swal({
        icon: "info",
        text: "Fileds can not be empty ! ",
      });

    if (inflation <= 0 || inflation > 100) {
      return swal({
        icon: "info",
        text: "Inflation must be between 0 - 100!",
      });
    }

    setIflationList([
      ...inflation_List,
      {
        start_time: formatedStartTime,
        end_time: formatedEndTime,
        inflation: parseFloat(inflation / 100),
        type: inflationType,
      },
    ]);
    validateInflation();
  };

  const handleFeeChecke = () => {
    setFeesOnVendor(!feesOnVendor);
  };

  const handlePrivateChecke = () => {
    setIsPrivate(!isPrivate);
  };

  const handleFormChange = (index, event) => {
    let data = [...privateUserList];
    console.log(event);
    data[index] = event;
    setPrivateUserList(data);
  };

  const addFields = () => {
    let newfield = [];
    setPrivateUserList([...privateUserList, newfield]);
  };

  const handleCurrency = (currency) => {
    setCurrency(currency.target.value);
  };

  const handleChangeSlotType = (slotType) => {
    setSlotType(slotType.target.value);
  };

  const handleChangeInflationType = (inflationType) => {
    setInflationType(inflationType.target.value);
  };

  const deleteBasePriceRow = (index) => {
    setbase_pricing(base_pricing.filter((v, i) => i !== index));
  };

  const deleteInflationRow = (index) => {
    setIflationList(inflation_List.filter((v, i) => i !== index));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isPrivate === true) {
      if (privateUserList !== null) {
        privateUserList.forEach((element, index) => {
          if (
            element.length === 12 &&
            (element.substring(4, 6).startsWith("9") ||
              element.substring(4, 6).startsWith("7"))
          ) {
            phoneNumberList.push(element);
          }
        });
        if (phoneNumberList.length === privateUserList.length) {
          if (selectedImage === null) {
            swal({
              icon: "info",
              text: "Select Image!",
            });
          } else {
            upload();
            swal({
              icon: "success",
              text: "Slot Added",
            });
            if (pageType === "indpendent") {
              navigate("/dashboard/slot-list");
            } else {
              navigate("/dashboard/slot-list");
            }
          }
        } else {
          swal({
            icon: "info",
            text: "phone number should be 8 digits and start with 7 or 9",
          });
        }
      } else {
        swal({
          icon: "info",
          text: "You Should fill At Least One Phone Number!",
        });
      }
    } else {
      if (selectedImage === null) {
        swal({
          icon: "info",
          text: "Select Image!",
        });
      } else {
        upload();
        swal({
          icon: "success",
          text: "Slot Added",
        });
        if (pageType === "indpendent") {
          navigate("/dashboard/slot-list");
        } else {
          navigate("/dashboard/slot-list");
        }
      }
    }
  };

  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };

  const removeSelectedImage = () => {
    setSelectedImage();
  };

  const upload = () => {
    const uniqeValue = new Date().getTime();
    const uniqeImageName = uniqeValue.toString() + selectedImage.name;

    if (selectedImage === null) return console.log("Error from upload");

    var uploadTask = projectStorage
      .ref(`/images/${uniqeImageName}`)
      .put(selectedImage)
      .on("state_changed", null, null, () => {
        projectStorage
          .ref("images")
          .child(uniqeImageName)
          .getDownloadURL()
          .then((url) => {
            addSlot(
              autoAccept,
              feeMinPrice,
              feesOnVendor,
              isPrivate,
              privateUserList,
              createdBy,
              currency,
              slotLable,
              numbeOfChair,
              base_pricing,
              inflation_List,
              opening_time,
              slotType,
              url,
              slotDate
            );
          });
      });
  }


  const handleChange = (event) => {
    dispatch({ type: "SET_BRANCH_IDS", payload: [event.target.value.branch] });
    setbranch(event.target.value.title);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const getBranchSection = () => {
    {
      if (pageType === "indpendent") {
        return (
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-standard-label" style={{ margin: '0px' }}>Branches</InputLabel>
            {/* {lableSelect === true ? " Branches" : null} */}

            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={branch}
              onChange={handleChange}
              style={{ width: 200 }}
              MenuProps={MenuProps}
              renderValue={(selected) => {
                setLableSelect(false);
                return selected;
              }}
            >
              {documents && documents.length === 0 && <p>No Branch yet!</p>}
              {documents &&
                documents.map((branch) =>
                  branch.createdBy === user.uid ? (
                    <MenuItem
                      key={branch.id}
                      value={{ title: branch.title, branch: branch.id }}
                      style={{ backgroundColor: 'white' }}
                    >
                      {branch.title}
                    </MenuItem>
                  ) : null
                )}
            </Select>
          </FormControl>
        );
      } else {
        <div></div>;
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} className="slot-form">
      <h2 className="slot-form-h2-tag">Add Slot</h2>

      {getBranchSection()}

      <div style={{ display: 'flex' }}>
        <div style={{ marginRight: '80px' }}>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} style={{ marginTop: '45px' }}>
            <InputLabel id="demo-simple-select-standard-label" style={{ margin: '0px' }}>Currency</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={currency}
              onChange={handleCurrency}
              style={{ width: 200 }}
            >
              <MenuItem value="USD">USD</MenuItem>
              <MenuItem value="OMR">OMR</MenuItem>
              <MenuItem value="AED">AED</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} style={{ marginTop: '45px' }}>
            <InputLabel id="demo-simple-select-standard-label" style={{ margin: '0px' }}>Slot Type</InputLabel>

            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={slotType}
              onChange={handleChangeSlotType}
              style={{ width: 200 }}
            >
              <MenuItem value="room" >Room</MenuItem>
              <MenuItem value="table">Table</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>


      <div style={{ display: 'flex', marginTop: '25px' }}>
        <div style={{ marginRight: '100px' }}>
          <label className="slot-form-lable">
            <span className="slot-form-headers">Slot Lable</span>
          </label>
          <input
            className="placeHolder"
            required
            placeholder="Example: A"
            type="text"
            onChange={(e) => setSlotLable(e.target.value)}
            value={slotLable}
            style={{ margin: "0px" }}
          />
        </div>

        <div>
          <label className="slot-form-lable">
            <span className="slot-form-headers">Seat Availability</span>
          </label>
          <input
            min="0"
            max="100"
            style={{ margin: "0px" }}
            required
            type="number"
            onChange={(e) => setNumbeOfChair(e.target.value)}
            onFocus={handleFocus}
            value={numbeOfChair}
          />
        </div>
      </div>


      {/* Base Pricing Table */}
      <div style={{ marginTop: '30px' }}>
        <label className="slot-form-lable">
          <span className="slot-form-headers">Base Pricing</span>
        </label>
        <div>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <div >
                      <p className="slot-form-p-tag">Hours</p>
                      <input
                        style={{ width: 200 }}
                        placeholder="Duration"
                        type="text"
                        onChange={(e) => setSlotHour(e.target.value)}
                        value={slotHour}
                      />
                    </div>
                  </TableCell>

                  <TableCell>
                    <div style={{ marginRight: '58px' }}>
                      <p className="slot-form-p-tag">Price</p>
                      <input
                        style={{ width: 200 }}
                        placeholder="Price"
                        type="text"
                        onChange={(e) => setSlotPrice(e.target.value)}
                        value={slotPrice}
                      />
                    </div>
                  </TableCell>

                  <TableCell>
                    <IconButton
                      onClick={handleSubmitHourPrice}
                      title="Add Base Price"
                      style={{
                        width: "60px",
                        color: " #014D2F",
                        marginLeft: "-70px",
                      }}
                    >
                      <AddCircleIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>

                <TableRow
                  style={{
                    fontWeight: "bold",
                    fontFamily: "NexaDemo-Bold",
                    fontSize: "18px",
                  }}
                >
                  <TableCell style={{ fontSize: '18px' }} align="right">Hours</TableCell>
                  <TableCell style={{ fontSize: '18px' }} align="right">Price</TableCell>
                  <TableCell style={{ fontSize: '18px' }} align="right">Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {base_pricing.map((input, index) => {
                  return (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      hover={true}
                    >
                      <TableCell component="th" scope="row">
                        {input.hours}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {input.price}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => deleteBasePriceRow(index)}
                          title="delete"
                          style={{
                            width: "60px",
                            color: "red",
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>

      {/* Inflation Table */}
      <div style={{ marginTop: '30px' }}>
        <label className="slot-form-lable">
          <span className="slot-form-headers">Inflation</span>
        </label>
        <div >
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ borderBottom: "none" }}>
                    <p className="slot-form-p-tag " >Start Time</p>
                    <TimePicker value={startTime} onChange={setStartTime} />
                  </TableCell>

                  <TableCell style={{ borderBottom: "none" }}>
                    <p className="slot-form-p-tag" style={{ marginLeft: '50px' }}>End Time</p>
                    <TimePicker value={endTime} onChange={setEndTime} style={{ marginLeft: '50px' }} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p className="slot-form-p-tag">Inflation</p>
                    <input
                      style={{ width: 200 }}
                      placeholder="Inflation (%)"
                      type="number"
                      min="0"
                      max="100"
                      onChange={(e) => setInflation(e.target.value)}
                      value={inflation}
                    />

                  </TableCell>

                  <TableCell>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} style={{ marginLeft: '50px' }}>
                      <InputLabel
                        id="demo-simple-select-standard-label"
                        style={{ margin: '0px', }}
                      >Type</InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={inflationType}
                        onChange={handleChangeInflationType}
                        style={{ width: 200, }}
                      >
                        <MenuItem value="INCREASE">INCREASE</MenuItem>
                        <MenuItem value="DECREASE">DECREASE</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={handleSubmitinflation}
                      style={{
                        width: "60px",
                        color: " #014D2F",
                        marginLeft: "-10px",
                        marginTop: "-30px",
                      }}
                    >
                      <AddCircleIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow
                  style={{
                    fontWeight: "bold",
                    fontFamily: "NexaDemo-Bold",
                    fontSize: "18px",
                  }}
                >
                  <TableCell style={{ fontSize: '18px' }}>Start Time</TableCell>
                  <TableCell style={{ fontSize: '18px' }} align="right">End Time</TableCell>
                  <TableCell style={{ fontSize: '18px' }} align="right">Inflation</TableCell>
                  <TableCell style={{ fontSize: '18px' }} align="right">Type</TableCell>
                  <TableCell style={{ fontSize: '18px' }} align="right">Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {inflation_List.map((input, index) => {
                  return (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      hover={true}
                    >
                      <TableCell component="th" scope="row">
                        {input.start_time}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {input.end_time}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {input.inflation}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {input.type}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => deleteInflationRow(index)}
                          title="delete"
                          style={{
                            width: "60px",
                            color: "red",
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>

      {/* Open-Close Time Table */}

      <div style={{ marginTop: '30px' }}>
        <div>
          <form>
            <label className="slot-form-lable" htmlFor="weekdays-time-range">
              <span className="slot-form-headers">
                Weekdays Opening and Closing Time{" "}
              </span>
            </label>
            <div style={{ display: 'flex ' }}>
              <span >Open Time</span>
              <TimePicker value={openTime} onChange={setOpenTime} style={{ marginLeft: '10px', marginRight: '10px', }} />

              <span>Close Time</span>
              <TimePicker value={closeTime} onChange={setCloseTime} style={{ marginLeft: '10px' }} />
              <button
                className="check-icon"
                type="submit"
                onClick={handleFormSubmitOpenTime}
              >
                <CheckCircleOutlineIcon />
                {/* Apply */}
              </button>
            </div>
          </form>
        </div>

        {showTable && <div>{openingClosingTable}</div>}
      </div>
      {/* Open-Close Time Table */}

      <div style={{ display: 'flex', marginTop: '20px' }}>
        <div style={{ marginRight: '150px' }}>
          <lable>
            <span
              className="slot-form-headers"
              id="demo-controlled-radio-buttons-group"
              style={{ margin: '0px' }}
            >
              Fee On Us{" "}
            </span>
            <button
              type="button"
              title="By checking this box, you agrees to to be charged for the fees instead of the user."
            >
              <HelpIcon
                style={{ marginTop: "-28px", fontSize: "15px", color: "grey" }}
              />
            </button>

            <Checkbox
              checked={feesOnVendor}
              onChange={handleFeeChecke}
              style={{ color: "#014D2F" }}
            />
          </lable>

          {feesOnVendor && (
            <label id="feeMinPrice" style={{ display: "block", margin: '0px' }}>
              <span >Minimum Price</span>
              <div>
                <input
                  min="1"
                  max="100"
                  required
                  type="number"
                  value={feeMinPrice}
                  onChange={(e) => setFeeMinPrice(e.target.value)}
                  style={{ margin: '0px', marginBottom: '10px' }}
                  onFocus={handleFocus}
                />
              </div>
            </label>
          )}
        </div>
        {/* </div>

      <div style={{ marginTop: '10px' }}> */}
        <div>
          <lable>
            <span
              className="slot-form-headers"
              id="demo-controlled-radio-buttons-group"
              style={{ margin: '0px' }}
            >
              Is Slot Private?{" "}
            </span>
            <Checkbox
              value={isPrivate}
              onChange={handlePrivateChecke}
              style={{ color: "#014D2F" }}
            />
          </lable>

          {isPrivate && (
            <div style={{ display: "block" }}>
              <lable id="userFiledsLable">
                <span >Phone Number</span>
                {privateUserList.map((input, index) => {
                  return (
                    <div key={index}>
                      <PhoneInput
                        className="phoneNumber"
                        required
                        international
                        maxLength={14}
                        name="phoneNumber"
                        defaultCountry="OM"
                        style={{ marginTop: '10px' }}
                        value={index.input}
                        onChange={(event) => handleFormChange(index, event)}
                      />
                    </div>
                  );
                })}
                <IconButton
                  title="Add Another User"
                  style={{
                    width: "60px",
                    color: " #014D2F",
                    marginLeft: "-20px",
                    marginTop: "-30px",
                  }}
                  onClick={addFields}
                >
                  <AddCircleIcon />
                </IconButton>
              </lable>
            </div>
          )}
        </div>
      </div>

      <div style={{ marginTop: '10px' }}>
        <label style={{ margin: '0px' }}>
          <span className="slot-form-headers" style={{ margin: '0px' }}>Slot Image</span>
          <input style={{ marginTop: '10px' }} accept=".png , .jpg" type="file" onChange={imageChange} />
          {selectedImage && (
            <div>
              <img
                src={URL.createObjectURL(selectedImage)}
                style={{ width: "250px" }}
              />
              <button
                onClick={removeSelectedImage}
                className="slotButton"
                style={{ width: 200, }}
              >
                Remove This Image
              </button>
            </div>
          )}
        </label>
      </div>
      {
        !isPending && (
          <button
            className="slotButton"
            style={{ width: 250, marginLeft: 325, marginRight: 125 }}
          >
            Add Slot
          </button>
        )
      }
      {
        isPending && (
          <button className="slotButton" disabled>
            loading
          </button>
        )
      }
      {error && <div className="error">{error}</div>}
    </form >
  );
}
