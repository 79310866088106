import React from "react";
import DownloadIcon from '@mui/icons-material/Download';
import {
  Paper,
  TableCell,
  TableRow,
  TableContainer,
  TableBody,
  Table,
  TableHead,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useFirestore } from "../../../hooks/useFirestore";
import { invoiceHeaders } from "../../../data/tableHeaders";
import { useCollectionGroup } from "../../../hooks/useCollectionGroup";
import "../../../style/vendor/OrdersList.css";
import "../../../style/admin/VendorReservationList.css";
import "../../../style/admin/Invoice.css";


export default function Invoice() {
  const { id } = useParams();
  var invoiceList = [];
  var total = 0.0;
  var paidBySlottotal = 0.0;
  var unpaidBySlottotal = 0.0;
  const [searchDate, setSearchDate] = React.useState('');
  const [searchFromDate, setSearchFromDate] = React.useState("");
  const [searchToDate, setSearchToDate] = React.useState("");
  const [filteredInvoiceList, setFilteredInvoiceList] = useState([]);
  const { updateReservation } = useFirestore("slots");
  const { documents: reservations, finalTotalIncome } = useCollectionGroup(
    "reservations",
    ["slotOwner", "==", id],
    ["timestamp", "desc"]
  );




  reservations &&
    reservations.forEach((reservation) => {
      if (reservation["status"] !== "ACCEPTED") return;

      invoiceList.push(reservation);
      total += reservation["totalIncome"];
      if (reservation["paidBySlot"]) {
        paidBySlottotal += reservation["totalIncome"];
      } else {
        unpaidBySlottotal += reservation["totalIncome"];
      }
    });

  const clearFilter = () => {
    setSearchDate("");
    setSearchToDate("");
    setSearchFromDate("");
    setFilteredInvoiceList([]);

  };


  const handleSearchFromDateChange = (e) => {
    const selectedDate = e.target.value;
    setSearchFromDate(selectedDate);
  };

  const handleSearchToDateChange = (e) => {
    const selectedDate = e.target.value;
    setSearchToDate(selectedDate);
  };


  const removeTimeFromDate = (inputDate) => {
    const year = inputDate.getFullYear();
    const month = inputDate.getMonth();
    const day = inputDate.getDate();
    const dateWithoutTime = new Date(year, month, day);
    return dateWithoutTime;
  }


  const getDisplayedData = () => {
    if (searchFromDate === "" && searchToDate === "") {
      return invoiceList;
    } else {
      const fromDate = searchFromDate && new Date(searchFromDate);
      const toDate = searchToDate && new Date(searchToDate);
      return invoiceList.filter((u) => {
        const reservationDate = new Date(u.bookedDate);
        if (fromDate && toDate) {
          var result = removeTimeFromDate(reservationDate) >= removeTimeFromDate(fromDate) && removeTimeFromDate(reservationDate) <= removeTimeFromDate(toDate);
          return result;
        }
        else if (fromDate) {
          return removeTimeFromDate(reservationDate) >= removeTimeFromDate(fromDate);
        } else if (toDate) {
          return removeTimeFromDate(reservationDate) <= removeTimeFromDate(toDate);
        }
        return true;
      });
    }
  };


  const exportToCSV = () => {
    const dataToExport = getDisplayedData();

    const columnNames = [
      "Date",
      "Time",
      "Slot Label",
      "Duration",
      "Price",
      "Paid by Slot",
    ];

    const csvContent =
      "data:text/csv;charset=utf-8," +
      [columnNames.join(","), ...dataToExport.map(formatExportRow)].join("\n");

    const encodedUri = encodeURI(csvContent);

    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "invoice_data.csv");
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };


  //columns that exported
  const formatExportRow = (row) => {
    const formattedRow = {
      Date: row.bookedDate,
      Time: `${row.fromTime} - ${row.toTime}`,
      "Slot Label": row.slotLable,
      Duration: formatExportDuration(row.period),
      Price: `${row.totalIncome} OMR`,
      "Paid by Slot": row.paidBySlot ? "Yes" : "No",
    };
    return Object.values(formattedRow).map((value) => JSON.stringify(value));
  };

  //duration format
  const formatExportDuration = (period) => {
    const [hours, minutes] = period.split(":").map(Number);
    if (hours === 1 && minutes === 0) {
      return "1 hr";
    } else if (hours > 1) {
      return `${hours} hrs`;
    } else {
      return `${minutes} mins`;
    }
  };


  return (
    <div className="mainDiv">
      {" "}
      <h2
        style={{
          fontSize: "30px",
          color: " #014D2F",
        }}
        className="page-title"
      >
        Unpaid Reservations
      </h2>
      <div className="columns">
        <div className="column">
          <div className="card">
            <div className="card-content">
              <div className="content">
                <center>
                  <div className="title"> {total.toFixed(3)}</div>
                  <div className="subtitle slot-green">Total</div>
                </center>
              </div>
            </div>
          </div>
        </div>
        <div className="column">
          <div className="card">
            <div className="card-content">
              <div className="content">
                <center>
                  <div className="title"> {paidBySlottotal.toFixed(3)}</div>
                  <div className="subtitle slot-green">Paid</div>
                </center>
              </div>
            </div>
          </div>
        </div>
        <div className="column">
          <div className="card">
            <div className="card-content">
              <div className="content">
                <center>
                  <div className="title"> {unpaidBySlottotal.toFixed(3)}</div>
                  <div className="subtitle slot-green">Unpaid</div>
                </center>
              </div>
            </div>
          </div>
        </div>
        <div className="column">
          <div className="card">
            <div className="card-content">
              <div className="content">
                <center>
                  <div className="title">
                    {" "}
                    {(total - total * 0.05).toFixed(3)} OMR
                  </div>
                  <div className="subtitle slot-green">Without VAT</div>
                </center>
              </div>
            </div>
          </div>
        </div>
        <div className="column">
          <div className="card">
            <div className="card-content">
              <div className="content">
                <center>
                  <div className="title">
                    {" "}
                    {(Number(finalTotalIncome) * 0.05).toFixed(3)}
                  </div>
                  <div className="subtitle slot-green">VAT</div>
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div> */}
      <p style={{ fontWeight: 'bolder' }}>Search By Date:</p>
      <div className="input-container">
        <span>From</span>
        <input
          type="date"
          value={searchFromDate}
          onChange={handleSearchFromDateChange}
          style={{ margin: "10px", width: 300 }}
        />
        <span>To</span>
        <input
          type="date"
          value={searchToDate}
          onChange={handleSearchToDateChange}
          style={{ margin: "10px", width: 300 }}
        />
      </div>
      <div className="clear-export-buttons">
        <button
          style={{ width: 150 }}
          className="slotButton"
          onClick={clearFilter}>
          Clear
        </button>
        <button
          style={{ marginLeft: '10px', width: 150 }}
          className="slotButton"
          onClick={exportToCSV}
        >
          <DownloadIcon />
          Export</button>
      </div>

      {/* </div> */}
      <div className="PackageList">
        {!reservations && <p>Loading Credit List...</p>}
        {reservations && reservations.length === 0 && (
          <p>No packages added yet!</p>
        )}
        {getDisplayedData().length !== 0 ? (
          <TableContainer component={Paper}>
            <Table
              stickyHeader={true}
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  {invoiceHeaders.map((header, index) => (
                    <TableCell
                      key={index}
                      style={{
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {getDisplayedData().map((u, index) => (
                  <TableRow
                    key={u.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="right">{index + 1}</TableCell>
                    <TableCell align="right">{u.bookedDate}</TableCell>
                    <TableCell align="right">
                      {u.fromTime + " - " + u.toTime}
                    </TableCell>
                    <TableCell align="right">{u.slotLable}</TableCell>
                    <TableCell align="right">
                      {u.period.substr(0, u.period.indexOf(":"))}
                      {parseInt(u.period.substr(0, u.period.indexOf(":"))) > 1
                        ? " hrs"
                        : " hr"}
                    </TableCell>
                    <TableCell align="right"> {u.totalIncome} OMR</TableCell>
                    <TableCell align="center">
                      <button
                        style={{
                          backgroundColor: u.paidBySlot ? "red" : "green",
                        }}
                        onClick={(e) => {
                          if (!u.paidBySlot) {
                            updateReservation(u.slotId, u.id, {
                              paidBySlot: true,
                            });
                          } else {
                            updateReservation(u.slotId, u.id, {
                              paidBySlot: false,
                            });
                          }
                        }}
                      >
                        {u.paidBySlot ? "Make it Unpaid" : "Make it Paid"}
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div></div>
        )}{" "}
      </div>
    </div>
  );
}
