import * as React from "react";
import Temple from "../../../assets/images/slotLogo.svg";
import { useLogout } from "../../../hooks/useLogout";
import "../../../style/general/DashboardLayout.css";
import drawerItems from "../../../data/drawerItems";
import { Link, Outlet } from "react-router-dom";
import { useCollection } from "../../../hooks/useCollection";

export default function DashboardLayout(props) {
  const [isActive, setisActive] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState(null);
  const { isPending } = useCollection("users");
  const { logout } = useLogout();
  return (
    <div style={{ background: "rgb(230, 238, 230)" }}>
      <nav
        className="navbar has-shadow slotBackground"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <div className="navbar-item">
            <img
              alt="Slot"
              className="logo"
              src={Temple} />
          </div>

          <div
            onClick={() => {
              setisActive(!isActive);
            }}
            role="button"
            className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
            aria-label="menu"
            aria-expanded="false"
          >
            <span aria-hidden="true" ></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </div>

          <div
            id="navbarBasicExample"
            className={`navbar-menu is-tab is-hidden-tablet ssss ${isActive ? "is-active" : ""}`}
          >
            <div className="navbar-start">
              {drawerItems.map((e, index) =>
                e.users.includes(props.userType) ? (
                  <Link
                    className="link-of-item"
                    exact
                    to={e.route}
                    key={index}
                  >
                    <li>
                      <div
                        className="navbar-item navbar-heading"
                        onClick={() => {
                          setisActive(!isActive);
                        }}
                      >
                        {e.title}
                      </div>
                    </li>
                  </Link>
                ) : null
              )}

              {!isPending && (
                <li>
                  <a className="logout-style"
                    onClick={logout}>
                    Logout
                  </a>
                </li>
              )}
            </div>
          </div>
        </div>


      </nav>

      <section className="main-content columns is-fullheight">
        <aside className="menu column is-2 is-narrow-mobile is-fullheight section is-hidden-mobile main-aside ">
          <ul className="menu-list ul-list">
            {drawerItems.map((item, index) =>
              item.users.includes(props.userType) ? (
                <Link
                  className="a-heading"
                  key={index}
                  exact
                  to={item.route}
                  style={{
                    backgroundColor: index === activeIndex ? "#fff" : "",
                  }}
                  onClick={() => setActiveIndex(index)}
                >
                  <li>
                    <a>{item.title}</a>
                  </li>
                </Link>
              ) : null
            )}

            {!isPending && (
              <li>
                <a
                  style={{ color: "red", paddingLeft: '27px' }}
                  onClick={logout}>
                  Logout
                </a>
              </li>
            )}
            {isPending && (
              <li>
                <a disabled>
                  <li>
                    <a>Logging out...</a>
                  </li>
                </a>
              </li>
            )}
          </ul>
        </aside>
        <div className="column is-10">
          <Outlet />
        </div>
      </section>
    </div>
  );
}
