import React from "react";
import moment from "moment";
import { useFirestore } from "../../../hooks/useFirestore";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import {
  Paper,
  Typography,
  TableCell,
  TableRow,
  TableContainer,
  TableBody,
  Table,
  TableHead,
} from "@mui/material";

import { creditHeaders } from "../../../data/tableHeaders";
import { projectFunctions } from "../../../firebase/config";
import { useCollection } from "../../../hooks/useCollection";
import "../../../style/admin/UsersManagment.css";

const sendNotification = projectFunctions.httpsCallable("sendNotification");

export default function CustomersManagment({ userType }) {
  const { updateCredit } = useFirestore("users");
  const { documents: users } = useCollection(
    "users",
    ["type", "==", "customer"],
    ["timestamp", "desc"]
  );
  var supportNotificationCount = 0; // number of users who can receive notification
  var totalCredit = 0.0;

  users &&
    users.forEach(
      (u) =>
      (totalCredit = u.credit
        ? totalCredit + parseFloat(u.credit)
        : totalCredit + 0.0)
    );
  supportNotificationCount =
    users &&
    users.filter(
      (element) => element.deviceToken != null && element.deviceToken !== ""
    ).length;

  const onAddCreditClick = async (e, credit, id, deviceToken) => {
    e.preventDefault();
    swal("Add Credit : ", {
      content: "input",
    }).then((value) => {
      if (value <= 10) {
        const newCredit = credit
          ? parseFloat(credit) + parseFloat(value)
          : 0 + parseFloat(value);
        swal(`new credit: ${newCredit}`);
        updateCredit(id, newCredit);
        //Send notification to user
        sendNotification({
          deviceToken: deviceToken,
          title: "Slot",
          body: `${value} OMR has been added to your wallet`,
        });
      } else {
        swal("Value should be 5 or less");
      }
    });
  };

  return (
    <>
      <div className="mainDiv">
        <Typography
          variant="h4"
          style={{
            color: " #014D2F",
          }}
          className="page-title"
        >
          Users Managment
        </Typography>
        <br />
        <br />

        <div className="columns">
          <div className="column">
            <div className="card">
              <div className="card-content">
                <div className="content">
                  <center>
                    <div className="title"> {users && users.length}</div>
                    <div className="subtitle slot-green">Users</div>
                  </center>
                </div>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="card">
              <div className="card-content">
                <div className="content">
                  <center>
                    <div className="title">
                      {users && totalCredit.toFixed(3)} OMR
                    </div>
                    <div className="subtitle slot-green">Total Credit</div>
                  </center>
                </div>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="card">
              <div className="card-content">
                <div className="content">
                  <center>
                    <div className="title">
                      {users &&
                        (
                          (supportNotificationCount / users.length) *
                          100
                        ).toFixed(2)}
                    </div>
                    <div className="subtitle slot-green">
                      Notification Support
                    </div>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="">
          {!users && <p>Loading Credit List...</p>}
          {users && users.length === 0 && <p>No packages added yet!</p>}
          {users && users.length !== 0 ? (
            <TableContainer component={Paper}>
              <Table
                stickyHeader={true}
                sx={{ minWidth: 650 }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    {creditHeaders.map((header, index) => (
                      <TableCell
                        key={index}
                        style={{
                          fontWeight: "bold",
                          fontSize: "18px",
                        }}
                      >
                        {header.users.includes(userType) && header.title}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((u) => (
                    <TableRow
                      key={u.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="right">
                        {u.id.substring(0, 10)}...
                      </TableCell>

                      <TableCell align="right">
                        {u.timestamp &&
                          moment
                            .unix(u.timestamp["seconds"])
                            .format("DD/MM/YYYY hh:mm A")}
                      </TableCell>
                      <TableCell align="right">
                        <Link
                          to={`/dashboard/customer-reservations/${u.id}`}
                          className="slot-green"
                        >
                          {u.firstName}
                        </Link>
                      </TableCell>
                      <TableCell align="right">{u.phoneNumber}</TableCell>
                      {u.credit === null ? (
                        <TableCell align="right">0 OMR</TableCell>
                      ) : (
                        <TableCell align="right">{u.credit} OMR</TableCell>
                      )}

                      {u.deviceToken === null || u.deviceToken === "" ? (
                        <TableCell align="right" style={{ color: "red" }}>
                          Not Supported
                        </TableCell>
                      ) : (
                        <TableCell align="right" style={{ color: "green" }}>
                          Supported
                        </TableCell>
                      )}
                      {userType === "developer" && (
                        <TableCell align="center">
                          <button
                            className="btn"
                            onClick={(e) => {
                              onAddCreditClick(
                                e,
                                u.credit,
                                u.id,
                                u.deviceToken
                              );
                            }}
                          >
                            Add Credit
                          </button>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <div></div>
          )}{" "}
        </div>
      </div>
    </>
  );
}
