import React from "react";
import swal from "sweetalert";
import fetch from "node-fetch";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import EmailTwoToneIcon from "@mui/icons-material/EmailTwoTone";
import { useState } from "react";
import { useEffect } from "react";
import { useFirestore } from "../../../hooks/useFirestore";
import { useCollection } from "../../../hooks/useCollection";
import { useAuthContext } from "../../../hooks/useAuthContext";
import "../../../style/vendor/GiveAccess.css";

export default function GiveAccess() {
  const [staffEmail, setStaffEmail] = useState("");
  const [fetchResult, setFetchResult] = useState([]);
  const { user } = useAuthContext();
  const { documents } = useCollection("users");
  const { updateAccountId } = useFirestore("users");
  var userList = [];
  var documentId = "";
  let headers = new Headers();

  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  headers.append("Access-Control-Allow-Origin", "http://localhost:3000");
  headers.append("Access-Control-Allow-Credentials", "true");

  useEffect(() => {
    async function fetchData() {
      await fetch(
        `https://us-central1-slot-145a8.cloudfunctions.net/getUserGivenAccessAcounts?userID=${user.uid}`
      )
        .then((res) => res.json())
        .then(
          (result) => {
            setFetchResult(result.data);
          },

          (error) => {
            console.log(error);
          }
        );
    }
    fetchData();
  }, []);

  const users = documents
    ? documents.filter((userData) => {
      if (userData.email === staffEmail && userData.type === "staff") {
        if (userData.access != null) {
          documentId = userData.id;
          userData.access.forEach((element, index) => {
            userList.push(element);
          });
        }
      }
    })
    : null;

  const updateAccessUsers = (e, documentId, index) => {
    e.preventDefault();
    updateAccountId(documentId, index, userList, true);
    if (staffEmail == "" || staffEmail == null)
      swal({
        icon: "info",
        text: "Enter Staff Email !",
      });
    else
      swal({
        icon: "success",
        text: `The User ${staffEmail} Has Been Given Access To Your Account `,
      });
  };

  return (
    <div className="give-access-div">
      <form className="give-access">
        <h2 className="give-access-h2-tag">Give Access</h2>
        <label>
          {/* <EmailTwoToneIcon className="give_Access_icons" /> */}
          <input
            required
            placeholder="Staff Email"
            type="text"
            onChange={(e) => setStaffEmail(e.target.value)}
            value={staffEmail}
          />
        </label>

        <button
          className="slotButton"
          onClick={(e) => {
            updateAccessUsers(e, documentId, userList.index);
          }}
        >
          Give Access
        </button>
      </form>

      <TableContainer component={Paper}>
        <Table className="give-access-table" aria-label="simple table">
          <TableHead>
            <TableRow
              style={{
                fontWeight: "bold",
                fontFamily: "NexaDemo-Bold",
                fontSize: "18px",
              }}
            >
              <TableCell>Staff Name</TableCell>
              <TableCell align="right">Staff Email</TableCell>
              <TableCell align="right">Phone Number</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fetchResult.length === 0 && <p>No Access yet!</p>}
            {fetchResult &&
              fetchResult.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  hover={true}
                >
                  <TableCell component="th" scope="row">
                    {row.firstName + " " + row.lastName}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.email}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.phoneNumber}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}