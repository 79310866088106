import Login from "./components/pages/auth/Login";
import Signup from "./components/pages/auth/Signup";
import StaffSignup from "./components/pages/auth/StaffSignup";
import PrivacyPolicyOfSlot from "./components/PrivacyPolicyOfSlot";
import { useCookies } from "react-cookie";
import { useAuthContext } from "./hooks/useAuthContext";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "./style/general/App.css";
import "./style/general/index.css";
import { projectAuth } from "./firebase/config";
import React, { useState } from "react";
import { DASHBOARD_ROUTES } from "./data/routes";
import NewLanding from "./components/pages/general/NewLanding";
import LandingPage2 from "./components/pages/general/LandingPage2";

import SlotTermsConditions from "./components/SlotTermsConditions";
import DashboardLayout from "./components/snippets/nav-sidebars/DashbordLayout";

function App() {
  const { authIsReady, user } = useAuthContext();
  const [cookies] = useCookies(["status"]);
  const [userType, setUserType] = useState(null);
  projectAuth.onAuthStateChanged((user) => {
    if (user) {
      user.getIdTokenResult().then((idTokenResult) => {
        user.type = idTokenResult.claims.type;
        setUserType(user.type);
      });
    }
  });

  return (
    <div className="App">
      {authIsReady && (
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                !user ? (
                  <NewLanding />
                ) : (
                  <Navigate to="/dashboard/user-profile" />
                )
              }
            />

            <Route
              path="/login"
              element={
                user && cookies.status === "active" ? (
                  <Navigate to="/" />
                ) : (
                  <Login />
                )
              }
            />

            <Route
              path="/signup"
              element={user && user.name ? <Navigate to="/" /> : <Signup />}
            />

            <Route
              path="/staff-signup"
              element={user ? <Navigate to="/" /> : <StaffSignup />}
            />
            <Route path="privacy-policy" element={<PrivacyPolicyOfSlot />} />
            <Route path="terms-conditions" element={<SlotTermsConditions />} />

            <Route
              path="/dashboard"
              element={<DashboardLayout userType={userType} />}
            >
              {DASHBOARD_ROUTES.map((route, index) => {
                if (
                  !user ||
                  (route.users.length > 0 && !route.users.includes(userType))
                )
                  return (
                    <Route
                      key={index}
                      path={route.path}
                      element={<Navigate to="/" />}
                    />
                  );

                var Component = React.cloneElement(route.component, {
                  userType: userType,
                });

                return (
                  <Route key={index} path={route.path} element={Component} />
                );
              })}
            </Route>
          </Routes>
        </BrowserRouter>
      )}
    </div>
  );
}
export default App;
