import React from 'react'
import moment from "moment";
import Divider from '@mui/material/Divider';
import { Link } from 'react-router-dom'
import { useParams } from "react-router-dom"



export default function VendorOrderBill(props) {
  const data = useParams()
  var stringTimestamp = data.timestamp;
  var _timeStampseconds =
    parseInt(stringTimestamp.substring(18, 28));

  var GetDate = function (params) {
    return moment(params * 1000).format("D/M/YYYY   h:mm a");
  };

  return (

    <div> <h2 style={{ fontFamily: 'NexaDemo-Bold', fontSize: '30px', color: " #014D2F" }} className="page-title">Order Bill</h2>
      <div className="project-list">
        <Link to={`/BillProfile/` + data.bookedBy}>
          <h3 style={{ textAlign: 'center', fontSize: '25px' }}>Total Income: {data.totalIncome} </h3>
          <Divider />
          <h3 style={{ fontSize: '23px' }}>Period: {data.period} Hour/s</h3>
          &nbsp;
          <h3 style={{ fontSize: '23px' }}>Date and Time signature  :{GetDate(_timeStampseconds)}
          </h3>
        </Link>
      </div>
    </div>
  )
}