import React, { useEffect } from "react";
import { useCollectionGroup } from "../../../hooks/useCollectionGroup";
import "../../../style/vendor/OrdersList.css";
import ReservationCard from "../../snippets/ReservationCard";
import ReservationsStatistics from "../../snippets/statistics/ReservationsStatistics";
import { useCollection } from "../../../hooks/useCollection";
export default function Reservations({ userType }) {
  const { documents: reservations } = useCollectionGroup("reservations", null, [
    "timestamp",
    "desc",
  ]);

  const { documents: branches } = useCollection("branches");



  return (
    <div className="mainDiv">
      <ReservationsStatistics />
      <div className="order-list">
        {reservations && reservations.length === 0 && (
          <p>No Reservations yet!</p>
        )}
        {reservations &&
          reservations.map((reservation, index) => (
            <ReservationCard
              reservation={reservation}
              userType={userType}
              key={index}
              branches={branches}
            />
          ))}
      </div>
    </div>
  );
}
