import { useState, useEffect } from "react";
import { projectFirestore } from "../firebase/config";
import { useCookies } from "react-cookie";
import { useGlobalContext } from "../hooks/useGlobalContext";

export const useAddBranch = () => {
  const [isCancelled, setIsCancelled] = useState(false);
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [cookies] = useCookies(["branchId"]);
  const { dispatch } = useGlobalContext();

  const addBranch = async (
    createdBy,
    title,
    description,
    isWifiAvailbe,
    isFreeParkingAvailbe,
    isFoodDrinkAvailbe,
    isToiletsAvailbe,
    lng,
    lat,
    placelocation,
    openTime,
    closeTime,
    image,
    status,
    timestamp
  ) => {
    setError(null);
    setIsPending(true);
    const brandName = cookies.brandName;
    try {
      await projectFirestore
        .collection("branches")
        .add({
          createdBy,
          brandName,
          title,
          description,
          isWifiAvailbe,
          isFreeParkingAvailbe,
          isFoodDrinkAvailbe,
          isToiletsAvailbe,
          lng,
          lat,
          placelocation,
          openTime,
          closeTime,
          image,
          status,
          timestamp,
        })
        .then((doc) => {
          dispatch({ type: "SET_BRANCH_IDS", payload: [doc.id] });
        })
        .catch((e) => {
          console.log("error from add branch:", e)
        });

      if (!isCancelled) {
        setIsPending(false);
        setError(null);
      }
    } catch (err) {
      if (!isCancelled) {
        setError(err.message);
        setIsPending(false);
      }
    }
  };

  useEffect(() => {
    return () => setIsCancelled(true);
  }, []);

  return { addBranch, error, isPending };
};
