import swal from "sweetalert";
import Navbar from "../../snippets/nav-sidebars/Navbar";
import { useState } from "react";
import React, { useEffect } from "react";
import { useLogin } from "../../../hooks/useLogin";
import { useNavigate } from "react-router-dom";
import { useCollection } from "../../../hooks/useCollection";
import { useAuthContext } from "../../../hooks/useAuthContext";
import "../../../style/auth/Login.css";
import { Link } from "react-router-dom";
export default function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login, error, isPending } = useLogin();
  const { documents } = useCollection("users");
  const { user } = useAuthContext();

  useEffect(() => {
    documents &&
      documents.map((userData) => {
        if (userData.id === user.uid) {
          if (userData.status === "pending") {
            swal({
              text: "Your Account is Bening Reviwed By Slot Managment",
            });
          } else {
            navigate("/app");
          }
        }
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    login(email, password);
  };



  return (
    <>
      <div>
        <Navbar />

        <section className="hero-body is-fullheight">

          <div className="hero-body">
            <div className="container">
              <div className="columns is-centered">
                <div className="column is-5-tablet is-4-desktop is-5-widescreen">
                  <form className="login-auth-form">
                    <h2
                      className="login-auth-form-title"
                      style={{
                        fontSize: 25,
                        color: "#014D2F",
                        fontWeight: "bolder",
                      }}
                    >
                      Login
                    </h2>
                    <div className="field">
                      <div className="control">
                        <div className="login-row">
                          <input
                            className="rowInput"
                            placeholder="Enter your Email"
                            required
                            type="email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div className="field">
                      <div className="control">
                        <div className="login-row">
                          <input
                            className="rowInput"
                            required
                            placeholder="Enter your Password"
                            type="password"
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="field">
                      <center>
                        {!isPending && (
                          <button
                            className="loginButton"
                            onClick={handleSubmit}
                          // style={{ color: "white" }}
                          >
                            Login
                          </button>
                        )}
                        {isPending && (
                          <button
                            // style={{ fontSize: "20px", textAlign: "center" }}
                            className="loginButton"
                            disabled
                          >
                            loading
                          </button>
                        )}
                        {error && <div className="error">{error}</div>}
                        <p className="link">
                          Don't have an account?
                          <Link className="loginLink" to="/signup">Sign Up</Link>
                        </p>
                      </center>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
