import { createContext, useReducer } from "react";

export const GlobalContext = createContext();

export const globlReducer = (state, action) => {
  switch (action.type) {
    case "isInLoop":
      return { ...state, isInLoop: action.payload };
    case "CHANGE_COUNT":
      return { ...state, count: action.payload };
    case "SET_BRANCH_IDS":
      return { ...state, branchIds: action.payload };
    case "SET_ACCOUNT_IDS":
      return { ...state, accountId: action.payload };
    // case 'LOGOUT':
    //   return { ...state, user: null }
    // case 'AUTH_IS_READY':
    //   return { user: action.payload, authIsReady: true }
    // case 'SAVE_TYPE':
    //   return { ...state, type: action.payload }
    // case 'SAVE_LOCATION':
    //   return { ...state, locationPlace: action.payload }
    default:
      return state;
  }
};

export const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(globlReducer, {
    isInLoop: true,
    branchIds: [],
    accountId: "",
    // authIsReady: false,
    // type: null,
    // locationPlace: {
    //   lat: null,
    //   lng: null,
    // }
  });

  // useEffect(() => {
  //   const unsub = projectAuth.onAuthStateChanged(user => {
  //     dispatch({ type: 'AUTH_IS_READY', payload: user })
  //     unsub()
  //   })
  // }, [])

  // console.log("Global Context:", state);

  return (
    <GlobalContext.Provider value={{ ...state, dispatch }}>
      {children}
    </GlobalContext.Provider>
  );
};
