import * as React from "react";
import moment from "moment";
import swal from "sweetalert";
import Global from "../../../data/global";
import TimePicker from "../general/TimePicker";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { timestamp } from "../../../firebase/config";
import { projectStorage } from "../../../firebase/config";
import { useAddBranch } from "../../../hooks/useAddBranch";
import { useAuthContext } from "../../../hooks/useAuthContext";
import "../../../style/vendor/AddBranch.css";

export default function Branch() {
  const [title, setTitle] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState("");
  const [description, setDescription] = useState("");
  const [isWifiAvailbe, setIsWifiAvailbe] = React.useState(true);
  const [isFreeParkingAvailbe, setIsFreeParkingAvailbe] = React.useState(true);
  const [isFoodDrinkAvailbe, setIsFoodDrinkAvailbe] = React.useState(true);
  const [isToiletsAvailbe, setIsToiletsAvailbe] = React.useState(true);
  const [choosesdImage, setChoosesdImage] = useState();
  const { user } = useAuthContext();
  const [createdBy] = React.useState(user.uid);
  const { addBranch, isPending, error } = useAddBranch();
  const formatedStartTime = moment(startTime).format("hh:mm A");
  const formatedEndTime = moment(endTime).format("hh:mm A");
  const navigate = useNavigate();
  Global.placeOpenTime = formatedStartTime;
  Global.placeCloseTime = formatedEndTime;

  const handleSubmit = (e) => {
    if (choosesdImage === null) {
      swal({
        icon: "info",
        text: "Select Image!",
      });
    } else {
      e.preventDefault();
      upload();

      swal({
        icon: "success",
        text: "Branch Added",
      });
      navigate("/dashboard/add-slot/:pageType");
    }
  };

  const handleChangeWifi = (eventWifi) => {
    setIsWifiAvailbe(eventWifi.target.value);
  };
  const handleChangeParking = (eventParking) => {
    setIsFreeParkingAvailbe(eventParking.target.value);
  };
  const handleChangeFoodDrink = (eventFoodDrink) => {
    setIsFoodDrinkAvailbe(eventFoodDrink.target.value);
  };
  const handleChangeToilets = (eventToilets) => {
    setIsToiletsAvailbe(eventToilets.target.value);
  };
  const removeChoosesdImage = () => {
    setChoosesdImage();
  };
  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setChoosesdImage(e.target.files[0]);
    }
  };

  const upload = () => {
    const uniqeValue = new Date().getTime();
    const uniqeImageName = uniqeValue.toString() + choosesdImage.name;

    if (choosesdImage === null) return;
    var uploadTask = projectStorage
      .ref(`/images/${uniqeImageName}`)
      .put(choosesdImage)
      .on("state_changed", () => {
        projectStorage
          .ref("images")
          .child(uniqeImageName)
          .getDownloadURL()
          .then((url) => {
            addBranch(
              createdBy,
              title,
              description,
              isWifiAvailbe,
              isFreeParkingAvailbe,
              isFoodDrinkAvailbe,
              isToiletsAvailbe,
              Global.lng,
              Global.lat,
              Global.placeLocationLable,
              formatedStartTime,
              formatedEndTime,
              url,
              "active",
              timestamp.now()
            );
          });
      });
  };

  return (
    <form onSubmit={handleSubmit} className="branch-auth-form">
      <h2 className="branch-auth-form-h2-tag">
        Add Branch
      </h2>

      <div>
        <label className="branch-auth-form-lable">
          <span className="branch-auth-form-span">
            Area
          </span>
        </label>
        <input
          className="branch-auth-form-input"
          required
          type="text"
          onChange={(e) => setTitle(e.target.value)}
          value={title}
        />
      </div>

      <div>
        <label className="branch-auth-form-lable">
          <span className="branch-auth-form-span" style={{ marginTop: '10px' }}>
            Opening And Closing Time
          </span>
        </label>
        <div className='branch-auth-form-lable' style={{ margin: '0px' }}>
          <div style={{ marginTop: '15px', display: 'flex' }}>
            <p className="branch-auth-form-p-tag" style={{ marginLeft: '-10px', marginRight: '10px' }}>
              From &nbsp;
            </p>
            <TimePicker
              value={startTime}
              onChange={setStartTime}
              style={{ width: "200px", marginLeft: '-10px' }}
            />

            <p className="branch-auth-form-p-tag" style={{ marginLeft: '10px', marginRight: '10px' }}>
              To&nbsp;{" "}
            </p>
            <TimePicker
              value={endTime}
              onChange={setEndTime}
              style={{ width: "200px", marginLeft: '-10px' }}
            />
          </div>
        </div>
      </div>

      <div>
        <lable className='branch-auth-form-lable'>
          <span
            className="branch-auth-form-span"
            for="exampleFormControlTextarea1"
            style={{ marginTop: '15px' }}>
            Description:
          </span>
        </lable>
        <textarea
          required
          className="form-control-textBox"
          id="exampleFormControlTextarea1"
          rows="3"
          onChange={(e) => setDescription(e.target.value)}
          value={description}
        ></textarea>
      </div>

      <label style={{ margin: 0 }} >
        <span
          className="branch-auth-form-span"
          id="demo-controlled-radio-buttons-group"
          style={{ marginTop: '15px' }}
        >
          Wi-Fi Availability
        </span>
      </label>
      <RadioGroup
        row
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={isWifiAvailbe}
        onChange={handleChangeWifi}
      >
        <FormControlLabel
          value="true"
          control={<Radio color="success" />}
          label="Available"
        />
        <FormControlLabel
          value="false"
          control={<Radio color="success" />}
          label="Not Available"
        />
      </RadioGroup>


      <label className="branch-auth-form-lable">
        <span className="branch-auth-form-span" id="demo-controlled-radio-buttons-group">
          Parking Accessibility
        </span>
      </label>
      <RadioGroup
        row
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={isFreeParkingAvailbe}
        onChange={handleChangeParking}
      >
        <FormControlLabel
          value="true"
          control={<Radio color="success" />}
          label="Accessible"
        />
        <FormControlLabel
          value="false"
          control={<Radio color="success" />}
          label="Not Accessible"
        />
      </RadioGroup>


      <label style={{ margin: 0 }}>
        <span className="branch-auth-form-span" id="demo-controlled-radio-buttons-group">
          Food and Beverages Availability
        </span>
      </label>
      <RadioGroup
        row
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={isFoodDrinkAvailbe}
        onChange={handleChangeFoodDrink}
      >
        <FormControlLabel
          value="true"
          control={<Radio color="success" />}
          label="Available"
        />
        <FormControlLabel
          value="false"
          control={<Radio color="success" />}
          label="Not Available"
        />
      </RadioGroup>


      <label style={{ margin: 0 }}>
        <span className="branch-auth-form-span" id="demo-controlled-radio-buttons-group">
          Restrooms Availability
        </span>
      </label>
      <RadioGroup
        row
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={isToiletsAvailbe}
        onChange={handleChangeToilets}
      >
        <FormControlLabel
          value="true"
          control={<Radio color="success" />}
          label="Available"
        />
        <FormControlLabel
          value="false"
          control={<Radio color="success" />}
          label="Not Available"
        />
      </RadioGroup>


      <label className="branch-auth-form-lable">
        <span className="branch-auth-form-span" style={{ marginBottom: '15px' }}>
          Branch Image
        </span>
      </label>
      <input accept=".png , .jpg" type="file" onChange={imageChange}
        style={{ width: "250px", marginLeft: '-10px', marginBottom: '35px' }} />
      {choosesdImage && (
        <div>
          <img
            src={URL.createObjectURL(choosesdImage)}
            style={{ width: "350px" }}
          />
          <button
            onClick={removeChoosesdImage}
            className="slotButton"
            style={{ width: 200, marginLeft: '-10px', marginTop: '35px' }}
          >
            Remove This Image
          </button>
        </div>
      )}


      {!isPending && (
        <button
          className="slotButton"
          style={{ width: 250, marginLeft: 200, marginRight: 150, marginTop: '30px' }}
          type="submit"
        >
          Add Branch
        </button>
      )}
      {isPending && (
        <button
          className="slotButton"
          style={{ width: 250, marginLeft: 125, marginRight: 125 }}
          disabled
        >
          loading
        </button>
      )}
      {error && <div className="error">{error}</div>}
    </form>
  );
}