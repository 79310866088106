import "../../../style/admin/VendorReservation.css";

import GeneralStatisctics from "../../snippets/statistics/GeneralStatistics";
import ReservationsStatistics from "../../snippets/statistics/ReservationsStatistics";

export default function Dashboard() {
  return (
    <>
      <GeneralStatisctics />
      <ReservationsStatistics />
      {/* <VendrosStatistics /> */}
    </>
  );
}
