import React from 'react'
import moment from "moment";
import swal from "sweetalert";
import TimePicker from ".././general/TimePicker";
import EditIcon from '@mui/icons-material/Edit';
import IconButton from "@mui/material/IconButton";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDocument } from "../../../hooks/useDocument";
import { useFirestore } from "../../../hooks/useFirestore";
import "../../../style/vendor/UpdateBranch.css";

export default function UpdateBranch() {
    const { id } = useParams();
    const { document, error } = useDocument("branches", id);
    const [isBrandVisible, setIsBrandVisible] = useState(false);
    const [isButtonBrandVisible, setisButtonBrandVisible] = useState(true);

    const [isBranchVisible, setIsBranchVisible] = useState(false);
    const [isButtonBranchVisible, setIsButtonBranchVisible] = useState(true);

    const [isTimeVisible, setIsTimeVisible] = useState(false);
    const [isButtonTimeVisible, setIsButtonTimeVisible] = useState(true);

    const [isLinkVisible, setIsLinkVisible] = useState(false);
    const [isButtonLinkVisible, setIsButtonLinkVisible] = useState(true);

    const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);
    const [isButtonDescriptionVisible, setIsButtonDescriptionVisible] = useState(true);


    const { updateBranch } = useFirestore("branches")
    const navigate = useNavigate();
    var updateBranchObject = {};

    const handleEditBrandClick = () => {

        setIsBrandVisible(true);
        setisButtonBrandVisible(false);
    };

    const handleEditBranchClick = () => {

        setIsBranchVisible(true);
        setIsButtonBranchVisible(false);
    };

    const handleEditTimeClick = () => {

        setIsTimeVisible(true);
        setIsButtonTimeVisible(false);
    };

    const handleEditLinkClick = () => {

        setIsLinkVisible(true);
        setIsButtonLinkVisible(false);
    };

    const handleEditDescriptionClick = () => {

        setIsDescriptionVisible(true);
        setIsButtonDescriptionVisible(false);
    };



    const onClickUpdateBranch = (e, branchId, updateBranchObject) => {
        e.preventDefault();
        updateBranch(branchId, updateBranchObject)
            .then(() => {
                swal({
                    icon: "success",
                    text: "Branch successfully updated!",
                });
                navigate("/dashboard/branch-list");
            })
            .catch((error) => {
                console.error('Error updating document: ', error);
            });
    }

    return (
        <form
            className="update-branch-form">
            <h2 className='update-branch-h2-tag'>Edit Branch</h2>
            <div className='update-branch-div'>
                <span className='update-branch-span'>  Brand Name: </span>
                <label>
                    {document && document.brandName}
                    <IconButton
                        title="Edit"
                        style={{
                            width: "60px",
                            color: " #014D2F",
                            marginLeft: "5px",
                            marginTop: "-10px",
                            display: isButtonBrandVisible ? "inline-block" : "none"
                        }}
                        onClick={handleEditBrandClick}
                    >
                        <EditIcon />
                    </IconButton>
                </label>
            </div>
            {isBrandVisible &&
                <input
                    className='update-branch-input'
                    required
                    placeholder="Brand Name"
                    type="text"
                    onChange={(e) => {
                        updateBranchObject["brandName"] = e.target.value
                    }}
                />
            }

            <div className='update-branch-div'>
                <span className='update-branch-span'>  Branch Name: </span>
                <label>
                    {document && document.title}
                    <IconButton
                        title="Edit"
                        style={{
                            width: "60px",
                            color: " #014D2F",
                            marginLeft: "5px",
                            marginTop: "-10px",
                            display: isButtonBranchVisible ? "inline-block" : "none"
                        }}
                        onClick={handleEditBranchClick}
                    >
                        <EditIcon />
                    </IconButton>
                </label>
            </div>

            {isBranchVisible &&
                <input
                    className='update-branch-input'
                    required
                    placeholder="Branch Name"
                    type="text"
                    onChange={(e) => {
                        updateBranchObject["title"] = e.target.value
                    }}
                />
            }

            <div className='update-branch-div'>
                <span className='update-branch-span'>Time : </span>
                <label>
                    {document && document.openTime} - {document && document.closeTime}
                    <IconButton
                        title="Edit"
                        style={{
                            width: "60px",
                            color: " #014D2F",
                            marginLeft: "5px",
                            marginTop: "-10px",
                            display: isButtonTimeVisible ? "inline-block" : "none"
                        }}
                        onClick={handleEditTimeClick}
                    >
                        <EditIcon />
                    </IconButton>
                </label>
            </div>

            {isTimeVisible &&
                <div>
                    <div>
                        <p className='update-branch-p-tag'>From: </p>
                        <TimePicker
                            className='update-slot-input'
                            placeholder={document.openTime}
                            onChange={(e) => {
                                updateBranchObject['openTime'] = moment(e).format("hh:mm A")
                            }} />
                    </div>
                    <div>
                        <p className='update-branch-p-tag'>To: </p>
                        <TimePicker
                            className='update-slot-input'
                            placeholder={document.closeTime}
                            onChange={(e) => {
                                updateBranchObject['closeTime'] = moment(e).format("hh:mm A")
                            }} />
                    </div>
                </div>
            }

            <div className='update-branch-div'>
                <span className='update-branch-span'>Link: </span>
                <label>
                    {document && document.link}
                    <IconButton
                        title="Edit"
                        style={{
                            width: "60px",
                            color: " #014D2F",
                            marginLeft: "5px",
                            marginTop: "-10px",
                            display: isButtonLinkVisible ? "inline-block" : "none"
                        }}
                        onClick={handleEditLinkClick}
                    >
                        <EditIcon />
                    </IconButton>
                </label>
            </div>

            {isLinkVisible &&
                <input
                    className='update-branch-input'
                    type="url"
                    id="url-type-styled-input"
                    onChange={(e) => {
                        updateBranchObject["link"] = e.target.value
                    }}
                />
            }


            <div className='update-branch-div'>
                <span className='update-branch-span'>  Description: </span>
                <label>
                    {document && document.description}
                    <IconButton
                        title="Edit"
                        style={{
                            width: "60px",
                            color: " #014D2F",
                            marginLeft: "5px",
                            marginTop: "-10px",
                            display: isButtonDescriptionVisible ? "inline-block" : "none"
                        }}
                        onClick={handleEditDescriptionClick}
                    >
                        <EditIcon />
                    </IconButton>
                </label>
            </div>

            {isDescriptionVisible &&
                <div>
                    <textarea
                        className="update-branch-textarea-tag"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        onChange={(e) => {
                            updateBranchObject["description"] = e.target.value
                        }}
                    ></textarea></div>

            }


            <button
                className="slotButton"
                style={{ width: 250, marginLeft: 300, marginRight: 125, marginTop: 15 }}

                onClick={(e) => {
                    onClickUpdateBranch(e, id, updateBranchObject);
                }}
            >
                Update
            </button>
        </form >
    )
}