import React from 'react';
import Navbar from '../../src/components/snippets/nav-sidebars/Navbar'
import "../style/general/SlotTermsConditions.css";
export default function SlotTermsConditions() {
    return (
        <div >
            <Navbar />
            <div className='terms-conditions-div' >
                <h2 className='min-header-style'>Slot Terms & Conditions</h2>
            </div>

            <div className='definitions-div'>
                <h1 className='headers' >1. Definitions:</h1>

                <p> <strong>- “Affiliate”</strong></p>
                <p>
                    means in relation to SLOT any entity that from time to time directly or indirectly controls, is
                    controlled by, or is under common control with Slot FZCO;
                </p>

                <p><strong> - “Apps”</strong> </p>
                <p>
                    means the Slot applications available on the Google Play Store or the Apple App Store;
                </p>

                <p><strong>- “End-user”</strong></p>
                <p>
                    means an individual using any of our Apps;
                </p>

                <p> <strong>- “Slot”</strong> </p>

                <p>
                    means www.slotyourspace.com a free zone company registered in United Arab Emirates under
                    license number 25024, with registered office address in DSO-DDP-A5-D-OFF-1006, Dubai Digital Park -
                    Office A5-Dtec, Dubai Silicon Oasis, Dubai, UAE;
                </p>

                <p><strong>-  “Partner”</strong></p>
                <p>
                    means a business owner who provide services to Slot like and not limited to payment gateway,
                    analytics services, hosting services, marketing services, analytical services, etc.
                </p>

                <p><strong>- “Vendor”</strong></p>
                <p>
                    means a business owner/space owner who uses the Slot website and/or App to
                    offer/confirm/cancel spaces/slots bookings to End-user;
                </p>

                <p><strong>- “Privacy Policy”</strong></p>
                <p>
                    means Privacy Policy which may be amended from time-to-time and available in our
                    Website www.slotyourspace.com ;
                </p>
                <p><strong>- “Terms & Conditions”</strong></p>
                <p>
                    means this Terms & Conditions for using Slot App or Websites which may be
                    amended from time-to-time and available in our Website www.slotyourspace.com ;
                </p>

                <p><strong>- “Services”</strong></p>
                <p>
                    means any of the services we provide to Partners or End-user through our Apps and Websites;
                    and;
                </p>

                <p><strong> - “Members”</strong></p>
                <p>
                    Are staff or shareholder of Slot FZCO or it’s local dealer.
                </p>

                <p><strong> - “Packages”</strong></p>
                <p>
                    Is the Vendor offers for each of his/her listed slots.
                </p>

                <p><strong>- “Convenience Fee”</strong>
                </p>
                <p>
                    is the amount added to Packaged amount as service fee to make slot
                    reservation/booking.
                </p>

                <p><strong> - “Websites”</strong> </p>
                <p>
                    means www.slotyourspace.com, including all subdirectories, subdomains, and Mobile sites
                    thereof.
                </p>


                <div>
                    <h2 className='headers'>2. Declaration</h2>
                </div>
                <p style={{
                    fontSize: '20px',
                    marginLeft: '15px',
                    justifyContent: 'center',
                    marginBottom: '35px'
                }}>
                    By downloading/ installing our Apps or accessing our Websites or using the Services, you agree to this Terms &
                    Conditions If we make any significant changes to this Terms & Conditions, there will be no notification either
                    declaration in regards to that; therefore, it is recommended by time to time Vendors, Partners, End-users and

                    visitors have a look through our Terms & Conditions.<br></br><br></br>
                    If you have any questions about our Terms & Conditions, don’t hesitate to get in touch with us. Please feel free
                    to reach out to us at <a href="mailto:CC@slotyourspace.com" style={{ color: 'blue' }}>CC@slotyourspace.com</a>
                </p>

                <div>
                    <h2 className='headers'>
                        3. Slot Accounts
                    </h2>
                </div>

                <p>
                    <strong>1. Accurate Information.</strong> You represent and warrant that all information supplied by you on the Slot
                    Apps/Websites (including information provided by you to create an account for use of the Slot
                    Apps/Websites(a "Slot Account")) or in connection with your use of the Slot Services istrue, accurate,
                    current and complete.

                </p>

                <p>
                    <strong>2. Security of Slot Accounts.</strong> Any Slot Account must be kept secure and you agree that you will not
                    share or disclose your Slot Account credentials with anyone. No Members of the Slot FZCO will be
                    liable for any loss or damage arising from your failure to safeguarding your Slot Account, use a strong
                    password or limit its use to your Slot Account. You may be allowed to browse some of the Slot
                    Apps/Websites without creating a Slot Account.

                </p>
                <p>
                    <strong>3. Discretionary Access to Slot Accounts.</strong> Slot reserves the right to deny your request for a Slot Account
                    (including usernames) and to disable or terminate access to any Slot Account (including usernames)
                    issued to you at any time in Slot’s sole discretion. If Slot disables access to a Slot Account issued to
                    you, you will be prevented from accessing the Slot Apps/Websites or the Slot Services, your Slot
                    Account details, Orders or other information that is associated with your Slot Account.

                </p>
                <p>
                    <strong>4. Suspension, Term, Termination and Deletion of Slot Accounts.</strong> We may suspend or terminate your
                    ability to access the Slot Apps/Websites, or cease providing you with all or part of the Slot Services
                    at any time for any or no reason, including, if we believe: (i) you may have violated these Terms &
                    Conditions , (ii) you may create risk or possible legal exposure for us; (iii) prolonged inactivity; or (iv)
                    our provision of the Slot Services to you is no longer commercially viable. Suspension or termination
                    may also include removal of some or all of the materials uploaded by you. We may make reasonable
                    efforts to notify you by the email address or mobile number associated with your account, through
                    the Slot Apps/Websites or the next time you attempt to access your account, depending on the
                    circumstances. You acknowledge and agree that all suspensions or terminations may be made by

                    Slot in its sole discretion and that no Members of the Slot FZCO will be liable to you or any third-
                    party for any suspension or termination of your access or for the removal of any of the materials

                    uploaded by you to the Slot Apps/Websites. Any suspension or termination of this Agreement by Slot
                    will be in addition to any and all other rights and remedies that Slot may have.

                </p>
                <p>
                    <strong>5. Account Deletion and Termination.</strong> You may request the deletion of your account by requesting (by
                    email or through any then-available interfaces) that your Slot Account be deleted, ceasing use of the
                    Slot Apps/Websites, the Slot Services and uninstalling and removing all local software components
                    thereof, if any. If you request deletion of your Slot Customer Account, we may disable your Slot
                    Customer Account but keep the disabled Slot Customer Account for fraud prevention or other lawful
                    purposes. If we have received all outstanding payment from you, you may request termination these
                    Terms & Conditions at any time by requesting by email (or any then-available interface on the Slot
                    Apps/Websites) that your Slot Account be deleted. We may terminate these Terms & Conditions at
                    any time by giving notice to you, at our discretion, by email at your current email address on file with
                    us or through the Slot Apps/Websites.
                </p>


                <div>
                    <h2 className='headers'>
                        4 Licensed Access to The Slot Apps/Websites and Permitted Use
                    </h2>
                </div>
                <p>
                    1. Subject to your compliance with these Terms & Conditions, Slot hereby grants you a personal,
                    revocable, non-exclusive and non-transferable license to use the Slot Apps/Websites in accordance
                    with these Terms & Conditions in the following manner depending on the type of User that you are:
                </p>
                <ul >
                    o for End-users, you may use the Slot Apps/Websites to view Packages provided by Vendors, create
                    an account, place orders and pay for Services (each an "Order"). You may also use feedback
                    features of the Slot Apps/Websites to rate, post comments, rating and reviews on Packages and
                    Vendors.
                </ul>
                <ul>
                    o for Vendors, you may create an account and use the Slot Apps/Websites to manage Packages
                    and fulfilment of Orders; and
                </ul>
                <ul>
                    o constitute the "Permitted Use" as applicable for a specific type of User.
                </ul>

                <p>
                    <strong>2. Reservation of Rights.</strong> Slot retains the right, at its sole discretion, to deny access to anyone to the
                    Slot Apps/Websites or the Slot Services, at any time and for any reason (or no reason at all), including,
                    but not limited to, for violation of these Terms & Conditions or use other than the Permitted Use.
                    You will cease and desist from any such access or use of the Slot Apps/Websites or Slot Services
                    immediately upon request by Slot.

                </p>

                <p>
                    <strong>3. Geographic Application of the Slot Apps/Websites.</strong> The Slot Apps/Websites and/or specific services
                    may not be available at all or any times and in all or any jurisdictions. Furthermore, nothing on the
                    Slot Apps/Websites constitutes an offer or solicitation to buy or sell any package or Service to anyone
                    in any jurisdiction in which such an offer or solicitation may be prohibited by law.

                </p>

                <p>
                    <strong>4. Changes to the Slot Apps/Websites.</strong> Slot reserves the right to change or allow a third party to change
                    any information, material or content (including, but not limited to, price, features, availability of
                    Packages, Vendors, posting and rating/reviews of Vendors) contained on or provided through the
                    Slot Apps/Websites (the "Content") at any time, and from time to time, without notice. For greater
                    certainty, the Content include the User Content (as defined below).

                </p>

                <div>
                    <h2 className='headers'>
                        5 No Unlawful or Prohibited Use
                    </h2>
                </div>
                <p>
                    You may not, without Slot's prior written permission, use the Slot Apps/Websites, the Slot Services or any
                    Content (including other User’s User Content) for purposes other than the applicable Permitted Use. Without
                    limiting the generality of the foregoing, you will not, and will not permit anyone else to, or attempt to use the
                    Slot Apps/Websites and/or the Content to:
                </p>
                <ul>
                    1. "frame", "mirror" or otherwise incorporate the Slot Apps/Websites, the Slot Services or the Content
                    or any part thereof on any commercial or non-commercial website;
                </ul>
                <ul>
                    2. access, monitor or copy any part of the Slot Apps/Websites, the Slot Services or the Content using
                    any robot, spider, scraper or other automated means or any manual process for any purpose without
                    Slot’s express written permission;
                </ul>
                <ul>
                    3. violate any laws;
                </ul>
                <ul>
                    4. violate the restrictions in any robot exclusion headers on the Content or the Slot Apps/Websites or
                    bypass or circumvent other measures employed to prevent or limit access to the Slot Services or the
                    Slot Apps/Websites;
                </ul>
                <ul>
                    5. remove (or permit anyone else to remove) any watermarks, labels or other legal or proprietary
                    notices included in the Slot Apps/Websites or the Content;
                </ul>
                <ul>
                    6. modify or attempt to modify (or permit anyone else to modify or attempt to modify) the Slot
                    Apps/Websites, or any Vendor menu, including any modification for the purpose of disguising or
                    changing any indications of the ownership or source of the Slot Apps/Websites;
                </ul>
                <ul>
                    7. forge headers or otherwise manipulate identifiers in order to disguise the origin of any material
                    transmitted to or through the Slot Apps/Websites or impersonate another person or organization or
                    misrepresent their affiliation with a person or entity;
                </ul>
                <ul>
                    8. attempt to, assist, authorize or encourage others to circumvent, disable or defeat any of the security
                    features or components, such as digital rights management software or encryption, that protect the
                    Slot Apps/Websites or the Slot Services;
                </ul>
                <ul>
                    9. copy, reproduce, modify, translate, distribute, transfer, sell, publish, broadcast, perform, transmit,
                    license or circulate in any form any part of the Slot Apps/Websites or the Content;
                </ul>
                <ul>
                    10. license, sell, rent, lease, transfer, assign, distribute, host, or otherwise commercially exploit the Slot
                    Apps/Website;
                </ul>
                <ul>
                    11. create derivative works based on the Slot Apps/Websites, the Slot Services or the Content, in whole
                    or in part, or decompile, disassemble, reverse engineer or other exploit any part of the Slot
                    Apps/Websites, the Slot Services or the Content;
                </ul>
                <ul>
                    12. use of access the Slot Apps/Websites in a manner that violates the rights (including, but not limited
                    to contractual, intellectual property or proprietary rights) of any third party; or
                </ul>
                <ul>
                    13. upload to or transmit through the Slot Apps/Websites any information, images, text, data, media or
                    other content that is offensive, harmful, tortuous, hateful, obscene, defamatory or violates any laws
                    or is otherwise objectionable, in each case as determined by Slot in its sole discretion.
                </ul>


                <div>
                    <h2 className='headers'>
                        6 Intellectual Property Terms
                    </h2>
                </div>


                <strong>1. Content and Intellectual Property</strong>
                <ul>
                    o <strong>Slot Content and Intellectual Property Rights.</strong> The Slot Apps/Websites and all content contained
                    therein (excluding User Content as defined below), is owned or licensed by Slot and protected by
                    copyright, trade-mark and other intellectual property laws ("Slot Content"). Slot expressly
                    reserves all rights in the Slot Apps/Websites and all materials provided by Slot in connection with
                    these Terms & Conditions that are not specifically granted to you. You acknowledge that all right,
                    title and interest in the Slot Apps/Websites, all materials provided by Slot in connection with
                    these Terms & Conditions (including the Slot Content), and any update, adaptation, translation,
                    customization or derivative work thereof, and all intellectual property rights therein will remain
                    with Slot (or third party suppliers or licensors, if applicable), and that the Slot Apps/Websites and
                    all materials provided by Slot hereunder are licensed and not "sold" to you.
                </ul>

                <ul>
                    o <strong>User Content.</strong> All information, data, text, software, music, sound, photographs, graphics, video,
                    messages or other materials, whether publicly posted or privately transmitted to the Slot
                    Apps/Websites by Users ("User Content"), is the sole responsibility of such Users. This means
                    that the User, and not member of the Slot FZCO, are entirely responsible for all such material
                    uploaded, posted, emailed, transmitted or otherwise made available on the Slot Apps/Websites.
                    Slot may monitor User Content from time to time but Slot does not guarantee the accuracy,
                    integrity or quality of such content. Under no circumstances will any Members of the Slot FZCO
                    be liable in any way for any User Content including for any loss or damage of any kind incurred
                    as a result of the viewing or use of any materials posted, emailed, transmitted or otherwise made
                    available via the Slot Apps/Websites. To the extent your User Content contains any personally
                    identifiable information, that data is handled in accordance with the Slot Privacy Policy and all
                    other User Content will be considered non-confidential.
                </ul>

                <ul>
                    o <strong>User-Related Content.</strong> Slot may collect data, information, records, files, material or other
                    content from your local computer (including mobile) system or from third-parties with your
                    permission, and (in each case) including all results from processing such data, including
                    compilations, and derivative works thereof ("User-Related Content"). You grant to Slot FZCO a
                    perpetual, transferrable, irrevocable, royalty-free, worldwide and sublicensable license to access,
                    collect, store and use any such User-Related Content. To the extent your User-Related Content
                    contains any personally identifiable information, that data is handled in accordance with the Slot
                    Privacy Policy. User-Related Content is not considered confidential or proprietary.
                </ul>

                <p><strong>2. User Content License to Slot.</strong> By submitting, posting or displaying User Content on or through the

                    Slot Apps/Websites or otherwise to Slot, you grant Slot FZCO, a worldwide, non- exclusive, royalty-
                    free, perpetual, transferable, and fully sublicensable right to use, refrain from using, remove,

                    reproduce, modify, edit, copy, adapt, publish, translate, create derivative works from, distribute,
                    transmit, perform display and otherwise use User Content, in whole or in part. You further grant Slot
                    the right (although Slot does not have the obligation) to pursue at law any person or entity that
                    violates your or Slot’s rights in the User Content by a breach of these Terms & Conditions . You
                    represent and warrant that you have all the rights, power and authority necessary to grant the rights
                    granted herein to any User Content that you submit. For the avoidance of doubt, Slot has no
                    obligation to post or display any User Content on the Slot Apps/Websites.</p>

                <p><strong>3. Use of Slot Content and User Content by other Users.</strong>You may only use Slot Content and other
                    Users Content (eg. menus provided by Vendors) for the applicable Permitted Use. If you print extracts
                    from the Slot Apps/Websites for your own personal, non- commercial use, you must not modify the
                    digital or papers of such materials or use any graphics, pictures, photographs or videos separately
                    from any accompanying text.</p>

                <strong>4. Terms on Submissions/Feedback and Other User Content</strong>
                <ul>o <strong>Creating Submissions and Other User Content.</strong> Through the capabilities of the Slot
                    Apps/Websites you may be able to provide ratings/reviews, suggestions or other feedback
                    ("Submissions"). Submissions are a subset of User Content. In creating and posting any
                    Submissions (or other User Content) you represent and warrant that you will not post or transmit
                    to or from the Slot Apps/Websites any material or content which does or may:</ul>

                <ul style={{ listStyleType: "none", paddingLeft: "50px" }}>
                    <li>▪ Breach any applicable local, national or international law;</li>
                    <li>▪ Be unlawful or fraudulent;</li>
                    <li>▪ Amount to unauthorized advertising;</li>
                    <li>▪ Contain any defamatory, obscene or offensive material;</li>
                    <li>▪ Promote violence or discrimination;</li>
                    <li>▪ Infringe the intellectual property rights of another person;</li>
                    <li>▪ Breach any legal duty owed to a third party (such as a duty of confidence);</li>
                    <li>▪ Promote illegal activity or invade another’s privacy;</li>
                    <li>▪ Give the impression that they originate from us; or</li>
                    <li>▪ Be used to impersonate another person or to misrepresent your affiliation with another person.</li>
                </ul>
                <ul>You will be solely liable for any damages resulting from any violation of the foregoing restrictions,
                    or any other harm resulting from your posting of Submissions or other User Content to the Slot
                    Apps/Websites.</ul>

                <ul><strong>o Status of Submissions on the Slot Apps/Websites.</strong>We reserve the right to remove or edit at any
                    time any Submissions or other User Content posted, uploaded or transmitted to the Slot
                    Apps/Websites that we determine breaches the restrictions in the section above or is otherwise
                    objectionable or may expose us or any third parties to any harm or liability of any type, or for any
                    reason. Any Submissions on the Slot Apps/Websites is for information purposes only and does
                    not constitute advice from us or the opinions of anyone within the Slot FZCO. User Content may
                    reflect the opinions of Customers who have ordered through the Slot Apps/Websites and any
                    statements, advice or opinions provided by such persons are theirs only. Accordingly, to the
                    fullest extent permitted by law, no Members of the Slot FZCO assume any responsibility or
                    liability to any person for any User Content, including without limitation any mistakes,
                    defamation, obscenity, omissions or falsehoods that you may encounter in any such materials.</ul>

                <ul><strong>o Public Display of Submissions.</strong>You acknowledge that Slot may choose to provide attribution of
                    your Submissions (for example, listing a date, User’s name and city on a User’s rating/review that
                    you submit) at Slot’s discretion, and that such User Content may be visible to other Users and
                    visitors to the Slot Apps/Websites. By providing a rating/review you acknowledge and consent to
                    us using your given name in association with the rating/review. All ratings/reviews that we
                    receive may be moderated before we publish them. We may do this to make sure that
                    ratings/reviews fit our content guidelines.</ul>

                <p><strong>5. Note on Privacy.</strong>If you access any Person Information through or in connection with the Slot
                    Apps/Websites you agree that you will treat such Personal Information in accordance with Slot's
                    current Privacy Policy and you will not collect or harvest any Personal Information, including Personal
                    Information of other users, except as permitted by these Terms & Conditions.</p>

                <p className='headers'>7 Advertising and Third-Party Websites</p>
                <p>1. If you elect to have any business dealings with anyone whose products or services may be advertised
                    on the Slot Apps/Websites, you acknowledge and agree that such dealings are solely between you
                    and such advertiser and you further acknowledge and agree that no Members of the Slot FZCO will
                    have any responsibility or liability for any losses or damages that you may incur as a result of any
                    such dealings.</p>

                <p>2. The Slot Apps/Websites may provide links to third party websites and such links are provided solely
                    for your convenience. Third party websites: Links to third party websites on the Slot Apps/Websites
                    are provided solely for your convenience. If you use these links, you leave the Slot Apps/Websites.
                    We have not reviewed and do not control any of these third-party websites (and are not responsible
                    for these websites or their content or availability). We do not endorse or make any representation
                    about these websites, their content, or the results from using such websites or content. If you decide
                    to access any of the third-party websites linked to the Slot Apps/Websites, you do so entirely at your
                    own risk.</p>
                <p>3. Slot reserves the right to prohibit or remove (or require you to remove) any link to the Slot
                    Apps/Websites, including, without limitation, any link which contains or makes available any content
                    or information of the foregoing nature, at any time.</p>

                <div>
                    <h2 className='headers'>
                        8 Availability and Updates
                    </h2>
                </div>

                <p>
                    1. Slot may alter, suspend, or discontinue the Slot Apps/Websites or Service at any time and for any
                    reason or no reason, without notice. The Slot Apps/Websites or Service may be unavailable from
                    time to time due to maintenance or malfunction of computer or network equipment or other
                    reasons. Slot may periodically add or update the information and materials on this the Slot
                    Apps/Websites without notice.
                </p>
                <p >
                    2. While we try to ensure the Slot Apps/Websites is normally available twenty-four (24) hours a day,
                    we do not undertake any obligation to do so, and no Members of the Slot FZCO will be liable to you
                    if the Slot Apps/Websites is unavailable at any time or for any period.
                </p>
                <p >
                    3. Slot may upgrade or alter the Slot Apps/Websites at any time. Slot may remotely access the Slot
                    Apps/Websites (including for greater certainty any Slot Apps/Websites App) at any time for any
                    lawful reason, in particular to update the software or to perform software maintenance.
                </p>


                <div>
                    <h2 className='headers'>
                        9 DISCLAIMERS
                    </h2>
                </div>

                <p >
                    The laws of certain jurisdictions do not allow the exclusion or limitation of legal warranties, conditions or
                    representations. if these laws apply to you, some or all of the exclusions or limitations in these Terms &
                    Conditions (including the following disclaimers and limitations of liability) may not apply and you may have
                    additional rights.
                </p>
                <ul><strong>1. Content: </strong> While we try to ensure that information on the Slot Apps/Websites is correct, we do not
                    promise it is accurate or complete. We may make changes to the material on the Slot Apps/Websites
                    including to Services/Goods and prices described on it, at any time without notice. The material on
                    the Slot Apps/Websites may be out of date, and we make no commitment to update that material.</ul>

                <ul><strong>2. Allergy, dietary and other menu information:</strong>We try to accurately copy the item names,
                    descriptions, prices, special offer information, heat and allergenic warnings and other information
                    ("Menu Information") from the menus that are provided to us by Vendors. However, it is the Vendors
                    that are responsible for providing this Menu Information and ensuring that it is factually accurate
                    and up-to-date, and we do not undertake any such responsibility. If you are in doubt about allergy
                    warnings, contents of a dish or any other Menu Information, you should confirm with the Vendor
                    directly before ordering.</ul>

                <ul><strong>3. Vendor actions and omissions:</strong>The legal contract for the supply and purchase of Services/ Goods is
                    between the End-user and the Vendor that you place your Order with. We have no control over the
                    actions or omissions of any Vendor. Without limiting the generality of the foregoing, you
                    acknowledge and accept the following by using the Slot Apps/Websites:</ul>

                <ul style={{ listStyleType: "none", paddingLeft: "50px" }}>
                    <li>o We do not give any undertaking that the slots/Goods ordered from any Vendor through the Slot
                        Apps/Websites will be of satisfactory quality or suitable for your purpose and we disclaim any
                        such warranties.</li>
                    <li>o Estimated times for confirming order are provided by the Vendors and are only estimates.
                        Neither we nor the Vendors guarantee that Orders will be delivered or will be available within
                        the estimated times.</li>
                    <li>o We encourage all Vendors accept all Orders and to communicate any rejection promptly, and we
                        will notify you (generally by Slot App pop-up notification) as soon as reasonably practicable if a
                        Vendor rejects your Order. However, we do not guarantee that Vendors will accept all Orders,
                        and Vendors have the discretion to reject Orders at any time because they are too busy, due to
                        weather conditions or for any other reason.</li>

                </ul>
                <ul>The foregoing disclaimers do not affect Customer’s statutory rights against any Vendor.</ul>

                <ul><strong>
                    4. Service Disclaimers
                </strong></ul>

                <ul style={{ listStyleType: "none", paddingLeft: "50px" }}>
                    <li>o <strong>Viruses.</strong> The downloading and viewing of the Slot Apps/Websites or the Content is done at your
                        own risk. Slot cannot and does not guarantee or warrant that the Slot Apps/Websites or the
                        Content are compatible with your computer system or that the Slot Apps/Websites or the
                        Content, or any links from the Slot Apps/Websites or the Content, will be free of viruses, worms,
                        malware, trojan horses or disabling devices or other code that manifests contaminating or
                        destructive properties. You are responsible for implementing safeguards to protect the security
                        and integrity of your computer system, and you are responsible for the entire cost of any service,
                        repairs or connections of and to your computer system that may be necessary as a result of your
                        use of the Slot Apps/Websites.</li>
                    <li>o <strong>Communications Not Confidential.</strong> Slot does not guarantee the confidentiality of any
                        communications made by you through the Slot Apps/Websites. Although Slot generally adheres
                        to the accepted industry practices in securing the transmission of data to, from and through the
                        Slot Apps/Websites, you understand, agree and acknowledge that Slot cannot and does not
                        guarantee the security of data transmitted over the Internet or public networks in connection
                        with your use of the Slot Apps/Websites.</li>

                </ul>

                <div>
                    <h2 className='headers'>
                        10 No Implied Warranties; Limitations of Liability.
                    </h2>
                </div>
                <p>
                    1. <strong>No implied warranties.</strong> the Slot services, the Slot Apps/Websites and the content are provided "as
                    is" without warranty or condition of any kind. to the fullest extent permitted by law, Slot disclaims
                    all warranties, representations and conditions of any kind with respect to the Slot services, Slot
                    Apps/Websites and the content whether express, implied, statutory or collateral, including, without
                    limitation, the implied warranties and conditions of merchantability, fitness for a particular purpose
                    and non-infringement or that the Slot services, the Slot Apps/Websites or the content are or will be
                    error-free or will operate without interruption.
                </p>
                <p >
                    2. <strong>Exclusion of indirect damages.</strong> in no event will Slot or any Members of the Slot FZCO or Swift Beam
                    LLC be liable, whether based on warranty, contract, tort, negligence, strict liability or any other legal
                    theory, for any damages of any kind (including, without limitation, indirect, incidental, consequential,
                    special, exemplary or punitive damages, lost profits, loss of revenue, loss of use, loss of data, personal
                    injury, fines, fees, penalties or other liabilities), whether or not Slot is advised of the possibility of
                    such damages, resulting from or related to the use of, or the inability to make use of, the Slotservices,
                    the Slot Apps/Websites or the content.
                </p>
                <p>
                    3. <strong>No responsibility for vendors.</strong> vendors offering goods or services through the Slot Apps/Websites
                    are independent persons or organizations and not representatives, agents or employees of Slot. Slot
                    is therefore not liable for the acts, errors, omissions, representations, warranties, contractual
                    breaches or negligence of any vendors or for any personal injury, death, property damage, or other
                    damages or expenses resulting therefrom and takes no responsibility whatsoever for the products
                    or services offered by vendors.
                </p>
                <p>
                    4. <strong>limitation of liability.</strong> to the extent that the foregoing limitations do not apply, in no event will the
                    total aggregate liability of the Slot FZCO in connection with or under these Terms & Conditions ,
                    including in connection with your use of, or inability to make use of, the Slot services, the Slot
                    Apps/Websites or the content exceed: (i) the Convenience Fees paid by you in the preceding 4
                    months; or (ii) AED100 (One Hundred Emirati Dirhams), whichever is lower. for greater certainty, the
                    existence of one or more claims under these Terms & Conditions will not increase the maximum
                    liability amount.
                </p>
                <p>
                    5. <strong>Additional costs:</strong> You assume full and sole responsibility for any additional or associated costs that
                    you may incur in connection with or as a result of your use of the Slot Apps/Websites, including
                    without limitation costs relating to the servicing, repair or adaptation of any equipment, software or
                    data that you may own, lease, license or otherwise use.
                </p>
                <p>
                    The limitations above reflect a fair allocation of risk but for which Slot would not make the Slot Apps/Websites
                    and/or Slot services available to users. The limitations specified in this section will survive and apply even if
                    any limited remedy specified in these terms is found to have failed of its essential purpose.
                </p>


                <div>
                    <h2 className='headers'>
                        11 Indemnification
                    </h2>
                </div>
                <p>
                    You agree to defend, indemnify and hold harmless Slot FZCO, its licensors and their directors, officers, agents,
                    contractors, partners, representatives and employees from and against any threated or actual claims, causes of
                    action, demands, recoveries, losses, damages, fines, penalties or other costs or expenses of any kind or nature
                    including but not limited to reasonable legal fees, incurred in connection with or as a result of:
                </p>

                <ul>
                    1. your breach of these Terms & Conditions or any documents referenced herein;
                </ul>
                <ul>2. your violation of any law or the rights of a third party (including, without limitation, intellectual
                    property rights);</ul>

                <ul>
                    3. your breach of or failure to perform in respect of any Orders made by you or by any third party acting
                    on your behalf or with your permission; or
                </ul>
                <p >
                    Slot reserves the right, at its own expense, to assume the exclusive defense and control of any matter otherwise
                    subject to indemnification by you hereunder, and you shall cooperate as fully as reasonably required by Slot.
                </p>

                <div>
                    <h2 className='headers'>
                        12 Governing Law and Dispute Resolution
                    </h2>
                </div>

                <p>
                    1. Governing Law. These Terms & Conditions will be governed by the laws of Dubai, UAE, these laws apply
                    to your access to or use of the, the Slot Apps/Websites, the Slot Services or the Content,
                    notwithstanding your domicile, residency or physical location. The Slot Apps/Websites, the Slot
                    Services and the Content are intended for use only in jurisdictions where they may lawfully be offered
                    for use.
                </p>

                <p>
                    2. Dispute Resolution Process.
                </p>

                <ul>
                    <li> o Except to the extent restricted by applicable law, if there is any dispute or controversy between
                        One (1) you and Two (2) Slot or any Members of the Slot FZCO, including any dispute or
                        controversy arising out of or relating to these Terms & Conditions , the Slot Apps/Websites or
                        Slot Services, any interactions or transactions between One (1) you and Two (2) Slot or any
                        Members of the Slot FZCO, or in respect of any legal relationship associated with or derived
                        from these Terms & Conditions , including the validity, existence, breach, termination,
                        construction or application, or the rights, duties or obligations of you or us, (each, a "Dispute"),
                        the disputing person will serve notice on the other person and each you and us must use good
                        faith efforts to resolve the Dispute informally.</li> <br></br>
                    <li>
                        o Except to the extent restricted by applicable law, if the Dispute is not resolved after Ten (30)
                        business days of a person serving notice on the other party that there is a Dispute, you and us
                        agree that the Dispute will be finally resolved by confidential arbitration before a single
                        arbitrator in accordance with the Arbitration Rules of the Dubai International Arbitration Centre
                        -DIAC-, Dubai, United Arab Emirates. The seat of the arbitration will be Dubai. There will be no
                        appeals of any kind. The language of the arbitration will be Arabic or English unless otherwise
                        required by applicable law or agreed to by you and us.
                    </li>
                    <br></br>
                    <li>o Except to the extent restricted by applicable law, you and us agree that you and us will resolve
                        any Dispute on an individual basis. Any claim you may have must be brought individually, in
                        your individual capacity and not as a representative plaintiff or class Members, and you will not
                        join such claim with claims of any other person or entity, or bring, join or participate in a class
                        action lawsuit, collective or representative proceeding of any kind (existing or future) against
                        any Members of the Slot FZCO.</li>
                    <br></br>
                    <li>o Nothing in this Section will prohibit us from seeking interim measures from a court, including
                        preliminary or injunctive relief of breach of you of any intellectual property rights.</li>
                </ul>

                <div>
                    <h2 className='headers'>
                        13 Miscellaneous
                    </h2>
                </div>

                <p >
                    1.<strong>Interpretation.</strong>  Unless the context clearly requires otherwise, (a) references to the plural include the
                    singular, the singular the plural, the part the whole, (b) references to any gender include all genders, (c)
                    “including” has the inclusive meaning frequently identified with the phrase “but not limited to” and (d)
                    references to “hereunder” or “herein” relate to this Agreement. The section headings in this Agreement
                    are for reference and convenience only and shall not be considered in the interpretation of these Terms
                    & Conditions.
                </p>

                <p>
                    2. <strong>Entire Agreement.</strong> If you are a Customer, these Terms & Conditions, the Privacy Policy and any
                    documents incorporated by reference herein constitute the entire agreement between Slot and you
                    pertaining to the subject matter hereof and supersede all prior or contemporaneous communications
                    and proposals, whether electronic, oral or written, between you and Slot with respect to the Slot
                    Apps/Websites. A printed version of these Terms & Conditions and of any notice given in electronic form
                    will be admissible in judicial or administrative proceedings based upon or relating to these Terms &
                    Conditions to the same extent and subject to the same conditions as other business documents and
                    records originally generated and maintained in printed form.
                </p>
                <p>
                    3.<strong>Notices.</strong>  Slot may, in its sole discretion, provide any notices to you in connection with these Terms &
                    Conditions through the Slot Apps/Websites or by email at the then-current email address for you on file
                    with Slot. Slot may be contacted in writing at:
                </p>
                <p><a href="mailto:CC@slotyourspace.com" style={{ color: 'blue' }}>CC@slotyourspace.com</a></p>

                <p>
                    Slot may change its notice contact information from time to time by posting updated contact details on
                    the Slot Apps/Websites.
                </p>
                <p>
                    4. <strong>No Waiver.</strong> Slot's failure to insist upon or enforce strict performance of any provision of these Terms &
                    Conditions will not be construed as a waiver of any provisions or right.
                </p>
                <p>
                    5. <strong>Force Majeure.</strong> Slot will not be liable to you for any failure of or delay in the performance of its
                    obligations under these Terms & Conditions for the period that such failure or delay is due to causes
                    beyond Slot's reasonable control, including but not limited to acts of God, power outages, internet disconnection, war, strikes or labor disputes, embargoes, government orders or any other force majeure
                    event.
                </p>

                <p>
                    6. <strong>Severability.</strong> If any of the provisions contained in these Terms & Conditions are determined to be void,
                    invalid or otherwise unenforceable by a court of competent jurisdiction, the provision shall be modified
                    by the court and interpreted so as best to accomplish the objectives of the original provision to the fullest
                    extent permitted by law, and the remaining provisions of this Agreement shall remain in effect.
                </p>

                <p>7. <strong>Order of Precedence. </strong>If any of the provisions contained in these Terms & Conditions conflict with the
                    terms of another agreement between you and us, then these Terms & Conditions will prevail; provided
                    that, if you are also a Vendor registered on the Slot Apps/Websites, the terms applicable to the Vendor
                    agreement will govern your relationship with Slot in connection with your use of the Slot Apps/Websites
                    as a Vendor offering its services.</p>

                <p>8.<strong>Language.</strong>  These Terms & Conditions are made available in English speaking Users.</p>

                <p>9. <strong>Assignment.</strong> You may not transfer any of your rights or obligations under these Terms & Conditions
                    without our prior written consent. We may transfer any of our rights or obligations under these Terms
                    & Conditions without your prior written consent to any of our affiliates or any business that we enter
                    into a joint venture with, purchase or are sold to.</p>

                <div>
                    <h2 className='headers'>
                        14 Ordering Terms
                    </h2>
                </div>

                <p>
                    1. Access, Slot Services and Products
                </p>
                <ul>
                    <li>o Through the Slot Apps/Websites you may place orders for Service/Goods from Vendors, you may
                        receive Services/Goods and Slot may provide customer care support between you and Vendors
                        (these services and associated services such as order management, payment processing for
                        Orders and provision of access to the Slot Apps/Websites provided by Slot from time to time are
                        the "Slot Services"). For avoidance of doubt, Slot does not prepare or fulfil any orders for Goods
                        or provide services directly and no Members of the Slot FZCO will be liable for any transactions
                        between End-user or Vendors. Slot may provide products or services to Vendors through a
                        separate agreement executed with such parties. Menus and other Vendor-related information
                        on the Slot Apps/Websites is content provided by the Vendor and not Slot.</li>
                </ul>
                <p>
                    2. Slot Customer Account
                </p>
                <ul>
                    <li>o To use the Slot Apps/Websites and most features of the Slot Services, you are required to
                        successfully create a user account (the "Slot Customer Account") using the available interfaces at
                        the Slot Apps/Websites.</li>
                </ul>
                <p>
                    3. Placing Orders
                </p>
                <ul>
                    <li>o Once you have created your Slot Customer Account you may select Services/Goods from a
                        Vendor’s menu, choose your desired quantities, and then add them to your cart. Your cart may
                        be edited at any time prior to placing an Order. Once you have made your selection, you may
                        proceed to place your Order. At this time, you will have the opportunity to review your cart items.
                        After you review and confirm that the items in your cart are correct, you may proceed to place
                        your Order, provide your payment card information or select wallet payment option, if available,
                        and an Order will be created and sent to the Vendor. You are responsible to ensure that all of
                        your Order details, including billing, timing, slot size and other information is current, complete and accurate. Your payment card will be charged when you place your Order if the Order is a
                        Card Order. Once you have placed your Order, you will not be entitled to change or cancel your
                        Order and you will not be entitled to a refund (except where required by law). Notwithstanding
                        that you are not entitled as of right to change your Order or be entitled to a refund (except where
                        prohibited by law), if you wish to amend or cancel your Order for a total or partial refund of your
                        Order’s price, you must get in touch with Slot via chat, email or the Slot support phone line at the
                        contact information provided on the Slot Apps/Websites.</li>
                    <br></br>
                    <li>
                        o We encourage Vendors to confirm all Orders and to communicate any inability or unwillingness
                        to confirm promptly, and we will notify you as soon as reasonably practicable if the Vendor does
                        not confirm your Order. Vendor's may have the discretion to not confirm Orders. In case, Vendor
                        not confirming your order, the amount of the package paid will be transferred to your Slot wallet
                        where you can use it for your future booking. No refund will be granted to any Slot users.
                    </li>
                    <br></br>
                    <li>o You agree that Slot or the Vendor will have no liability to you for Orders that are not confirmed,
                        are cancelled or that the Vendor has been unable or unwilling to fulfill.</li>
                </ul>
                <p>
                    4. Customer Care, Goods Contract, Third Party and Refunds
                </p>
                <ul>
                    <li>o Slot may offer customer care in respect of issues relating to the Vendors. If you have any
                        problems, questions or suggestions with respect to the Slot Apps/Websites or your Order, please
                        contact Slot’s customer care team (and not the Vendor) You can contact Slot's customer care
                        team through various communication channels, including direct messages on Instagram and
                        Twitter (Instagram: slot_yourspace, Twitter: slot_yourspace), as well as WhatsApp at +968-
                        94045745. Alternatively, you may also contact them via email or phone call using the contact
                        information provided on the Slot Apps/Websites.</li>
                    <br></br>
                    <li>o The legal contract for the purchase of Goods will in all cases be between the End-user and the
                        Vendor. You irrevocably appoint and authorize us to act as your sole and exclusive agent for the
                        purpose of concluding contracts for the sale of Goods between you and the Vendor by means of
                        you placing Orders via the Slot Apps/Websites. We may act as agent for the Customer and the
                        Vendor for the legal contract for the purchase and sale of the Goods. YOU ACKNOWLEDGE AND
                        AGREE THAT NO MEMBERS OF THE SLOT FZCO HAS ANY CONTROL OVER THE QUALITY OF THE
                        GOODS OR SERVICES OFFERED BY VENDORS AND THAT NO MEMBERS OF THE SLOT FZCO WILL
                        HAVE LIABILITY TO YOU FOR ANY PROBLEMS CAUSED BY THE VENDOR CONCERNING YOUR
                        ORDERS, INCLUDING, WITHOUT LIMITATION, MISSED OR LATE BOOKING CONIFRMATION,
                        MISSED GOODS ITEMS, AND ANY PROBLEMS WITH RESPECT TO THE QUALITY OF THE GOODS
                        INCLUDING IF GOODS CAUSE ILLNESS, ALLERGIC REACTIONS OR HAVE INCORRECT NUTRITIONAL
                        VALUES.</li>
                    <br></br>
                    <li>o Slot may provide, in its discretion, the refund or compensation by payment to your payment
                        credit or debit card or providing the Customer with Slot Credits (as defined below).</li>
                </ul>
                <p >
                    5. Payment Processing Services
                </p>
                <ul>
                    <li>o Payment processing services for the Slot Apps/Websites are provided by payment services
                        providers as Slot may choose from time to time (collectively referred to as the "Payment
                        Processing Services Provider"). You hereby authorize Slot to share with the Payment Processing
                        Services Provider that financial information, banking details and transaction information related
                        to your use of the payment processing services and the Slot Apps/Websites. You also agree that
                        the Slot FZCO is not responsible for the use or misuse of such financial information, banking
                        details and transaction information by the Payment Processing Services Provider or any third
                        party.</li>
                </ul>
                <p>6. Price and Payment</p>
                <ul>
                    <li>o Service prices listed on the Slot Apps/Websites from Vendor are in the Vendor local currency,
                        Slot App/Website might show prices in your currency as indication purpose not for billing
                        purpose. Services prices may not include applicable tax and Services charges or Convenience Fee.
                        Taxes , Convenience Fee and Services charges may be added at checkout and will be payable by
                        you along with the Order price.</li>
                    <br></br>
                    <li>o Payment for Orders must be made with an accepted credit or debit card through the Slot
                        Apps/Websites ("Card Orders") .</li>
                    <br></br>
                    <li>o For Card Orders, you will be charged at the time of placing your Order. The Slot Apps/Websites
                        uses the third-party payment processors to effect charges onto your debit or credit card. You
                        consent to the collection and use of your information (including, if applicable, personal
                        information) by such payment processing service as necessary to process your payments. We
                        reserve the right to change, or to stop accepting, any permitted payment method at any time in
                        our sole discretion. You agree we may charge your payment card for any order placed and for
                        any additional amounts (including any taxes) as may be applicable in connection with your
                        purchase. You are responsible to ensure that all of your billing information is current, complete,
                        and accurate. We will provide you with an online or emailed billing summary statement which
                        you may review, save, or print at your discretion. This is the only billing statement that will be
                        provided by us.</li>
                    <br></br>
                    <li>o Slot or the Vendor is under no obligation to fulfill your Order at the incorrect price. Prices at the
                        Vendor’s Goods/Services in the Vendor’s physical location and at the Slot Apps/Websites may be
                        different. Any such differences will not entitle you to a refund or a reduction in price of your
                        Order.</li>
                    <br></br>
                    <li>o Services’ prices and any other fees are subject to change at any time at Slot’s or the Vendor’s
                        discretion.</li>
                </ul>
                <div>
                    <h2 className='headers'>
                        15 Cancellation Policy
                    </h2>
                    <table className='cancellationTable' border="2" style={{ width: "100%", borderCollapse: 'collapse', textAlign: "center" }}>
                        <thead>
                            <tr>
                                <th>Change Event</th>
                                <th>Change By</th>
                                <th>X is Min Booking Notice</th>
                                <th>Cancelation Fee %</th>
                                <th>Action </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Change before</td>
                                <td>End-User</td>
                                <td>4X</td>
                                <td>Free</td>
                                <td>Delete Booked Slot & Move 100% of Voucher Amount to EU Wallet, 0% to SP Wallet</td>
                            </tr>
                            <tr>
                                <td>Change before</td>
                                <td>End-User</td>
                                <td>3X</td>
                                <td>-25%</td>
                                <td>Delete Booked Slot &Move 75% of Voucher Amount to EU Wallet , 25% to SP Wallet</td>
                            </tr>

                            <tr>
                                <td>Change before</td>
                                <td>End-User</td>
                                <td>2X</td>
                                <td>-50%</td>
                                <td>Delete Booked Slot & Move 50% of Voucher Amount to EU Wallet , 50% to SP Wallet</td>
                            </tr>

                            <tr>
                                <td>Change before</td>
                                <td>End-User</td>
                                <td>1X</td>
                                <td>-75%</td>
                                <td>Delete Booked Slot &Move 25% of Voucher Amount to EU Wallet, 75% to SP Wallet</td>
                            </tr>

                            <tr>
                                <td>Change within</td>
                                <td>End-User</td>
                                <td>X</td>
                                <td>-90%</td>
                                <td>Delete Booked Slot &Move 10% of Voucher Amount to EU Wallet , 90% to SP Wallet</td>
                            </tr>

                            <tr>
                                <td>Change before</td>
                                <td>Service Provider</td>
                                <td>4X</td>
                                <td>Free</td>
                                <td>Delete Booked Slot &Move 100% of Voucher Amount to EU Wallet, 0% to SP Wallet</td>
                            </tr>
                            <tr>
                                <td>Change before</td>
                                <td>Service Provider</td>
                                <td>3X</td>
                                <td>-125%</td>
                                <td>Delete Booked Slot &Move 125% of Voucher Amount to EU Wallet , -25%  deducted from SP Wallet</td>
                            </tr>
                            <tr>
                                <td>Change before</td>
                                <td>Service Provider</td>
                                <td>2X</td>
                                <td>-150%</td>
                                <td>Delete Booked Slot &Move 150% of Voucher Amount to EU Wallet , -50%  deducted from SP Wallet</td>
                            </tr>
                            <tr>
                                <td>Change before</td>
                                <td>Service Provider</td>
                                <td>1X</td>
                                <td>-175%</td>
                                <td>Delete Booked Slot &Move 175% of Voucher Amount to EU Wallet , -75%  deducted from SP Wallet</td>
                            </tr>
                            <tr>
                                <td>Change within</td>
                                <td>Service Provider</td>
                                <td>X</td>
                                <td>-190%</td>
                                <td>Delete Booked Slot &Move 190% of Voucher Amount to EU Wallet , -90%  deducted from SP Wallet</td>
                            </tr>

                        </tbody>
                    </table>

                </div>

            </div>
        </div >
    )
}
