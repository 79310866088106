import CustomersManagment from "../components/pages/admin/CustomersManagment";
import StaffManagment from "../components/pages/admin/StaffManagment";
import Reservations from "../components/pages/admin/Reservations";
import VendorList from "../components/pages/admin/VendorList";
import Package from "../components/pages/vendor/AddPackage";
import PackageList from "../components/pages/vendor/PackageList";
import SlotList from "../components/pages/vendor/SlotList";
import BranchList from "../components/pages/vendor/BranchList";
import Map from "../components/pages/vendor/Map";
import UserPofile from "../components/pages/general/UserProfile";
import Employees from "../components/pages/employee/EmployeesList";
import AddIndependentBranch from "../components/pages/vendor/AddIndependentBranch";
import AddSlot from "../components/pages/vendor/AddSlot";
import StaffOrderList from "../components/pages/admin/StaffOrderList";
import VendorsReservations from "../components/pages/admin/VendorsReservations";
import Invoice from "../components/pages/admin/Invoice";
import CustomerReservations from "../components/pages/admin/CustomerReservations";
import VendorReservationList from "../components/pages/admin/VendorReservationList";
import VendorOrderBill from "../components/pages/admin/VendorOrderBill";
import BillProfile from "../components/pages/admin/BillProfile";
import AcountAccess from "../components/pages/vendor/AcountAccess";
import GiveAccess from "../components/pages/vendor/GiveAccess";
import AddBranch from "../components/pages/vendor/AddBranch";
import AddIndependentBranchMap from "../components/pages/vendor/AddIndependentBranchMap";
import UpdateSlot from "../components/pages/vendor/UpdateSlot";
import Dashboard from "../components/pages/general/Dashboard";
import OrdersList from "../components/pages/vendor/OrdersList";
import UpdateBranch from "../components/pages/vendor/UpdateBranch";
import UpdateUserProfile from "../components/pages/general/UpdateUserProfile";

const DASHBOARD_ROUTES = [
  {
    path: "",
    component: <Dashboard />,
    users: ["admin", "developer"],
  },
  {
    path: "customers-managment",
    component: <CustomersManagment />,
    users: ["admin", "developer"],
  },
  {
    path: "staff-managment",
    component: <StaffManagment />,
    users: ["admin", "developer"],
  },
  {
    path: "reservations",
    component: <Reservations />,
    users: ["admin", "developer"],
  },
  {
    path: "vendor-list",
    component: <VendorList />,
    users: ["admin", "developer"],
  },
  {
    path: "add-package/:pageType",
    component: <Package />,
    users: ["vendor"],
  },

  {
    path: "package-list",
    component: <PackageList />,
    users: ["vendor"],
  },
  {
    path: "branch-list",
    component: <BranchList />,
    users: ["vendor"],
  },
  {
    path: "map",
    component: <Map />,
    users: [],
  },
  {
    path: "user-profile",
    component: <UserPofile />,
    users: [],
  },
  {
    path: "payment",
    component: <createSession />,
    users: ["admin", "developer"],
  },
  {
    path: "add-employee-list",
    component: <Employees />,
    users: ["admin", "developer"],
  },
  {
    path: "add-independent-branch",
    component: <AddIndependentBranch />,
    users: ["vendor", "developer"],
  },
  {
    path: "add-slot/:pageType",
    component: <AddSlot />,
    users: ["vendor"],
  },
  {
    path: "add-independent-branch-map",
    component: <AddIndependentBranchMap />,
    users: ["vendor"],
  },
  {
    path: "add-branch",
    component: <AddBranch />,
    users: ["vendor"],
  },
  {
    path: "orders-list",
    component: <OrdersList />,
    users: ["vendor", "developer"],
  },
  {
    path: "staff-order-list/:id",
    component: <StaffOrderList />,
    users: ["admin", "developer", "staff"],
  },
  {
    path: "vendors-reservations",
    component: <VendorsReservations />,
    users: ["admin", "developer"],
  },
  {
    path: "invoice/:id",
    component: <Invoice />,
    users: ["admin", "developer"],
  },
  {
    path: "customer-reservations/:id",
    component: <CustomerReservations />,
    users: ["admin", "developer"],
  },
  {
    path: "vendor-order-list/:id",
    component: <VendorReservationList />,
    users: ["admin", "developer"],
  },
  {
    path: "vendor-order-bill/:id/:totalIncome/:timestamp/:pariod/:bookedBy",
    component: <VendorOrderBill />,
    users: ["vendor", "admin", "developer"],
  },
  {
    path: "bill-profile/:id",
    component: <BillProfile />,
    users: ["vendor", "admin", "developer"],
  },
  {
    path: "slot-list",
    component: <SlotList />,
    users: ["vendor", "developer"],
  },
  {
    path: "paid",
    component: <paid />,
    users: ["vendor", "developer"],
  },
  {
    path: "acount-access",
    component: <AcountAccess />,
    users: ["vendor", "developer", "staff"],
  },
  {
    path: "give-access",
    component: <GiveAccess />,
    users: ["vendor", "developer"],
  },
  {
    path: "update-slot/:id",
    component: <UpdateSlot />,
    users: ["vendor", "developer"],
  },
  {
    path: "update-branch/:id",
    component: <UpdateBranch />,
    users: ["vendor", "developer"],
  },
  {
    path: "update-profile/:id",
    component: <UpdateUserProfile />,
    users: ["vendor", "developer", "staff"],
  },
];

export { DASHBOARD_ROUTES };
