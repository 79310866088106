import React from "react";
import swal from "sweetalert";
import Global from "../../../data/global";
import Navbar from "../../snippets/nav-sidebars/Navbar";
import PhoneInput from "react-phone-number-input";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { useSignup } from "../../../hooks/useSignup";
import { useLogout } from "../../../hooks/useLogout";
import { timestamp } from "../../../firebase/config";
import { projectStorage } from "../../../firebase/config";
import { projectFunctions } from "../../../firebase/config";
import "../../../style/auth/Signup.css";
import "react-phone-number-input/style.css";
import { Link } from "react-router-dom";
import { useEffect } from "react";

export default function Signup() {
  const navigate = useNavigate();
  const { signup, isPending, error } = useSignup();
  const { logout } = useLogout();
  const [email, setEmail] = useState("");
  const [ownerId, setOwnerId] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [brandName, setBrandName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [crDocuments, setCrDocuments] = useState("");
  const [cookies, setCookie] = useCookies(["brandName"]);
  const setUserClaims = projectFunctions.httpsCallable("setUserClaims");







  const createVendor = (url) => {
    signup(
      email,
      dateOfBirth,
      password,
      firstName,
      lastName,
      phoneNumber,
      brandName,
      crDocuments,
      ownerId,
      "pending",
      "vendor",
      url,
      timestamp.now()
    ).then(() => {
      setUserClaims({
        email: email,
        claims: { type: "vendor", status: "pending" },
      })
        .then((result) => { })
        .catch((error) => {
          console.log(error);
        });
      logout().then(() => {
        navigate("/login");
      });
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      phoneNumber.substring(4, 6).startsWith("9") ||
      phoneNumber.substring(4, 6).startsWith("7")
    ) {
      Global.brandName = brandName;
      setCookie("brandName", brandName, { path: "/" });
      const fileName = ownerId + new Date().getTime();
      if (crDocuments === null) return;
      projectStorage
        .ref()
        .child(
          `documents/${fileName.toString() + "." + crDocuments.name.split(".").pop()
          }`
        )
        .put(crDocuments)
        .then((snapshot) => {
          snapshot.ref.getDownloadURL().then((url) => {
            createVendor(url);
          });
        });
    } else {
      swal({
        text: "phone number should be 8 digits and start with 7 or 9 ",
      });
    }

    swal({
      text: "Your Account is Being Reviwed By Slot Managment",
    });
  };

  return (
    // <div className="signupContainer">
    <div>
      {" "}
      <Navbar />
      <section className="hero-body is-fullheight">
        <div >
          <div className="container">
            <div className=" is-centered">
              <div>
                <form className="signup-auth-form" onSubmit={handleSubmit}>
                  <ul className="tab-group">
                    <li className="is-active">
                      <Link to="/signup">Vendor</Link>
                    </li>
                    <li>
                      <Link to="/staff-signup">Staff</Link>
                    </li>
                  </ul>
                  <hr className="hrLine" />
                  <div className="vendor-row">
                    <h2
                      className="signup-auth-form-title"
                      style={{
                        fontSize: 25,
                        color: "#014D2F",
                        fontWeight: "bolder",
                      }}
                    >
                      Vendor Sign Up
                    </h2>
                    <input
                      className="vendor-row"
                      placeholder="First Name"
                      required
                      type="text"
                      onChange={(e) => setFirstName(e.target.value)}
                      value={firstName}
                    />
                    <input
                      className="vendor-row"
                      placeholder="Last Name"
                      required
                      type="text"
                      onChange={(e) => setLastName(e.target.value)}
                      value={lastName}
                    />
                    <input
                      className="vendor-row"
                      placeholder="Email"
                      required
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                    <input
                      className="vendor-row"
                      placeholder="Password"
                      required
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                    />
                    <PhoneInput
                      className="phoneInput"
                      required
                      international
                      defaultCountry="OM"
                      value={phoneNumber}
                      maxLength={14}
                      onChange={setphoneNumber}
                    />
                    <input
                      className="vendor-row-date"
                      required
                      type="date"
                      onChange={(e) => setDateOfBirth(e.target.value)}
                      value={dateOfBirth}
                    />
                    <input
                      className="vendor-row"
                      placeholder="Brand Name"
                      required
                      type="text"
                      onChange={(e) => setBrandName(e.target.value)}
                      value={brandName}
                    />

                    <input
                      className="vendor-row"
                      placeholder="Commercial Registration Number"
                      required
                      type="text"
                      onChange={(e) => setOwnerId(e.target.value)}
                      value={ownerId}
                    />

                    <input
                      className="vendor-row"
                      accept=".pdf"
                      required
                      type="file"
                      onChange={(e) => {
                        setCrDocuments(e.target.files[0]);
                      }}
                    ></input>

                    {!isPending && <button>Sign up</button>}
                    {isPending && <button disabled>loading</button>}
                    {error && <div className="error">{error}</div>}
                    <p className="link-signup">
                      Already have an account?
                      <a className="link-signup" href="/login">
                        Login
                      </a>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
