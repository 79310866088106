import React from "react";
import swal from "sweetalert";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import ReservationStatus from "../../data/reservationStatus";
import { useDocument } from "../../hooks/useDocument";
import { useFirestore } from "../../hooks/useFirestore";
import { projectFunctions } from "../../firebase/config";
import { useCollection } from "../../hooks/useCollection";
import { useEffect } from "react";

function ReservationCard({ reservation, userType, branches }) {
  const { updateReservation } = useFirestore("slots");
  const { updateCredit } = useFirestore("users");
  const { document } = useDocument("users", reservation.bookedBy);
  const sendNotification = projectFunctions.httpsCallable("sendNotification");

  // check if status of branch disabled nothing displayed
  if (branches && branches.length > 0) {
    const branchDocument = branches.find(
      (branch) => branch.id === reservation.branchId
    );

    if (
      branchDocument &&
      branchDocument.status === "disabled" &&
      userType == "admin"
    ) {
      return null;
    }
  }

  const onRejectClick = async (
    e,
    slotId,
    reservationId,
    bookedBy,
    branchTitle
  ) => {
    e.preventDefault();
    const newCredit = document["credit"] + reservation.totalIncome;
    const isArabic = document.app_lang === 'ar';
    const rejectedMessage = isArabic ?
      `يعتذر عن عدم توفر مساحة موفرة ${branchTitle}. الرجاء محاولة حجز مساحات أخرى.`
      : `${branchTitle} apologizes for the unavailability. Please book other slots and use your wallet for payment.`

    swal("Reject this Booking? (Can't Be Changed Latter!)", {
      buttons: ["No", "Yes"],
    }).then((value) => {
      if (value === true) {
        updateReservation(slotId, reservationId, {
          status: ReservationStatus.REJECTED,
        });
        updateCredit(reservation.bookedBy, newCredit);
        sendNotification({
          deviceToken: document.deviceToken,
          title: branchTitle,
          body: rejectedMessage,
        });
        return;
      }
    });
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };


  const onAcceptClick = async (
    e,
    slotId,
    reservationId,
    bookedBy,
    branchTitle
  ) => {
    e.preventDefault();
    const isArabic = document.app_lang === 'ar';
    const confirmationMessage = isArabic ?
      `هلا ${capitalizeFirstLetter(document.firstName)} ، لقد تم تأكيد حجزك في ${branchTitle}.`
      : `${capitalizeFirstLetter(document.firstName)} you’re confirmed, see you soon at ${branchTitle}`

    swal("Accept this Booking? (Can't Be Changed Latter!)", {
      buttons: ["No", "Yes"],
    }).then((value) => {
      if (value === true) {
        updateReservation(slotId, reservationId, {
          status: ReservationStatus.ACCPTED,
        });
        sendNotification({
          deviceToken: document.deviceToken,
          title: branchTitle,
          body: confirmationMessage,
        });
        return;
      }
    });
  };

  const formatDuration = (duration) => {
    const [hours, minutes] = duration.split(':').map(Number);

    if (hours === 0 && minutes === 0) {
      return 'Less than a minute';
    } else {
      let formattedDuration = '';

      if (hours > 0) {
        formattedDuration += `${hours} ${hours === 1 ? 'hour' : 'hours'}`;
      }

      if (minutes > 0) {
        if (formattedDuration.length > 0) {
          formattedDuration += ' ';
        }
        formattedDuration += `${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
      }

      return formattedDuration;
    }
  };

  const checkIn = async (e, slotId, reservationId, bookedBy, branchTitle) => {
    e.preventDefault();
    const isArabic = document.app_lang === 'ar';
    const checkInMessage = isArabic ?
      `شرفتنا في ${branchTitle}، استمتع بـ ${formatDuration(reservation.period)} في ${reservation.slotLable}`
      : `Welcome to ${branchTitle}, Enjoy ${formatDuration(reservation.period)} at ${reservation.slotLable} slot`

    swal("Did this customer arrive?", {
      buttons: ["No", "Yes"],
    }).then((value) => {
      if (value === true) {
        updateReservation(slotId, reservationId, {
          arrival: ReservationStatus.ARRIVED,
          arrivalTime: {
            title: "ARRIVED",
            timestamp: Date.now(),
          },
        });

        sendNotification({
          deviceToken: document.deviceToken,
          title: branchTitle,
          body: checkInMessage,
        });
        return;
      }
    });
  };

  const getStatusColor = (status) => {
    switch (status) {
      case ReservationStatus.PENDING:
        return "#FC9803";
      case ReservationStatus.ACCPTED:
        return "#3FD129";
      case ReservationStatus.REJECTED:
        return "#E82D2D";
      default:
        return "#FFF";
    }
  };

  return (
    <div className="card" key={reservation.id}>
      <h3 style={{ textAlign: "center" }}>
        {reservation.branchTitle} ( {reservation.slotLable} )
      </h3>
      &nbsp;
      <Divider />
      &nbsp;
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <p
            style={{
              textAlign: "center",
              backgroundColor: getStatusColor(reservation.status),
              borderRadius: "5px",
              color: "white",
              padding: 5,
            }}
          >
            {reservation.status}
          </p>
        </Grid>
        <Grid item xs={4}>
          <p
            style={{
              borderRadius: "5px",
              width: "100px",
              padding: 5,
              fontSize: 20,
            }}
          >
            {reservation.totalIncome} OMR
          </p>
        </Grid>
      </Grid>
      <h3>
        <b>{reservation.userName}</b>{" "}
      </h3>
      <h3>{reservation.bookedDate}</h3>
      <h3>
        {" "}
        {userType === "admin" && typeof reservation.creditPayment === "number"
          ? `( C: ${reservation.creditPayment.toFixed(
            3
          )} - PG: ${reservation.paymentGateway.toFixed(3)} )`
          : ""}{" "}
      </h3>
      <h3>
        ( {reservation.fromTime} - {reservation.toTime} )
      </h3>
      {["admin", "Vendor"].includes(userType) ? (
        <h5>
          {" "}
          {reservation.paidBySlot === true
            ? "Your business has received the payment for this reservation"
            : "Your business hasn't received the payment for this reservation"}
        </h5>
      ) : null}
      {userType !== "admin" &&
        reservation.status === ReservationStatus.PENDING ? (
        <Grid container spacing={1} style={{ marginTop: 10 }}>
          <Grid item xs={4}>
            <button
              className="slotButton"
              onClick={(e) => {
                onAcceptClick(
                  e,
                  reservation.slotId,
                  reservation.id,
                  reservation.bookedBy,
                  reservation.branchTitle
                );
              }}
            >
              Accept
            </button>
          </Grid>
          <Grid item xs={4}>
            <button
              className="btnRej"
              onClick={(e) => {
                onRejectClick(
                  e,
                  reservation.slotId,
                  reservation.id,
                  reservation.bookedBy,
                  reservation.branchTitle
                );
              }}
            >
              Reject
            </button>
          </Grid>
        </Grid>
      ) : reservation.arrival ? (
        <p>{reservation.arrival}</p>
      ) : (
        userType !== "admin" && (
          <Grid container spacing={1} style={{ marginTop: 10 }}>
            <Grid item xs={6}>
              <button
                className="btnCheck"
                onClick={(e) => {
                  checkIn(
                    e,
                    reservation.slotId,
                    reservation.id,
                    reservation.bookedBy,
                    reservation.branchTitle
                  );
                }}
              >
                <b>Check In</b>
              </button>
            </Grid>
          </Grid>
        )
      )}
    </div>
  );
}
export default ReservationCard;
