import { useEffect, useState, useRef } from "react";
import { projectFirestore } from "../firebase/config";

export const useCollectionGroup = (collection, _query, _orderBy) => {
  const [finalTotalIncome, setFinalTotalIncome] = useState(0.0);
  const [documents, setDocuments] = useState(null);
  const [error, setError] = useState(null);

  // if we don't use a ref --> infinite loop in useEffect
  // _query is an array and is "different" on every function call
  const query = useRef(_query).current;
  const orderBy = useRef(_orderBy).current;

  useEffect(() => {
    let ref = projectFirestore.collectionGroup(collection);

    if (query) {
      ref = ref.where(...query);
    }
    if (orderBy) {
      ref = ref.orderBy(...orderBy);
    }

    const unsubscribe = ref.onSnapshot(
      (snapshot) => {
        let results = [];
        let total = 0.0;

        snapshot.docs.forEach((doc) => {
          results.push({ ...doc.data(), id: doc.id });
          if (doc.data().status === "ACCEPTED") {
            total += Number(doc.data().totalIncome);
          }
        });
        setFinalTotalIncome(total);
        setDocuments(results);
        setError(null);
      },
      (error) => {
        console.log(error);
        setError("could not fetch the data");
      }
    );

    // unsubscribe on unmount
    return () => unsubscribe;
  }, [collection, query, orderBy]);

  return { documents, finalTotalIncome, error };
};
