import React from "react";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";
import { useCollection } from "../../../hooks/useCollection";
import { useCollectionGroup } from "../../../hooks/useCollectionGroup";
import "../../../style/admin/VendorReservation.css";

export default function VendorsReservations() {
  const spend = {};

  const { documents: reservations } = useCollectionGroup("reservations");
  const {
    isPending,
    error,
    documents: vendors,
  } = useCollection("users", ["type", "==", "vendor"]);


  reservations &&
    reservations.forEach((element) => {
      if (element["slotOwner"] === "TCzZ3p8MLMR9BiHnsePwOKL8OYv2") return;

      if (!spend[element["slotOwner"]]) {
        spend[element["slotOwner"]] = {};
        spend[element["slotOwner"]].spend = 0.0;
      }
      spend[element["slotOwner"]].spend += element["totalIncome"];
    });

  const pStyle = {
    fontSize: "21px",
    marginTop: 18,
  };

  return (
    <div style={{ marginTop: "100px", padding: "0 80px" }}>
      <h2
        style={{
          fontSize: "30px",
          color: " #014D2F",
          fontWeight: "bold",
        }}
        className="page-title"
      >
        Vendors
      </h2>

      <div className="Admin-Vendor-List">
        {isPending && <div>Loading Vendors...</div>}
        {error && <div>{error}</div>}
        {vendors &&
          vendors.map((vendor, index) => (
            // check if test account 
            vendor.testAccount === true ? null : (
              <Link
                key={index}
                to={`/dashboard/vendor-order-list/${vendor.id}`}
                className="card"
              >
                <h3
                  style={{
                    textAlign: "center",
                    fontSize: 19,
                    fontWeight: "bold",
                  }}
                >
                  {vendor.brandName + " "} (&nbsp;
                  {spend[vendor.id] ? spend[vendor.id].spend.toFixed(3) : 0.0} OMR
                  )
                </h3>
                &nbsp;
                <Divider />
                <p style={pStyle}>
                  {vendor.firstName} {vendor.lastName}
                </p>
                <p style={pStyle}> {vendor.email}</p>
                <p style={pStyle}>
                  <Link
                    to={`/dashboard/invoice/${vendor.id}`}
                    className="slotButton"
                  >
                    {" "}
                    Invoice
                  </Link>
                </p>
              </Link>
            )
          ))}
      </div>
    </div>
  );
}
