import React from 'react'
import moment from "moment";
import swal from "sweetalert";
import { useState } from "react";
import { useEffect } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from '@mui/icons-material/Edit';
import TimePicker from ".././general/TimePicker";
import IconButton from "@mui/material/IconButton";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDocument } from "../../../hooks/useDocument";
import { useFirestore } from "../../../hooks/useFirestore";
import { basePriceHeaders, inflationHeaders, OpeningTimeHeaders } from "../../../../src/data/tableHeaders";
import {
    Paper,
    TableCell,
    TableRow,
    TableContainer,
    TableBody,
    Table,
    TableHead,
} from "@mui/material";
import "../../../style/vendor/UpdateSlot.css";


export default function UpdateSlot() {
    const [isSlotLableVisible, setIsisSlotLableVisible] = useState(false);
    const [isSlotTypeVisible, setIsisSlotTypeVisible] = useState(false);
    const [isSlotSeatVisible, setIsisSlotSeatVisible] = useState(false);
    const [isSlotBasePriceVisible, setIsSlotBasePriceVisible] = useState(false);
    const [isSlotInflationVisible, setIsSlotInflationVisible] = useState(false);
    const [isSlotInOpeningTimeVisible, setIsSlotInOpeningTimeVisible] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();
    const { document, error } = useDocument("slots", id);
    const { updateSlot } = useFirestore("slots");
    const [isButtonSlotLableVisible, setisButtonSlotLableVisible] = useState(true);
    const [isButtonSlotTypeVisible, setisButtonSlotTypeVisible] = useState(true);
    const [isButtonSlotSeatVisible, setisButtonSlotSeatVisible] = useState(true);
    const [isButtonBasePriceVisible, setIsButtonBasePriceVisible] = useState(true);
    const [isButtonInflationVisible, setIsButtonInflationVisible] = useState(true);
    const [editableOpeningTimeIndex, setEditableOpeningTimeIndex] = useState(null);
    const [isButtonOpeningTimeVisibleArray, setIsButtonOpeningTimeVisibleArray] = useState([]);
    const [previousEditedRowIndex, setPreviousEditedRowIndex] = useState(null);


    useEffect(() => {
        if (document && document.opening_time) {
            const initialVisibilityArray = new Array(document.opening_time.length).fill(true);
            setIsButtonOpeningTimeVisibleArray(initialVisibilityArray);
        }
    }, [document]);


    var updateSlotObject = {};



    const initObject = () => {
        updateSlotObject = document;
    }

    document && initObject()

    const handleEditLableClick = () => {

        setIsisSlotLableVisible(true);
        setisButtonSlotLableVisible(false);
    };

    const handleEditTypeClick = () => {
        setIsisSlotTypeVisible(true);
        setisButtonSlotTypeVisible(false);
    };

    const handleEditSeatClick = () => {
        setIsisSlotSeatVisible(true);
        setisButtonSlotSeatVisible(false);
    };


    const handleEditBasePriceClick = (index) => {
        setIsSlotBasePriceVisible(true);
        setIsButtonBasePriceVisible(false);
    };

    const handleEditInflationClick = (index) => {
        setIsButtonInflationVisible(false);
        setIsSlotInflationVisible(true);
    };

    const handleEditOpeningTimeClick = (index) => {
        if (previousEditedRowIndex !== null) {
            const updatedVisibilityArray = [...isButtonOpeningTimeVisibleArray];
            updatedVisibilityArray[previousEditedRowIndex] = true;
            setIsButtonOpeningTimeVisibleArray(updatedVisibilityArray);
        }

        setIsButtonOpeningTimeVisibleArray(prev => {
            const updatedArray = [...prev];
            updatedArray[index] = false;
            return updatedArray;
        });
        setIsSlotInOpeningTimeVisible(true);
        setEditableOpeningTimeIndex(index);
        setPreviousEditedRowIndex(index);
    };



    const onClickUpdateSlot = (e, slotId, updateSlotObject) => {
        e.preventDefault();
        updateSlot(slotId, updateSlotObject)
            .then(() => {
                swal({
                    icon: "success",
                    text: "Slot successfully updated!",
                });
                navigate("/dashboard/slot-list");
            })
            .catch((error) => {
                console.error('Error updating document: ', error);
            });
    }

    return (
        <form
            className="update-slot-form">
            <h2 className='update-slot-h2-tag'>Edit Slot</h2>

            <div className='update-slot-div'>
                <span className='update-slot-span'> Slot Lable: </span>
                <label >
                    {document && document.slotLable}

                    <IconButton
                        title="Edit"
                        style={{
                            width: "60px",
                            color: " #014D2F",
                            marginLeft: "5px",
                            marginTop: "-10px",

                            display: isButtonSlotLableVisible ? "inline-block" : "none"
                        }}
                        onClick={handleEditLableClick}
                    >
                        <EditIcon />
                    </IconButton>
                </label>

            </div>

            {
                isSlotLableVisible &&
                <input
                    className='update-slot-input'
                    required
                    placeholder="Slot Lable"
                    type="text"
                    onChange={(e) => {
                        updateSlotObject["slotLable"] = e.target.value
                    }}
                />
            }


            <div className='update-slot-div'>
                <span className='update-slot-span'> Slot Type: </span>
                <label>
                    {document && document.slotType}

                    <IconButton
                        title="Edit"
                        style={{
                            width: "60px",
                            color: " #014D2F",
                            marginLeft: "5px",
                            marginTop: "-10px",
                            display: isButtonSlotTypeVisible ? "inline-block" : "none"
                        }}
                        onClick={handleEditTypeClick}
                    >
                        <EditIcon />
                    </IconButton>
                </label>

            </div>
            {
                isSlotTypeVisible &&
                <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    onChange={(e) => {
                        updateSlotObject["slotType"] = e.target.value
                    }}
                    style={{ width: 300 }}
                >
                    <MenuItem value="Room">Room</MenuItem>
                    <MenuItem value="Table">Table</MenuItem>
                </Select>
            }

            <div className='update-slot-div'>
                <span className='update-slot-span'>Seat Availability :</span>
                <label>
                    {document && document.numbeOfChair}
                    <IconButton
                        title="Edit"
                        style={{
                            width: "60px",
                            color: " #014D2F",
                            marginLeft: "5px",
                            marginTop: "-10px",
                            display: isButtonSlotSeatVisible ? "inline-block" : "none"
                        }}
                        onClick={handleEditSeatClick}
                    >
                        <EditIcon />
                    </IconButton>
                </label>

            </div>
            {
                isSlotSeatVisible &&
                <input
                    className='update-slot-input'
                    required
                    placeholder="Slot Seat"
                    type="number"
                    onChange={(e) => {
                        updateSlotObject["numbeOfChair"] = e.target.value
                    }}
                />
            }


            {
                document && document.base_pricing && document.base_pricing.length > 0 ? (
                    <div className='update-slot-div'>
                        <span className='update-slot-span'>Base Pricing : </span>
                        <div style={{ marginTop: 15, marginBottom: 15 }}>


                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            {basePriceHeaders.map((header, index) => (
                                                <TableCell
                                                    key={index}
                                                    style={{ fontWeight: "bold", fontSize: "18px" }}
                                                >
                                                    {header}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {document.base_pricing.map((basePricing, index) => (
                                            <TableRow
                                                key={index}
                                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                            >
                                                <TableCell align="right">{basePricing.hours}</TableCell>
                                                <TableCell align="right">{basePricing.price}</TableCell>
                                                <TableCell align="right">
                                                    <IconButton
                                                        id="basePriceButton"
                                                        title="Edit"
                                                        style={{
                                                            width: "60px",
                                                            color: " #014D2F",
                                                            marginLeft: "5px",
                                                            marginTop: "-10px",
                                                            display: isButtonBasePriceVisible ? "block" : "none"
                                                        }}
                                                        onClick={() => handleEditBasePriceClick(index)}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>

                                                    {isSlotBasePriceVisible &&
                                                        <div>
                                                            <input
                                                                className='update-slot-input'
                                                                required
                                                                placeholder={basePricing.hours}
                                                                type="number"
                                                                onChange={(e) => {
                                                                    updateSlotObject["base_pricing"][index]['hours'] = parseInt(e.target.value)
                                                                }}
                                                            />

                                                            <input
                                                                className='update-slot-input'
                                                                required
                                                                placeholder={basePricing.price}
                                                                type="number"
                                                                onChange={(e) => {
                                                                    updateSlotObject["base_pricing"][index]['price'] = parseFloat(e.target.value)
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        ))}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                ) : (
                    <div></div>
                )
            }

            {
                document && document.inflation && document.inflation.length !== 0 ? (
                    <div className='update-slot-div'>
                        <span className='update-slot-span'>Inflation : </span>
                        <div style={{ marginTop: 15, marginBottom: 15 }}>


                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            {inflationHeaders.map((header, index) => (
                                                <TableCell
                                                    key={index}
                                                    style={{ fontWeight: "bold", fontSize: "18px" }}
                                                >
                                                    {header}
                                                </TableCell>

                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {document && updateSlotObject.inflation.map((inflation, index) => (
                                            <TableRow
                                                key={index}
                                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                            >
                                                <TableCell align="right">{inflation.start_time}</TableCell>
                                                <TableCell align="right">{inflation.end_time}</TableCell>
                                                <TableCell align="right">{inflation.inflation}</TableCell>
                                                <TableCell align="right">{inflation.type}</TableCell>
                                                <TableCell align="right">
                                                    <IconButton
                                                        title="Edit"
                                                        style={{
                                                            width: "60px",
                                                            color: " #014D2F",
                                                            marginLeft: "5px",
                                                            marginTop: "-10px",
                                                            display: isButtonInflationVisible ? "block" : "none"
                                                        }}
                                                        onClick={() => handleEditInflationClick(index)}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>

                                                    {isSlotInflationVisible &&
                                                        <div>
                                                            <TimePicker
                                                                className='update-slot-input'
                                                                placeholder={inflation.start_time}
                                                                onChange={(e) => {
                                                                    updateSlotObject["inflation"][index]['start_time'] = moment(e).format("hh:mm A")
                                                                }} />

                                                            <TimePicker
                                                                className='update-slot-input'
                                                                placeholder={inflation.end_time}
                                                                onChange={(e) => {
                                                                    updateSlotObject["inflation"][index]['end_time'] = moment(e).format("hh:mm A")
                                                                }}
                                                            />

                                                            <input
                                                                className='update-slot-input'
                                                                required
                                                                placeholder={inflation.inflation}
                                                                type="number"
                                                                onChange={(e) => {
                                                                    updateSlotObject["inflation"][index]['inflation'] = e.target.value
                                                                }}
                                                            />

                                                            <Select
                                                                className='update-slot-input'
                                                                labelId="demo-simple-select-filled-label"
                                                                id="demo-simple-select-filled"
                                                                onChange={(e) => {
                                                                    updateSlotObject["inflation"][index]['type'] = e.target.value
                                                                }}
                                                                style={{ width: 300 }}
                                                            >
                                                                <MenuItem value="INCREASE">INCREASE</MenuItem>
                                                                <MenuItem value="DECREASE">DECREASE</MenuItem>
                                                            </Select>
                                                        </div>
                                                    }
                                                </TableCell>


                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                ) : (
                    <div></div>
                )
            }

            {
                document && document.opening_time && document.opening_time.length > 0 ? (
                    <div className='update-slot-div'>
                        <span className='update-slot-span'>Opening - Closing Time:</span>
                        <div style={{ marginTop: 15, marginBottom: 15 }}>

                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            {OpeningTimeHeaders.map((header, index) => (
                                                <TableCell
                                                    key={index}
                                                    style={{ fontWeight: "bold", fontSize: "18px" }}
                                                >
                                                    {header}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {document.opening_time.map((openingTime, index) => (
                                            <TableRow
                                                key={index}
                                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                            >
                                                <TableCell align="right">{openingTime.day}</TableCell>
                                                <TableCell align="right">{openingTime.open_time} - {openingTime.close_time}</TableCell>

                                                <TableCell align="right">
                                                    <IconButton
                                                        title="Edit"
                                                        style={{
                                                            width: "60px",
                                                            color: " #014D2F",
                                                            marginLeft: "5px",
                                                            marginTop: "-10px",
                                                            display: isButtonOpeningTimeVisibleArray[index] && previousEditedRowIndex !== index ? "block" : "none"
                                                        }}
                                                        onClick={() => handleEditOpeningTimeClick(index)}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>

                                                    {index === editableOpeningTimeIndex && isSlotInOpeningTimeVisible && ((
                                                        <div>
                                                            <p className="slot-form-p-tag ">Open Time: </p>
                                                            <TimePicker
                                                                placeholder={openingTime.open_time}
                                                                onChange={(e) => {
                                                                    updateSlotObject["opening_time"][index]['open_time'] = moment(e).format("hh:mm A")
                                                                }}
                                                            />

                                                            <p className="slot-form-p-tag">Close Time: </p>
                                                            <TimePicker
                                                                placeholder={openingTime.close_time}
                                                                onChange={(e) => {
                                                                    updateSlotObject["opening_time"][index]['close_time'] = moment(e).format("hh:mm A")
                                                                }}
                                                            />
                                                        </div>
                                                    ))}
                                                </TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                ) : (
                    <div></div>
                )
            }


            <button
                className="slotButton"
                style={{ width: 250, marginLeft: 300, marginRight: 125 }}

                onClick={(e) => {
                    onClickUpdateSlot(e, id, updateSlotObject);
                }}
            >
                Save
            </button>


        </form >

    );
} 