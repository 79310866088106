import { useState, useEffect } from 'react'
import { projectFirestore, projectAuth } from '../firebase/config'
import { useAuthContext } from './useAuthContext'

export const useSignup = () => {
  const [isCancelled, setIsCancelled] = useState(false)
  const [error, setError] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const { dispatch } = useAuthContext()


  const signup = async (email,
    // thwaniCustomerId,
    dateOfBirth,
    password,
    firstName,
    lastName,
    phoneNumber,
    brandName,
    crDocuments,
    ownerId,
    status,
    type,
    crDocumentDownloadURL,
    timestamp) => {


    setError(null)
    setIsPending(true)

    try {
      // signup
      const res = await projectAuth.createUserWithEmailAndPassword(email, password)

      if (!res) {
        throw new Error('Could not complete signup')
      }


      await projectFirestore.collection('users').doc(res.user.uid).set({
        email,
        //thwaniCustomerId,
        dateOfBirth,
        password,
        firstName,
        lastName,
        phoneNumber,
        brandName,
        ownerId,
        status,
        type,
        crDocumentDownloadURL,
        timestamp
      })

      //creatSession(thwaniCustomerId,firstName);

      // dispatch login action
      dispatch({ type: 'LOGIN', payload: res.user })

      if (!isCancelled) {
        setIsPending(false)
        setError(null)
      }
    }
    catch (err) {
      if (!isCancelled) {
        setError(err.message)
        setIsPending(false)
      }
    }
  }

  useEffect(() => {
    return () => setIsCancelled(true)
  }, [])

  return { signup, error, isPending }
}