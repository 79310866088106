import PersonIcon from "@mui/icons-material/Person";
import EmailTwoToneIcon from "@mui/icons-material/EmailTwoTone";
import CoPresentTwoToneIcon from "@mui/icons-material/CoPresentTwoTone";
import AssignmentIndTwoToneIcon from "@mui/icons-material/AssignmentIndTwoTone";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import { useCollection } from "../../../hooks/useCollection";
import { useAuthContext } from "../../../hooks/useAuthContext";
import "../../../style/admin/Card.css";
import "../../../style/auth/Login.css";

export default function BillProfile() {
  const { id } = useParams();
  const { isPending, error, documents } = useCollection("users");
  const { user } = useAuthContext();
  const { document } = useCollection("slots");
  const [cookies, setCookie] = useCookies(["reloadCount"]);

  useEffect(() => {
    if (cookies.reloadCount === 0) {
      window.location.reload();
      setCookie("reloadCount", 1);
    } else {
      setCookie("reloadCount", 0);
    }
  }, []);

  return (
    <div>
      <h2
        style={{
          fontFamily: "NexaDemo-Bold",
          fontSize: "25px",
          marginBottom: "25px",
        }}
        className="page-title"
      >
        <PersonIcon className="svg_icons" />
        User Information:
      </h2>
      <div>
        {isPending && <div>Loading users...</div>}
        {error && <div>{error}</div>}

        {documents &&
          documents.map((profile) =>
            profile.id === id ? (
              <div className="container" key={user.uid}>
                <div
                  style={{
                    fontFamily: "NexaDemo-Bold",
                    fontSize: "20px",
                    marginBottom: "25px",
                  }}
                >
                  <h3>
                    <CoPresentTwoToneIcon className="svg_icons" />
                    Name: &nbsp;
                    <span className="wrapper">{profile.firstName}</span>
                  </h3>
                </div>
                <div
                  style={{
                    fontFamily: "NexaDemo-Bold",
                    fontSize: "20px",
                    marginBottom: "25px",
                  }}
                >
                  <h3>
                    <EmailTwoToneIcon className="svg_icons" />
                    Email: &nbsp;
                    <span className="wrapper">{profile.email}</span>
                  </h3>
                </div>
                <div
                  style={{
                    fontFamily: "NexaDemo-Bold",
                    fontSize: "20px",
                    marginBottom: "20px",
                  }}
                >
                  <h3>
                    <AssignmentIndTwoToneIcon className="svg_icons" />
                    Owner Id: &nbsp;
                    <span className="wrapper">{profile.ownerId}</span>
                  </h3>
                </div>
              </div>
            ) : (
              <p></p>
            )
          )}
      </div>
    </div>
  );
}
