import PersonOutlineSharpIcon from '@mui/icons-material/PersonOutlineSharp';

const personInfoFields = [
  {
    title: "Name",
    fields: ["firstName", "lastName"],
    icon: <PersonOutlineSharpIcon />,
  },
  {
    title: "Email",
    fields: ["email"],
  },
  {
    title: "Phone Number",
    fields: ["phoneNumber"],
  },

  {
    title: "CR Number",
    fields: ["ownerId"],
    isStricted: true, // You can strict access to this field by assigning true to isStricted and add user types to the users list
    users: ["vendor"],
  },
];

export default personInfoFields;
