const packageHeaders = [
  "Type",
  "Fee",
  "Period Range",
  "Regular Min. Order",
  "Business Min. Order",
  "Delete",
];

const slotHeaders = [
  "Label",
  "Avaliable From",
  "Avaliable To",
  "Type",
  "Number Of Chairs",
  "Location",
  "Update",
];

const basePriceHeaders = [
  "Hours",
  "Price",
  "Update"
];

const inflationHeaders = [
  "Start Time",
  "End Time",
  "Inflation",
  "Type",
  "Update",
];

const OpeningTimeHeaders = [
  "Open Day:",
  "Open - Close Time:",
  "Update",
];

const creditHeaders = [
  { title: "#", users: ["admin", "developer"] },
  { title: "Registeration Date", users: ["admin", "developer"] },
  { title: "Customer Name", users: ["admin", "developer"] },
  { title: "Phone Number", users: ["admin", "developer"] },
  { title: "Credit", users: ["admin", "developer"] },
  { title: "Notification", users: ["admin", "developer"] },
  { title: "Add Credit", users: ["developer"] },
];

const invoiceHeaders = ["id", "Date", "Time", "Slot Label", "Duration", "Price", "Action"];

const staffHeaders = ["id", "Registeration Date", "Staff Name", "Phone Number"];

export {
  packageHeaders,
  slotHeaders,
  creditHeaders,
  staffHeaders,
  invoiceHeaders,
  basePriceHeaders,
  inflationHeaders,
  OpeningTimeHeaders,
};
