import React from "react";
import Navbar from "../../snippets/nav-sidebars/Navbar";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLogout } from "../../../hooks/useLogout";
import { timestamp } from "../../../firebase/config";
import { projectFunctions } from "../../../firebase/config";
import { useStaffSignup } from "../../../hooks/useStaffSignup";
import "../../../style/auth/StaffSignup.css";
import { Link } from "react-router-dom";

export default function StaffSignup() {
  const navigate = useNavigate();
  const { logout } = useLogout();
  const { staffSignup, isPending, error } = useStaffSignup();
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const setUserClaims = projectFunctions.httpsCallable("setUserClaims");
  const createStaff = () => {
    try {
      staffSignup(
        email,
        password,
        firstName,
        lastName,
        phoneNumber,
        "staff",
        [],
        "active",
        timestamp.now()
      ).then(() => {
        setUserClaims({
          email: email,
          claims: { type: "staff", status: "pending" },
        })
          .then((result) => { })
          .catch((error) => {
            console.log(error);
          });
      });
      logout();
      navigate("/login");
    } catch (error) { }
  };

  return (
    <div>
      <Navbar />
      <section className="hero-body  is-fullheight">
        <div >
          <div className="container">
            <div className=" is-centered">
              <form className="staff-auth-form">
                <ul className="tab-group">
                  <li>
                    <Link to="/signup">Vendor</Link>
                  </li>
                  <li className="is-active">
                    <Link to="/staff-signup">Staff</Link>
                  </li>
                </ul>
                <hr className="hrLine2" />

                <div className="staff-row">
                  <h2
                    className="staff-auth-form-title"
                    style={{
                      fontSize: 25,
                      color: "#014D2F",
                      fontWeight: "bolder",
                    }}
                  >
                    Staff Sign Up
                  </h2>
                  <input
                    className="staff-row"
                    placeholder="Email"
                    required
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                  <input
                    className="staff-row"
                    placeholder="Password"
                    required
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                  />
                  <input
                    className="staff-row"
                    placeholder="First Name"
                    required
                    type="text"
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName}
                  />
                  <input
                    className="staff-row"
                    placeholder="Last Name"
                    required
                    type="text"
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName}
                  />

                  <input
                    className="staff-row"
                    placeholder="Phone Number"
                    required
                    type="text"
                    onChange={(e) => setphoneNumber(e.target.value)}
                    value={phoneNumber}
                  />

                  {!isPending && <button onClick={createStaff}>Sign up</button>}
                  {isPending && <button disabled>loading</button>}
                  {error && <div className="error">{error}</div>}
                  <p className="link-staffsignup">
                    Already have an account?
                    <a className="link-staffsignup" href="/login">
                      Login
                    </a>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}