import swal from "sweetalert";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate } from "react-router-dom";
import { useFirestore } from "../../../hooks/useFirestore";
import {
  Paper,
  Typography,
  TableCell,
  TableRow,
  TableContainer,
  TableBody,
  Table,
  TableHead,
} from "@mui/material";
import { packageHeaders } from "../../../data/tableHeaders";
import { useCollection } from "../../../hooks/useCollection";
import { useAuthContext } from "../../../hooks/useAuthContext";
import "../../../style/vendor/PackageList.css";

export default function PackageList() {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { deleteDocument } = useFirestore("packages");
  const { documents: packages } = useCollection("packages", [
    "createdBy",
    "==",
    user.uid,
  ]);

  const deletePackage = async (e, id) => {
    swal("Do You Want To delete This Package? ", {
      buttons: ["No", "Yes"],
    }).then((value) => {
      if (value === true) {
        deleteDocument(id);
      }
    });
  };

  const onAddPackageClick = () => {
    navigate("/dashboard/add-package/indpendent");
  };

  return (
    <div style={{ marginTop: "150px", padding: "0 100px" }}>
      <IconButton
        title="Add Other Package"
        style={{ width: "60px", color: " #014D2F" }}
        onClick={onAddPackageClick}
      >
        <AddCircleIcon style={{ fontSize: "30px", marginBottom: "10px" }} />
      </IconButton>
      <Typography
        variant="h4"
        style={{
          color: " #014D2F",
        }}
        className="page-title"
      >
        Packages List
      </Typography>
      <div className="PackageList">
        {!packages && <p>Loading packages...</p>}
        {packages && packages.length === 0 && <p>No packages added yet!</p>}
        {packages && packages.length !== 0 ? (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {packageHeaders.map((header, index) => (
                    <TableCell
                      key={index}
                      style={{ fontWeight: "bold", fontSize: "18px" }}
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {packages.map((p) => (
                  <TableRow
                    key={p.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="right">{p.slotType}</TableCell>
                    <TableCell align="right">
                      {p.fee === "yes" ? "With Fee" : "Without Fee"}
                    </TableCell>
                    <TableCell align="right">
                      {p.timeFrom} - {p.timeTo}
                    </TableCell>
                    <TableCell align="right">{p.minOrderUser} OMR</TableCell>
                    <TableCell align="right">
                      {p.minOrderBusiness} OMR{" "}
                    </TableCell>
                    <TableCell align="center">
                      <button
                        className="btnRej"
                        onClick={(e) => {
                          deletePackage(e, p.id);
                        }}
                      >
                        Delete Package
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div></div>
        )}
      </div>
    </div >
  );
}