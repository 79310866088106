import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
import { useCollection } from "../../../hooks/useCollection";
import { useCollectionGroup } from "../../../hooks/useCollectionGroup";
import '../../../style/admin/GeneralStatistics.css'
export default function GeneralStatisctics() {
  var totalSpent = 0.0;
  var customersCount = 0;
  var vendorsCount = 0;
  var staffCount = 0;

  const spend = {};
  const { documents: users } = useCollection("users");
  const { documents: reservations } = useCollectionGroup("reservations");

  reservations &&
    reservations.forEach((element) => {
      if (element["slotOwner"] === "TCzZ3p8MLMR9BiHnsePwOKL8OYv2") return;
      if (!spend[element["slotOwner"]]) {
        spend[element["slotOwner"]] = {};
        spend[element["slotOwner"]].spend = 0.0;
      }
      spend[element["slotOwner"]].spend += element["totalIncome"];
      if (element["status"] === "ACCEPTED") {
        totalSpent += parseFloat(element["totalIncome"]);
      }
    });

  users &&
    users.forEach((user) => {
      if (user.type === "customer") {
        customersCount += 1;
      } else if (user.type === "vendor") {
        vendorsCount += 1;
      } else if (user.type === "staff") {
        staffCount += 1;
      }
    });

  var numbers = [
    { title: "Total Spending", count: totalSpent.toFixed(3), link: "" },
    {
      title: "Customers",
      count: customersCount,
      link: "/dashboard/customers-managment",
    },
    {
      title: "Vendors",
      count: vendorsCount,
      link: "/dashboard/vendors-reservations",
    },
    { title: "Staff", count: staffCount, link: "/dashboard/staff-managment" },
  ];

  const titleStyle = {
    textAlign: "center",
    color: " #014D2F",
    fontSize: "21px",
  };
  const countStyle = {
    textAlign: "center",
    fontSize: "34px",
    fontWeight: "bold",
    marginBottom: '0px',
  };

  return (
    <div className="general-statistics-container" >
      <Grid container spacing={2}>
        {numbers &&
          numbers.map((number, index) => (
            <Grid key={index} item xs={12} sm={4} md={3}>
              <div className="columns">
                <div className="column">
                  <div className="card">
                    <div className="card-content general-statistics-card-content">
                      <div className="content">
                        <center>
                          <div className="title">
                            <h3 style={countStyle}>{number.count + " "}</h3>
                            <a href={number.link}>
                              {/* 
                              &nbsp; */}
                              <p style={titleStyle}>{number.title}</p>
                            </a>
                          </div>
                        </center>
                      </div>
                    </div>
                  </div>
                </div></div>
            </Grid>

          ))}
      </Grid>
    </div>
  );
}
