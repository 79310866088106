import React from "react";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate } from "react-router";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useCollection } from "../../../hooks/useCollection";
import "../../../style/vendor/BranchList.css";

export default function BranchList() {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { documents: branches } = useCollection("branches", [
    "createdBy",
    "==",
    user.uid,
  ]);

  const onAddBranchClick = () => {
    navigate("/dashboard/add-independent-branch-map");
  };

  const onUpdateBranchClick = (id) => {
    navigate(`/dashboard/update-branch/${id}`);
  }

  branches && branches.map((branch) => { });

  return (
    <div className="branchDiv">
      <IconButton
        title="Add Extra Branch"
        style={{ width: "60px", color: " #014D2F" }}
        onClick={onAddBranchClick}
      >
        <AddCircleIcon style={{ fontSize: "30px", marginBottom: "10px" }} />
      </IconButton>
      <h2
        style={{
          fontFamily: "NexaDemo-Bold",
          fontSize: "30px",
          color: " #014D2F",
        }}
        className="page-title"
      >
        Branch List
      </h2>

      <div className="BranchList">
        {!branches && <p className="BranchList-p-tag">Loading Branches!</p>}
        {branches && branches.length === 0 && <p>No Branches Added yet!</p>}
        {branches &&
          branches.map((branch) => (
            <div key={branch.id}>
              <h3 className="BranchList-h3-tag" style={{ textAlign: "center" }}>
                Brand Name: {branch.brandName}
              </h3>
              &nbsp;
              <Divider />
              &nbsp;
              <p className="BranchList-p-tag" style={{ fontFamily: "NexaDemo-Bold", fontSize: "23px" }}>
                Branch Name : {branch.title}
              </p>
              <p className="BranchList-p-tag" style={{ fontFamily: "NexaDemo-Bold", fontSize: "20px" }}>
                Time From : {branch.openTime} - {branch.closeTime}
              </p>
              <p className="BranchList-p-tag" style={{ fontFamily: "NexaDemo-Bold", fontSize: "23px" }}>
                Description : {branch.description}
              </p>
              <button
                className="branchButton"
                style={{ alignSelf: 'center' }}
                onClick={() => { onUpdateBranchClick(branch.id) }}
              >
                Update Branch
              </button>
            </div>

          ))}
      </div>
    </div>
  );
}
