import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";
import "../../../style/employee/EmployeesList.css";

export default function AddEmployeeList({ employee }) {
  return (
    <div style={{ marginTop: "150px", padding: "0 100px" }}>
      <h2
        style={{
          fontFamily: "NexaDemo-Bold",
          fontSize: "30px",
          color: " #014D2F",
        }}
        className="page-title"
      >
        Employee List
      </h2>
      <div className="Employees-list">
        {employee.length === 0 && <p>No Employees Availab!e</p>}
        {employee.map((employee) => (
          <Link key={employee.id}>
            <h3 style={{ fontFamily: "NexaDemo-Bold", fontSize: "20px" }}>
              First Name: {employee.firstName}
            </h3>
            <Divider />
            <p style={{ fontFamily: "NexaDemo-Bold", fontSize: "18px" }}>
              Last Name: {employee.lastName}
            </p>
            <p style={{ fontFamily: "NexaDemo-Bold", fontSize: "18px" }}>
              E-mail: {employee.email}
            </p>
            <p style={{ fontFamily: "NexaDemo-Bold", fontSize: "18px" }}>
              Account Type : {employee.type}
            </p>
          </Link>
        ))}
      </div>
    </div>
  );
}
