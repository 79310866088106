import React from "react";
import { useCollectionGroup } from "../../../hooks/useCollectionGroup";
// import "../../../style/OrdersList.css";

export default function ReservationsStatistics() {
  var acceptedTotal = 0.0;
  var rejectedTotal = 0.0;
  var pendingTotal = 0.0;
  const { documents: reservations } = useCollectionGroup("reservations", null, [
    "timestamp",
    "desc",
  ]);

  reservations &&
    reservations.forEach((reservation) => {
      if (reservation["slotOwner"] === "TCzZ3p8MLMR9BiHnsePwOKL8OYv2") return;
      if (reservation["status"] === "ACCEPTED") {
        acceptedTotal += reservation["totalIncome"];
      } else if (reservation["status"] === "REJECTED") {
        rejectedTotal += reservation["totalIncome"];
      } else if (reservation["status"] === "PENDING") {
        pendingTotal += reservation["totalIncome"];
      }
    });

  return (
    <div >
      <div className="is-size-3 pb-4 slot-green reservations-header">Reservations</div>

      <div className="columns reservations-header">
        <div className="reservation-column">
          <div className="card">
            <div className="card-content reservation-card-content ">
              <div className="content reservation-content">
                <center>
                  <div className="card-title">
                    {" "}
                    {reservations && reservations.length}
                  </div>
                  <div className="card-title slot-green">Reservations</div>
                </center>
              </div>
            </div>
          </div>
        </div>
        <div className="reservation-column">
          <div className="card">
            <div className="card-content reservation-card-content">
              <div className="content">
                <center>
                  <div className="card-title">
                    {(acceptedTotal + rejectedTotal + pendingTotal).toFixed(3)}{" "}
                    OMR
                  </div>
                  <div className="card-title slot-green">Total</div>
                </center>
              </div>
            </div>
          </div>
        </div>
        <div className="reservation-column">
          <div className="card">
            <div className="card-content reservation-card-content">
              <div className="content">
                <center>
                  <div className="card-title">{acceptedTotal.toFixed(3)} OMR</div>
                  <div className="card-title slot-green">Accepted</div>
                </center>
              </div>
            </div>
          </div>
        </div>
        <div className="reservation-column">
          <div className="card">
            <div className="card-content reservation-card-content">
              <div className="content">
                <center>
                  <div className="card-title">{rejectedTotal.toFixed(3)} OMR</div>
                  <div className="card-title slot-green">Rejected</div>
                </center>
              </div>
            </div>
          </div>
        </div>
        <div className="reservation-column">
          <div className="card" >
            <div className="card-content reservation-card-content">
              <div className="content">
                <center>
                  <div className="card-title">{pendingTotal.toFixed(3)} OMR</div>
                  <div className="card-title slot-green">Pending</div>
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
