import personInfoFields from "../../../data/personalInfoFields";
import { useDocument } from "../../../hooks/useDocument";
import { projectAuth } from "../../../firebase/config";
import {
  Paper,
  Typography,
  TableCell,
  TableRow,
  TableContainer,
  TableBody,
  Table,
} from "@mui/material";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import "../../../style/general/UserProfile.css";
import { useAuthContext } from "../../../hooks/useAuthContext";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router";
import { AiTwotoneEdit } from "react-icons/ai";


export default function UserPofile() {

  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { document } = useDocument("users", user.uid);

  const onUpdateProfileClick = (id) => {
    navigate(`/dashboard/update-profile/${id}`);
  }


  projectAuth.onAuthStateChanged((user) => {
    if (user) {
      user.getIdTokenResult().then((idTokenResult) => {
        user.type = idTokenResult.claims.type;
      });
    }
  });


  return (
    <>
      <div className="UserProfileDiv">
        <IconButton
          title="Update"
          style={{ width: "60px", color: " #014D2F" }}
          onClick={() => onUpdateProfileClick(user.uid)}
        >
          <AiTwotoneEdit style={{ fontSize: "35px", marginBottom: "10px" }} />
        </IconButton>
        <Typography
          variant="h4"
          color="#014D2F"
          style={{
            marginBottom: "25px",
          }}
          className="page-title"
        >
          {document && <div> {document.firstName}'s Profile</div>}
        </Typography>
        <div>
          {!document && <div>Loading user...</div>}

          {document && (
            <div className="container" key={user.uid}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableBody>
                    {personInfoFields.map((row, index) => {
                      if (row.isStricted && !row.users.includes(document.type))
                        return <div key={index}></div>;

                      return (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="right">
                            <Typography variant="h5" color="#014D2F">
                              {row.title}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography variant="h7">
                              {row.fields.map((f) => document[f] + " ")}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </div>
      </div>
      {/* <Statistics /> */}
    </>
  );
}
