import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";
import { useFirestore } from "../../../hooks/useFirestore";
import { useCollection } from "../../../hooks/useCollection";
import "../../../style/admin/VendorList.css";
export default function VendorList() {
  const { updateDocument } = useFirestore("users");
  const { documents: vendor } = useCollection("users", [
    "type",
    "==",
    "vendor",
  ]);

  const confirmation = (e, id) => {
    e.preventDefault();
    updateDocument(id, { status: "active" });
  };
  const reject = (e, id) => {
    e.preventDefault();
    updateDocument(id, { status: "rejected" });
  };

  return (
    <div className="Vendor-List-div">
      <h2
        style={{
          fontSize: "30px",
          color: " #014D2F",
        }}
        className="page-title"
      >
        Vendor Registeration List
      </h2>
      <div className="Vendor-List">
        {!vendor && <p>Loading vendors...</p>}
        {vendor && vendor.length === 0 && <p>No Vendors Available!</p>}
        {vendor &&
          vendor.map((vendor, index) => (
            <div className="Vendor-List" key={index}>
              <div className="vendor-list-cards">
                <h3 style={{ fontFamily: "NexaDemo-Bold", fontSize: "20px" }}>
                  Brand Name: {vendor.brandName}
                </h3>

                <Divider />
                <p style={{ fontFamily: "NexaDemo-Bold", fontSize: "20px" }}>
                  Owner Id: {vendor.ownerId}
                </p>
                <p style={{ fontFamily: "NexaDemo-Bold", fontSize: "20px" }}>
                  Owner email: {vendor.email}
                </p>
                <p style={{ fontFamily: "NexaDemo-Bold", fontSize: "20px" }}>
                  Account Status: {vendor.status}
                </p>
                <p style={{ fontFamily: "NexaDemo-Bold", fontSize: "20px" }}>
                  Account ID: {vendor.id}
                </p>
                <p style={{ fontFamily: "NexaDemo-Bold", fontSize: "18px" }}>
                  CR Document:{" "}
                  <a
                    href={vendor.crDocumentDownloadURL}
                    style={{ color: "Highlight" }}
                  >
                    Download File
                  </a>
                </p>

                {vendor.status === "pending" ? (
                  <p style={{ fontFamily: "NexaDemo-Bold", fontSize: "17px" }}>
                    <strong>
                      <button
                        className="btn"
                        style={{ marginBottom: 10 }}
                        onClick={(e) => {
                          confirmation(e, vendor.id);
                        }}
                      >
                        Activate
                      </button>
                    </strong>

                    <button
                      className="btnRej"
                      onClick={(e) => {
                        reject(e, vendor.id);
                      }}
                    >
                      Reject
                    </button>
                  </p>
                ) : null}

                {vendor.status === "active" ? (
                  <strong>
                    <button
                      className="btnRej"
                      style={{ marginBottom: 10 }}
                      onClick={(e) => {
                        reject(e, vendor.id);
                      }}
                    >
                      Deactivate
                    </button>
                  </strong>
                ) : null}

                {vendor.status === "rejected" ? (
                  <strong>
                    <button
                      className="btn"
                      style={{ marginBottom: 10 }}
                      onClick={(e) => {
                        confirmation(e, vendor.id);
                      }}
                    >
                      Activate
                    </button>
                  </strong>
                ) : null
                }
              </div >
            </div >
          ))
        }
      </div >
    </div >
  );
}
