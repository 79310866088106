import React from "react";
import { useCollectionGroup } from "../../../hooks/useCollectionGroup";
import "../../../style/vendor/OrdersList.css";
import ReservationCard from "../../snippets/ReservationCard";
import { useParams } from "react-router-dom";
// import "../../../style/admin/VendorReservationList.css";

export default function VendorReservationList() {
  const { id } = useParams();

  const { documents: reservations, finalTotalIncome } = useCollectionGroup(
    "reservations",
    ["slotOwner", "==", id],
    ["timestamp", "desc"]
  );

  return (
    <div className="mainDiv">
      {" "}
      <h2
        style={{
          fontSize: "30px",
          color: " #014D2F",
        }}
        className="page-title"
      >
        Reservations
      </h2>
      <div className="columns">
        <div className="column">
          <div className="card">
            <div className="card-content">
              <div className="content">
                <center>
                  <div className="title">
                    {" "}
                    {reservations && reservations.length}
                  </div>
                  <div className="subtitle slot-green">Reservations</div>
                </center>
              </div>
            </div>
          </div>
        </div>
        <div className="column">
          <div className="card">
            <div className="card-content">
              <div className="content">
                <center>
                  <div className="title">
                    {" "}
                    {finalTotalIncome.toFixed(3)} OMR
                  </div>
                  <div className="subtitle slot-green">Total</div>
                </center>
              </div>
            </div>
          </div>
        </div>
        <div className="column">
          <div className="card">
            <div className="card-content">
              <div className="content">
                <center>
                  <div className="title">
                    {" "}
                    {(Number(finalTotalIncome) * 0.05).toFixed(3)}
                  </div>
                  <div className="subtitle slot-green">Tax</div>
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="order-list">
        {reservations && reservations.length === 0 && (
          <p>No Reservations yet!</p>
        )}

        {reservations &&
          reservations.map((reservation, index) => (
            <ReservationCard
              key={index}
              reservation={reservation}
              userType="Vendor"
            />
          ))}
      </div>
    </div>
  );
}
