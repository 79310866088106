import Global from "../data/global";
import swal from "sweetalert";
import { useState, useEffect } from "react";
import { projectAuth, projectFirestore } from "../firebase/config";
import { useAuthContext } from "./useAuthContext";
import { ReactSession } from "react-client-session";
import { useCookies } from "react-cookie";
import { useLogout } from "./useLogout";
export const useLogin = () => {
  const [isCancelled, setIsCancelled] = useState(false);
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const { dispatch } = useAuthContext();
  const { logout } = useLogout();
  const [cookies, setCookie] = useCookies(["brandName"]);

  const login = async (email, password) => {
    ReactSession.setStoreType("localStorage");
    setError(null);
    setIsPending(true);
    try {
      // login
      await projectAuth
        .signInWithEmailAndPassword(email, password)
        .then((res) => {
          projectFirestore.collection("users").doc(res.user.uid);
          projectFirestore
            .collection("users")
            .doc(res.user.uid)
            .get()
            .then((snapshot) => {
              if (snapshot.data().status === "pending") {
                swal({
                  icon: "info",
                  text: "Your Account is Bening Reviwed By Slot Managment",
                });
                logout();
              } else if (snapshot.data().status === "rejected") {
                swal({
                  icon: "error",
                  text: "Your Account is Rejected By Slot Managment",
                });
                logout();
              } else {
                Global.brandName = snapshot.data().brandName;
                setCookie("brandName", snapshot.data().brandName, {
                  path: "/",
                });
                setCookie("status", snapshot.data().status, { path: "/" });
                ReactSession.set("USERTYPE", snapshot.data().type);
                dispatch({ type: "SAVE_TYPE", payload: snapshot.data().type });
                dispatch({ type: "LOGIN", payload: res.user });
              }
            })
            .catch((e) => console.log(e));
        });

      if (!isCancelled) {
        setIsPending(false);
        setError(null);
      }
    } catch (err) {
      if (!isCancelled) {
        setError(err.message);
        setIsPending(false);
      }
    }
  };

  useEffect(() => {
    return () => setIsCancelled(true);
  }, []);

  return { login, isPending, error };
};
