import { useReducer, useEffect, useState } from "react";
import { projectFirestore, timestamp } from "../firebase/config";
import { useAuthContext } from "../hooks/useAuthContext";

let initialState = {
  document: null,
  isPending: false,
  error: null,
  success: null,
};

const firestoreReducer = (state, action) => {
  switch (action.type) {
    case "IS_PENDING":
      return { isPending: true, document: null, success: false, error: null };
    case "ADDED_DOCUMENT":
      return {
        isPending: false,
        document: action.payload,
        success: true,
        error: null,
      };
    case "DELETED_DOCUMENT":
      return { isPending: false, document: null, success: true, error: null };
    case "ERROR":
      return {
        isPending: false,
        document: null,
        success: false,
        error: action.payload,
      };
    case "UPDATED_DOCUMENT":
      return {
        isPending: false,
        document: action.payload,
        success: true,
        error: null,
      };
    case "UPDATED_SLOT":
      return {
        isPending: false,
        document: action.payload,
        success: true,
        error: null,
      };
    case "UPDATED_BRANCH":
      return {
        isPending: false,
        document: action.payload,
        success: true,
        error: null,
      };

    case "UPDATED_PROFILE":
      return {
        isPending: false,
        document: action.payload,
        success: true,
        error: null,
      };
    case "UPDATED_CONFIRM":
      return {
        isPending: false,
        document: action.payload,
        success: true,
        error: null,
      };
    case "UPDATED_ACOUNTID":
      return {
        isPending: false,
        document: action.payload,
        success: true,
        error: null,
      };
    default:
      return state;
  }
};

export const useFirestore = (collection) => {
  const [response, dispatch] = useReducer(firestoreReducer, initialState);
  const [isCancelled, setIsCancelled] = useState(false);
  const { user } = useAuthContext();

  // collection ref
  const ref = projectFirestore.collection(collection);
  const usersRef = projectFirestore.collection(collection);
  // only dispatch if not cancelled
  const dispatchIfNotCancelled = (action) => {
    if (!isCancelled) {
      dispatch(action);
    }
  };

  // add a document
  const addDocument = async (doc) => {
    dispatch({ type: "IS_PENDING" });

    try {
      const createdAt = timestamp.fromDate(new Date());
      const addedDocument = await ref.add({ ...doc, createdAt });
      dispatchIfNotCancelled({
        type: "ADDED_DOCUMENT",
        payload: addedDocument,
      });
    } catch (err) {
      dispatchIfNotCancelled({ type: "ERROR", payload: err.message });
    }
  };

  // delete a document

  // update a document
  const updateDocument = async (id, updates) => {
    dispatch({ type: "IS_PENDING" });

    try {
      const updatedDocument = await ref.doc(id).update(updates);
      dispatchIfNotCancelled({
        type: "UPDATED_DOCUMENT",
        payload: updatedDocument,
      });
      return updatedDocument;
    } catch (error) {
      dispatchIfNotCancelled({ type: "ERROR", payload: error });
      return null;
    }
  };

  const updateReservation = async (slotId, reservationId, updates) => {
    dispatch({ type: "IS_PENDING" });

    try {
      const updatedDocument = await ref
        .doc(slotId)
        .collection("reservations")
        .doc(reservationId)
        .update(updates);
      dispatchIfNotCancelled({
        type: "UPDATED_DOCUMENT",
        payload: updatedDocument,
      });
      return updatedDocument;
    } catch (error) {
      console.log(error);
      dispatchIfNotCancelled({ type: "ERROR", payload: error });
      return null;
    }
  };

  const updateCredit = async (userid, credit) => {
    dispatch({ type: "IS_PENDING" });

    try {
      const updatedDocument = await usersRef.doc(userid).update({ credit });
      dispatchIfNotCancelled({
        type: "UPDATED_DOCUMENT",
        payload: updatedDocument,
      });
      return updatedDocument;
    } catch (error) {
      console.log(error);
      dispatchIfNotCancelled({ type: "ERROR", payload: error });
      return null;
    }
  };

  const deleteDocument = async (id) => {
    dispatch({ type: "IS_PENDING" });

    try {
      const deletePackage = await ref.doc(id).delete();
      dispatchIfNotCancelled({ type: "DELETED_DOCUMENT" });
      return deletePackage;
    } catch (err) {
      dispatchIfNotCancelled({ type: "ERROR", payload: "could not delete" });
    }
  };

  const updateAccountId = async (documentId, index, userList, boolean) => {
    dispatch({ type: "IS_PENDING" });
    var newAccess = { accountId: user.uid, confirm: true };
    userList.push(newAccess);

    try {
      const updateAccountId = await ref
        .doc(documentId)
        .update({ access: userList });
      dispatchIfNotCancelled({
        type: "UPDATED_CONFIRM",
        payload: updateAccountId,
      });
      return updateAccountId;
    } catch (error) {
      dispatchIfNotCancelled({ type: "ERROR", payload: error });
      return null;
    }
  };

  const updateSlot = async (slotId, updateSlotObject) => {
    dispatch({ type: "IS_PENDING" });

    try {
      const updatedSlot = await ref
        .doc(slotId)
        .update(updateSlotObject);
      dispatchIfNotCancelled({
        type: "UPDATED_SLOT",
        payload: updatedSlot,
      });
      return updatedSlot;
    } catch (error) {
      console.log(error);
      dispatchIfNotCancelled({ type: "ERROR", payload: error });
      return null;
    }
  };

  const updateBranch = async (branchId, updateBranchObject) => {
    dispatch({ type: "IS_PENDING" });

    try {
      const updatedBranch = await ref
        .doc(branchId)
        .update(updateBranchObject);
      dispatchIfNotCancelled({
        type: "UPDATED_BRANCH",
        payload: updatedBranch,
      });
      return updatedBranch;
    } catch (error) {
      console.log(error);
      dispatchIfNotCancelled({ type: "ERROR", payload: error });
      return null;
    }
  };


  const updateProfile = async (userId, updateProfileObject) => {
    dispatch({ type: "IS_PENDING" });

    try {
      const updatedProfile = await ref
        .doc(userId)
        .update(updateProfileObject);
      dispatchIfNotCancelled({
        type: "UPDATED_PROFILE",
        payload: updatedProfile,
      });
      return updatedProfile;
    } catch (error) {
      console.log(error);
      dispatchIfNotCancelled({ type: "ERROR", payload: error });
      return null;
    }
  };

  useEffect(() => {
    return () => setIsCancelled(true);
  }, []);

  return {
    addDocument,
    updateReservation,
    updateCredit,
    updateAccountId,
    updateSlot,
    updateBranch,
    updateProfile,
    deleteDocument,
    updateDocument,
    response,
  };
};
