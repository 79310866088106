import AddEmployeeList from "../employee/AddEmpolyeesList";
import { useCollection } from "../../../hooks/useCollection";

export default function Employees() {
  const { documents, error } = useCollection("users");

  const employee = documents
    ? documents.filter((document) => {

      let assignedToMe = false;
      if (document.type === "business") {
        assignedToMe = true;
      }
      return assignedToMe;
    })
    : null;

  return (
    <div>
      {error && <p className="error">{error}</p>}
      {employee && <AddEmployeeList employee={employee} />}
    </div>
  );
}