import id from "date-fns/locale/id";
import { useState, useEffect } from "react";
import { projectFirestore } from "../firebase/config";
import { useGlobalContext } from "../hooks/useGlobalContext";

export const useVendorAddPackage = () => {
  const [isCancelled, setIsCancelled] = useState(false);
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const { branchIds } = useGlobalContext();

  const addPackage = async (
    createdBy,
    timeFrom,
    timeTo,
    fee,
    minOrderUser,
    minOrderBusiness,
    slotType,
    timestamp
  ) => {
    setError(null);
    setIsPending(true);

    try {
      await projectFirestore.collection("packages").doc(id.packages).set({
        branchIds,
        createdBy,
        timeFrom,
        timeTo,
        fee,
        minOrderUser,
        minOrderBusiness,
        slotType,
        timestamp,
      });

      if (!isCancelled) {
        setIsPending(false);
        setError(null);
      }
    } catch (err) {
      if (!isCancelled) {
        setError(err.message);
        setIsPending(false);
      }
    }
  };

  useEffect(() => {
    return () => setIsCancelled(true);
  }, []);

  return { addPackage, error, isPending };
};