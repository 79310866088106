import React from "react";
import useSound from "use-sound";
import ReservationCard from "../../snippets/ReservationCard";
import elegantSfx from "../../../assets/sounds/elegant.mp3";
import { useEffect } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useCollectionGroup } from "../../../hooks/useCollectionGroup";
import "../../../style/vendor/OrdersList.css";

export default function OrdersList() {
  const { user } = useAuthContext();
  const [play, { stop }] = useSound(elegantSfx);

  const { documents: reservations, finalTotalIncome } = useCollectionGroup(
    "reservations",
    ["slotOwner", "==", user.uid],
    ["timestamp", "desc"]
  );

  useEffect(() => {
    play();
  }, [reservations]);

  return (
    <div className="mainDiv" >
      {" "}
      <h2
        style={{
          fontFamily: "NexaDemo-Bold",
          fontSize: "30px",
          color: " #014D2F",
        }}
        className="page-title"
      >
        Orders List
      </h2>
      <div className="order-list">
        <h3
          className="order-list-total"
          style={{ textAlign: "center", fontSize: "25px" }}
        >
          Total Income: {Number(finalTotalIncome).toFixed(3)} OMR
        </h3>
      </div>
      <div className="order-list">
        {reservations && reservations.length === 0 && (
          <p>No Reservations yet!</p>
        )}
        {reservations &&
          reservations.map((reservation, index) => (
            <ReservationCard
              reservation={reservation}
              userType="Vendor"
              key={reservation.id}
            />
          ))}
      </div>
    </div>
  );
}