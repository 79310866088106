import React from "react";
import moment from "moment";

import {
  Paper,
  Typography,
  TableCell,
  TableRow,
  TableContainer,
  TableBody,
  Table,
  TableHead,
} from "@mui/material";

import { staffHeaders } from "../../../data/tableHeaders";
import { useCollection } from "../../../hooks/useCollection";
import "../../../style/admin/UsersManagment.css";

export default function StaffManagment() {
  const { documents: users } = useCollection("users", [
    "type",
    "==",
    "staff",
    "timestamp",
  ]);

  return (
    <div style={{ marginTop: "150px", padding: "0 100px" }}>
      <Typography
        variant="h4"
        style={{
          color: " #014D2F",
        }}
        className="page-title"
      >
        Staff Managment
      </Typography>
      <br />
      <br />

      <div className="columns">
        <div className="column">
          <div className="card">
            <div className="card-content">
              <div className="content">
                <center>
                  <div className="title"> {users && users.length}</div>
                  <div className="subtitle slot-green">Staff</div>
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="PackageList">
        {!users && <p>Loading Staff List...</p>}
        {users && users.length === 0 && <p>No staff added yet!</p>}
        {users && users.length !== 0 ? (
          <TableContainer component={Paper}>
            <Table
              stickyHeader={true}
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  {staffHeaders.map((header, index) => (
                    <TableCell
                      key={index}
                      style={{
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((u) => (
                  <TableRow
                    key={u.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="right">{u.id}</TableCell>

                    <TableCell align="right">
                      {u.timestamp &&
                        moment
                          .unix(u.timestamp["seconds"])
                          .format("DD/MM/YYYY hh:mm A")}
                    </TableCell>
                    <TableCell align="right">{u.email}</TableCell>
                    <TableCell align="right">{u.phoneNumber}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div></div>
        )}{" "}
      </div>
    </div>
  );
}
