import React from "react";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate } from "react-router-dom";
import { slotHeaders } from "../../../data/tableHeaders";
import { useCollection } from "../../../hooks/useCollection";
import { useAuthContext } from "../../../hooks/useAuthContext";
import UpdateIcon from '@mui/icons-material/Update';
import { useParams } from "react-router-dom"
import { AiTwotoneEdit } from "react-icons/ai";
import {
  Paper,
  Typography,
  TableCell,
  TableRow,
  TableContainer,
  TableBody,
  Table,
  TableHead,
} from "@mui/material";
import "../../../style/vendor/SlotList.css";

export default function SlotList() {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { id } = useParams();
  const { documents: slots } = useCollection("slots", [
    "createdBy",
    "==",
    user.uid,
  ]);

  const onAddSlotClick = () => {
    navigate("/dashboard/add-slot/indpendent");
  };

  const onUpdateSlotClick = (id) => {
    navigate(`/dashboard/update-slot/${id}`);
  }

  return (
    <div className="slotList-div" >
      <IconButton
        title="Add Other Package"
        style={{ width: "60px", color: " #014D2F" }}
        onClick={onAddSlotClick}
      >
        <AddCircleIcon style={{ fontSize: "30px", marginBottom: "10px" }} />
      </IconButton>
      <Typography
        variant="h4"
        style={{
          color: " #014D2F",
        }}
        className="page-title"
      >
        Slots List
      </Typography>
      <div className="SlotList">
        {!slots && <p>Loading Slots!</p>}
        {slots && slots.length === 0 && <p>No Slot added yet!</p>}
        {slots && slots.length !== 0 ? (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {slotHeaders.map((header, index) => (
                    <TableCell
                      key={index}
                      style={{ fontWeight: "bold", fontSize: "18px" }}
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {slots.map((slot) => (
                  <TableRow
                    key={slot.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="right">{slot.slotLable}</TableCell>
                    <TableCell align="right">{slot.placeOpenTime}</TableCell>
                    <TableCell align="right">{slot.placeCloseTime}</TableCell>
                    <TableCell align="right">{slot.slotType}</TableCell>
                    <TableCell align="right">{slot.numbeOfChair}</TableCell>
                    <TableCell align="right">{slot.location}</TableCell>
                    <TableCell align="right">
                      <IconButton title="Update" onClick={() => { onUpdateSlotClick(slot.id) }}>
                        <AiTwotoneEdit style={{ color: " #014D2F" }} />
                      </IconButton></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div></div>
        )}
      </div>
    </div >
  );
}
