import React from "react";
import useSound from "use-sound";
import ReservationCard from "../../../components/snippets/ReservationCard";
import elegantSfx from "../../../assets/sounds/elegant.mp3";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useCollectionGroup } from "../../../hooks/useCollectionGroup";

export default function StaffOrderList() {
  const { id } = useParams();
  const [play, { stop }] = useSound(elegantSfx);
  var reservations = [];
  const { documents } = useCollectionGroup(
    "reservations",
    ["slotOwner", "==", id],
    ["timestamp", "desc"]
  );

  reservations =
    documents && documents.filter((reservation) => !reservation["paidBySlot"]);

  useEffect(() => {
    play();
  }, [reservations]);

  return (
    <div style={{ marginTop: "150px", padding: "0 100px" }}>
      {" "}
      <h2
        style={{
          fontSize: "30px",
          color: " #014D2F",
        }}
        className="page-title"
      >
        Reservations
      </h2>
      <div className="order-list">
        {reservations && reservations.length === 0 && (
          <p>No Reservations yet!</p>
        )}
        {reservations &&
          reservations.map((reservation, index) => (
            <ReservationCard key={index} reservation={reservation} />
          ))}
      </div>
    </div>
  );
}
