import React from "react";
import { useCollectionGroup } from "../../../hooks/useCollectionGroup";
import "../../../style/vendor/OrdersList.css";
import ReservationCard from "../../snippets/ReservationCard";
import ReservationsStatistics from "../../snippets/statistics/ReservationsStatistics";
import { useParams } from "react-router-dom";
export default function CustomerReservations({ userType }) {
  const { id } = useParams();
  const { documents: reservations } = useCollectionGroup(
    "reservations",
    ["bookedBy", "==", id],
    ["timestamp", "desc"]
  );

  return (
    <div className="mainDiv">
      <div className="order-list">
        {reservations && reservations.length === 0 && (
          <p>No Reservations yet!</p>
        )}
        {reservations &&
          reservations.map((reservation, index) =>
            reservation["branchId"] !== "4XPQk0DgbbpgKrYvmj9N" ? (
              <ReservationCard
                key={index}
                reservation={reservation}
                userType={userType}
              />
            ) : null
          )}
      </div>
    </div>
  );
}
