import { useState, useEffect } from 'react'
import { projectFirestore, projectAuth } from '../firebase/config'
import { useAuthContext } from './useAuthContext'

export const useStaffSignup = () => {
  const [isCancelled, setIsCancelled] = useState(false)
  const [error, setError] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const { dispatch } = useAuthContext()


  const staffSignup = async (email,
    password,
    firstName,
    lastName,
    phoneNumber,
    type,
    access,
    status,
    timestamp) => {


    setError(null)
    setIsPending(true)

    try {
      // signup
      const res = await projectAuth.createUserWithEmailAndPassword(email, password)

      if (!res) {
        throw new Error('Could not complete signup')
      }
      await projectFirestore.collection('users').doc(res.user.uid).set({
        email,
        password,
        firstName,
        lastName,
        phoneNumber,
        type,
        access,
        status,
        timestamp
      })

      // creatSession(thwaniCustomerId, firstName);

      // dispatch login action
      dispatch({ type: 'LOGIN', payload: res.user })

      if (!isCancelled) {
        setIsPending(false)
        setError(null)
      }
    }
    catch (err) {
      if (!isCancelled) {
        setError(err.message)
        setIsPending(false)
      }
    }
  }

  useEffect(() => {
    return () => setIsCancelled(true)
  }, [])

  return { staffSignup, error, isPending }
}