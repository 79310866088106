import React from "react";
import { createRoot } from "react-dom/client";
import "./style/general/index.css";
import App from "./App";
import { AuthContextProvider } from "./context/AuthContext";
import { GlobalContextProvider } from "./context/GlobalContext";
import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { CookiesProvider } from "react-cookie";
import "../src/hooks/Payment";
const options = {
  timeout: 8000,
  position: positions.BOTTOM_CENTER,
};
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <GlobalContextProvider>
        <Provider template={AlertTemplate} {...options}>
          <CookiesProvider>
            <App />
          </CookiesProvider>
        </Provider>
      </GlobalContextProvider>
    </AuthContextProvider>
  </React.StrictMode>
);
